import styled from 'styled-components';

import colors from '../../styles/colors';
import { hideScrollbar } from '../../styles/utils';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-x: hidden;
	margin: -0.25rem -1rem 0;
`;

export const Badge = styled.div`
	min-width: 1.25rem;
	width: 1.25rem;
	margin-left: 0.375rem;
	font-size: 0.625rem;
	font-weight: 700;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: ${colors.neutralText};
	border: 1px solid ${colors.neutralText};
	border-radius: 0.25rem;
`;

export const Head = styled.div`
	display: flex;
	flex-direction: row;
	overflow-x: scroll;
	white-space: nowrap;
	background-color: ${({ transparent }) => (transparent ? 'transparent' : 'white')};
	position: relative;
	box-shadow: ${({ transparent }) => (transparent ? 'none' : colors.sidebarShadow)};
	border-bottom: ${({ transparent }) => (transparent ? `1px solid ${colors.neutralForm}` : 'none')};

	${hideScrollbar}
`;

export const TabButton = styled.button`
	display: flex;
	justify-content: center;
	text-align: center;
	appearance: none;
	border: none;
	background-color: transparent;
	padding: 0.5rem 1rem;
	flex: 1;
	cursor: pointer;
	color: ${({ active }) => (active ? colors.brandPrimary : colors.neutralText)};

	& > *:first-child {
		position: relative;
	}

	${({ active }) => active
		&& `
		${Badge} {
			color: ${colors.brandPrimary};
			border-color: ${colors.brandPrimary};
		}
	`}

	@media screen and (min-width: 768px) {
		flex: 0;
	}
`;

export const ActiveIndicator = styled.div`
	position: absolute;
	bottom: -0.5rem;
	left: 0;
	right: 0;
	height: 0.125rem;
	background-color: ${colors.brandPrimary};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	flex: 1;

	@media screen and (max-width: 768px) {
		overflow: auto;
		padding: 1rem;
		position: relative;
		top: 0rem;
		width: 100%;
	}
`;
