import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './TableComponents.styles';

const CLASS_STATUS_LABELS = {
	CLASS: { label: 'Class', icon: ['fal', 'check'], color: 'success' },
	NOT_A_CLASS: { label: 'Not a Class', icon: ['fal', 'times'], color: 'danger' },
};

const ClassStatusCell = ({ value }) => (
	<S.ClassStatusCell className={CLASS_STATUS_LABELS[value].color}>
		<FontAwesomeIcon className="icon" icon={CLASS_STATUS_LABELS[value].icon} />
		{CLASS_STATUS_LABELS[value].label}
	</S.ClassStatusCell>
);

ClassStatusCell.propTypes = {
	value: PropTypes.oneOf(Object.keys(CLASS_STATUS_LABELS)),
};

export const { TableWrapper } = S;
export const Cells = {
	ClassStatusCell,
};
