import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	border-bottom: 1px solid ${colors.neutralForm}
`;

export const CardWrapper = styled.div`
	margin: 1rem;
`;

export const InnerCardWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const InfoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 50%;
`;

export const DetailWrapper = styled.div`
	 display: flex;
	 flex-direction: column;

	 .detail-heading {
		 text-transform: uppercase;
		 font-weight: bold;
		 color: ${colors.neutralText};
	 }
`;

export const ActionWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 50%;

	button {
		padding-right: 0;
	}
`;
