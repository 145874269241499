import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: auto auto 1fr;
	gap: 1.125rem;
	align-items: center;
	padding: 1.125rem 1rem;
	z-index: 0;
`;

export const SmallBox = styled.div`
	border-radius: 0.375rem;
	border: 1px solid ${colors.neutralForm};
	width: 1.5625rem;
	height: 1.5625rem;
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 1rem;
		height: 1rem;
		color: white;
		opacity: 0;
	}
`;

export const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	min-width: 2rem;
	max-width: 2rem;
	color: ${colors.neutralText};
	pointer-events: none;
`;

export const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	pointer-events: none;

	.dc-title {
		color: black;
		margin-bottom: 0.125rem;
	}

	.dc-description {
		color: ${colors.neutralText};
	}
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
	position: absolute;
	height: 100%;
	width: 100%;
	appearance: none;
	cursor: pointer;
	border: 2px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	z-index: -1;

	&:hover {
		border-color: ${colors.brandPrimary};
	}

	&:checked {
		border-color: ${colors.brandPrimary};
		background-color: ${colors.neutralForm};

		& ~ ${SmallBox} {
			background-color: ${colors.brandPrimary};
			border-color: ${colors.brandPrimary};

			svg {
				opacity: 1;
			}
		}

		& ~ ${IconWrapper} {
			color: ${colors.brandPrimary};
		}

		& ~ ${InfoWrapper} {
			.dc-title {
				color: ${colors.brandPrimary};
			}
			.dc-description {
				color: black;
			}
		}
	}
`;
