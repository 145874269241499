import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-top: 3rem;

	h6 {
		margin-bottom: 1rem;
	}
`;

export const Form = styled.form`
	width: 100%;
`;

export const FormRow = styled.div`
	width: 100%;
	border: 1px solid ${colors.neutralForm};
	border-radius: .25rem;
	padding: .75rem;
	margin-top: 1rem;

	input {
		flex: 1;

		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: auto;
	}
`;

export const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 1rem;

	button {
		padding: 0;
	}

	.submit-button {
		margin-left: 1rem;
	}

	.cancel-button {
		color: ${colors.neutralText};
	}
`;
