import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	& > label {
		cursor: pointer;
		color: ${({ error }) => (error ? colors.stateDanger : colors.neutralText)};
		text-transform: uppercase;
	}
`;

const SIZES = {
	small: css`
		font-size: 0.75rem;
		min-height: 2.25rem;
	`,
	medium: css`
		font-size: 1rem;
		min-height: 2.8125rem;
	`,
};

export const TextInput = styled.input`
	appearance: none;
	padding: 0 0.75rem;
	border-radius: 0.25rem;
	border: 1px solid ${colors.neutralForm};
	color: black;
	line-height: 'normal';
	width: 100%;
	outline: none;

	${({ size }) => SIZES[size]}

	&[type='password'] {
		letter-spacing: 0.25rem;
	}

	&[type='textarea'] {
		letter-spacing: .00625rem;
	}

	&:focus {
		border-color: ${colors.neutralButton};
	}

	&:read-only {
		background-color: ${colors.neutralBackground};
		border-color: ${colors.neutralForm};
	}

	${({ withIcon }) => withIcon
		&& css`
			padding-left: 2.25rem;
		`}

	${({ withPasswordIcon }) => withPasswordIcon
		&& css`
			padding-right: 2.25rem;
		`}

	${({ error }) => error
		&& css`
			color: ${colors.stateDanger};
			border-color: ${colors.stateDanger};
			margin-bottom: .75rem;
			:focus {
				border-color: ${colors.stateDanger};
			}
		`}
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: -1rem;
	color: ${colors.stateDanger};
	max-width: 100%;

	p {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		margin-bottom: .5rem;
	}
`;

export const IconWrapper = styled.div`
	position: absolute;
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	padding: 0 0.75rem;
	pointer-events: none;

	svg {
		color: ${colors.neutralForm};
	}
`;

export const PasswordIconWrapper = styled.div`
	position: absolute;
	top: .75rem;
	right: 0.5rem;
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;

	svg {
		color: ${colors.neutralText};
	}

	&:hover {
		cursor: pointer;
	}
`;
