import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
`;

export const Toggle = styled.input.attrs({ type: 'checkbox' })`
	display: block;
	appearance: none;
	cursor: pointer;
	border-radius: 9999px;
	border: 1px solid ${colors.neutralForm};
	background-color: ${colors.neutralForm};
	width: 2.8125rem;
	height: 1.5625rem;

	&:checked {
		border-color: ${colors.brandPrimary};
		background-color: ${colors.brandPrimary};
	}
`;

export const ToggleDot = styled.div`
	position: absolute;
	width: 1.0938rem;
	height: 1.0938rem;
	border-radius: 9999px;
	background-color: white;
	pointer-events: none;
`;

export const ToggleWrapper = styled.div`
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	${ToggleDot} {
		left: 0.2344rem;
	}

	${Toggle}:checked + ${ToggleDot} {
		left: auto;
		right: 0.2344rem;
	}
`;
