export const scrollToTarget = (target, containerEl) => {
	const isElement = target && target.nodeType === 1;
	const isNumber = Object.prototype.toString.call(target) === '[object Number]';

	if (containerEl) {
		if (isElement) {
			containerEl.scrollTo({ top: target.offsetTop, behavior: 'smooth' });
		} else if (isNumber) {
			containerEl.scrollTop = target;
		} else if (target === 'bottom') {
			containerEl.scrollTo({ top: containerEl.scrollHeight - containerEl.offsetHeight, behavior: 'smooth' });
		} else if (target === 'top') {
			containerEl.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}
};
