import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: ${({ isOver }) => (isOver ? '3.75rem' : '100%')};
	opacity: ${({ isOver }) => (isOver ? '.5' : '1')};
`;

export const ScrollWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: ${({ isOver }) => (isOver ? '3.75rem' : '100%')};
	opacity: ${({ isOver }) => (isOver ? '.5' : '1')};
`;

export const EmptyWrapper = styled.div`
	height: 100%;
`;
