import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const Topbar = styled.header`
	display: flex;
	background-color: white;
	border-bottom: 1px solid ${colors.neutralForm};
	padding: .4rem 0.5rem;
	height: 3rem;
`;

export const TopbarWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 0 0.5rem;

	.mobile-only {
		display: block;
	}
	.desktop-only {
		display: none;
		margin-left: 2.5rem;
	}

	@media screen and (min-width: 768px) {
		.mobile-only {
			display: none;
		}

		.desktop-only {
			display: block;
		}
	}
	${({ fullWidth }) => fullWidth
		&& css`
			justify-content: center;
			flex: 1;
		`}

	& > * + * {
		margin-left: 1rem;
	}

	&:last-child {
		justify-content: flex-end;
	}

	.menu-button {
		color: ${colors.brandPrimary};
	}
`;

export const Picture = styled.picture`
	display: flex;
	height: 2.375rem;

	img {
		height: 100%;
	}

	@media screen and (min-width: 768px) {
		display: none;
	}
`;

export const Avatar = styled.picture`
	width: 1.875rem;
	min-width: 1.875rem;
	height: 1.875rem;
	min-height: 1.875rem;
	border-radius: 9999px;
	overflow: hidden;
	background-color: ${colors.neutralButton};

	img {
		width: 100%;
		height: 100%;
		max-width: 100%;
		object-fit: cover;
	}
`;

export const AvatarWrapper = styled.button`
	display: flex;
	align-items: center;
	border: none;
	background-color: transparent;
	cursor: pointer;
	appearance: none;

	.avatar-name,
	.angle-down {
		display: none;

		@media screen and (min-width: 768px) {
			display: block;
		}
	}

	.angle-down {
		color: ${colors.brandPrimary};
		width: 1rem;
		height: 1rem;
	}

	& > * + * {
		margin-left: 0.5rem;
	}

	&:hover {
		opacity: 0.8;
	}
`;
