import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useForm } from 'react-hook-form';

import { useDispatch } from 'react-redux';
import {
	moveTask, removeTaskFromBacklog, addTaskToBacklog
} from '../../../../app/slices/tasks/tasksSlice';
import {
	addDispatchTask, deleteDispatchTask, removeTaskFromDispatch
} from '../../../../app/slices/dispatches/dispatchesSlice';


import {
	Modal, IconButton, Button, Typography, DateInput, Select, Loader
} from '../../../../components';
import * as S from './MoveTaskModal.styles';

const MoveTaskModal = ({
	task,
	isOpen,
	onRequestClose,
	selectedDispatch,
	setSelectedDispatch,
	dispatchOptions,
	targetDate,
	setTargetDate,
	originalDispatch,
	handleAddDispatch,
	loadingDispatch
}) => {

	const {
		formState: { isSubmitting },
	} = useForm();
	const reduxDispatch = useDispatch();
	const [isBacklog, setIsBacklog] = useState(false);
	const [date, setDate] = useState(new Date());

	useEffect(() => {
		if (targetDate) {
			setDate(new Date(targetDate));
		}
	}, [targetDate]);

	const formatDispatchAssigneeLabel = (dispatch) => `${dispatch && dispatch.assignees && dispatch.assignees.length === 0 ? `dispatch-${dispatch.dispatchNumber}` : dispatch && dispatch.assignees && dispatch.assignees.length > 1 ? `${dispatch.assignees.map((assignee) => `${assignee.firstName} ${assignee.lastName ? assignee.lastName : ''}`)} (${dispatch.dispatchNumber})` : `${dispatch.assignees[0].firstName} ${dispatch.assignees[0].lastName ? dispatch.assignees[0].lastName : ''} (${dispatch.dispatchNumber})`}`;

	const CustomMenu = ({
		innerRef, innerProps, isDisabled, children
	}) => (!isDisabled ? (
		<S.SelectMenu ref={innerRef} {...innerProps}>
			<Button
				onClick={() => handleAddDispatch(date)}
				variant="text"
				className="new-dispatch-button"
			>
				<FontAwesomeIcon icon={['fal', 'plus']} />
				<Typography tag="p">
					New Dispatch
				</Typography>
			</Button>
			{loadingDispatch ? (
				<S.LoaderWrapper>
					<Loader />
				</S.LoaderWrapper>
			) : children}
		</S.SelectMenu>
	) : null);

	// Additional PropTypes for custom menu compoenent above. Kept in functional component for scope
	CustomMenu.propTypes = {
		innerRef: PropTypes.func,
		innerProps: PropTypes.object,
		isDisabled: PropTypes.bool,
		children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	};

	const onSubmit = (e) => {
		e.preventDefault();
		const movedTask = {
			taskId: task.id,
			targetDispatchId: selectedDispatch.id || null,
			targetPlacement: isBacklog ? 'backlog' : 'dispatch',
			previousDispatchId: originalDispatch.id || null
		};
		reduxDispatch(moveTask(movedTask)).then((res) => {
			const payload = res?.payload;
			if (isBacklog) {
				reduxDispatch(removeTaskFromDispatch({ taskId: payload.id, dispatchId: originalDispatch.id, persist: true }));
				reduxDispatch(addTaskToBacklog(payload));
			} else if (task.placement === 'backlog') {
				reduxDispatch(addDispatchTask(payload));
				reduxDispatch(removeTaskFromBacklog(payload));
			} else {
				reduxDispatch(addDispatchTask(payload));
				reduxDispatch(deleteDispatchTask({ ...payload, dispatchId: originalDispatch.id }));
			}
		});
		onRequestClose(e);
	};

	const handleSelectDispatch = (option) => {
		setSelectedDispatch(option.dispatch);
	};

	const handleDateChange = (value) => {
		setDate(value);
		setTargetDate(moment(value).format('MM-DD-YYYY'));
	};

	const handleBacklogClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsBacklog('backlog');
	};

	return (
		<Modal isOpen={isOpen} onRequestClose={onRequestClose} maxWidth="20.9375rem" topMargin="10rem" noOverflow noOverlay disableOverflow>
			<S.Wrapper>
				<S.ActionsWrapper>
					<IconButton icon={['fal', 'times']} size={1.25} className="icon close-icon" onClick={onRequestClose} />
				</S.ActionsWrapper>
				<S.Form onSubmit={onSubmit}>
					<S.Header>
						<Typography tag="h6">Move Job</Typography>
						<hr />
					</S.Header>
					<S.FormRow>
						{task.placement === 'dispatch' && <Button variant="text" variation="secondary" className="backlog-button" onClick={handleBacklogClick}>Send to Backlog</Button>}
						<DateInput id="date" selected={date} onChange={handleDateChange} label="Date" containerClassName="select-date-field" size="medium" placeholder="Select Date" />
					</S.FormRow>
					{!isBacklog && (
						<S.FormRow>
							<Select
								id="dispatches"
								label="Select Dispatch"
								containerClassName="select-field"
								size="medium"
								placeholder="Add Dispatch"
								options={dispatchOptions}
								value={{ label: selectedDispatch ? formatDispatchAssigneeLabel(selectedDispatch) : '', value: selectedDispatch ? selectedDispatch.id : '' }}
								onChange={(value) => handleSelectDispatch(value)}
								alternate
								disabled={isBacklog}
								components={{ Menu: CustomMenu }}
							/>
						</S.FormRow>
					)}
					<S.ButtonRow>
						<Button type="submit" variation="secondary" className="submit-button">
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading...' : 'Move'}
							</Typography>
						</Button>
					</S.ButtonRow>
				</S.Form>
			</S.Wrapper>
		</Modal>
	);
};

export default MoveTaskModal;

MoveTaskModal.displayName = 'MoveTaskModal';
MoveTaskModal.propTypes = {
	task: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	selectedDispatch: PropTypes.object,
	setSelectedDispatch: PropTypes.func,
	dispatchOptions: PropTypes.array,
	targetDate: PropTypes.string,
	setTargetDate: PropTypes.func,
	originalDispatch: PropTypes.object,
	handleAddDispatch: PropTypes.func,
	loadingDispatch: PropTypes.bool
};
