/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import placeholder from '../../assets/avatar-placeholder.png';
import { Typography } from '../Typography';
import * as S from './Avatar.styles';

export const Avatar = ({
	className, avatar, setAvatar, setFile
}) => {
	const onDrop = useCallback((acceptedFiles) => {
		if (acceptedFiles.length > 0) {
			if (setFile) {
				setFile(acceptedFiles[0]);
			}
			setAvatar(URL.createObjectURL(acceptedFiles[0]));
		}
	}, []);
	const {
		getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject
	} = useDropzone({
		onDrop, multiple: false, accept: 'image/jpeg, image/png', disabled: !setAvatar
	});

	useEffect(
		() => () => {
			!!avatar && URL.revokeObjectURL(avatar);
		},
		[avatar]
	);

	const renderPicture = () => (
		<S.Picture>
			<source srcSet={avatar} />
			<img src={placeholder} alt="" />
		</S.Picture>
	);

	const renderEmptyUploadPlaceholder = () => (
		<S.EmptyPlaceholder>
			<FontAwesomeIcon size="2x" icon={['fal', 'camera']} />
			<Typography variation="button-small" weight="bold">
				{isDragActive ? 'Drop Here' : 'Upload Photo'}
			</Typography>
		</S.EmptyPlaceholder>
	);

	return (
		<S.Avatar {...getRootProps()} className={className} uploadDisabled={!setAvatar} isDragActive={isDragActive} isDragAccept={isDragAccept} isDragReject={isDragReject}>
			<input {...getInputProps()} />
			{setAvatar && !avatar ? renderEmptyUploadPlaceholder() : renderPicture()}
		</S.Avatar>
	);
};

Avatar.displayName = 'Avatar';
Avatar.propTypes = {
	className: PropTypes.string,
	avatar: PropTypes.string,
	setAvatar: PropTypes.func,
	file: PropTypes.object,
	setFile: PropTypes.func,
};
