import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid ${colors.neutralForm};
	border-radius: .25rem;
	width: 100%;
	padding: 1rem;
	margin-bottom: .75rem;

	svg, p {
		margin-left: .75rem;
	}

	svg {
		color: ${colors.brandSecondary};
		height: 1.5rem;
	}

	p {
		text-transform: capitalize;
	}
`;

export const LeftContent = styled.div`
	display: flex;
	align-items: center;
`;

export const RightContent = styled.div`
	svg {
		color: ${colors.stateDanger};
	}
`;
