import React from 'react';
import PropTypes from 'prop-types';

import {
	Modal, IconButton, Button, Typography
} from '../../../../components';
import * as S from './DeleteDispatchModal.styles';

export const DeleteDispatchModal = ({
	isOpen,
	onRequestClose,
	handleDelete
}) => (
	<Modal isOpen={isOpen} onRequestClose={onRequestClose} maxWidth="35rem" noOverflow>
		<S.Wrapper>
			<S.ActionsWrapper>
				<IconButton icon={['fal', 'times']} size={1.25} className="icon close-icon" onClick={onRequestClose} />
			</S.ActionsWrapper>
			<S.InfoWrapper>
				<Typography tag="h2" weight="bold">
					Delete Dispatch
				</Typography>
				<Typography tag="p">
					Would you like to also delete the jobs for this Dispatch?
				</Typography>
			</S.InfoWrapper>
			<S.ButtonRow>
				<Button variant="outline" className="submit-button" onClick={() => handleDelete('archive')}>
					<Typography variation="button-medium" weight="bold">
						Keep in Job Archive
					</Typography>
				</Button>
				<Button variation="warning" className="submit-button" onClick={() => handleDelete('delete')}>
					<Typography variation="button-medium" weight="bold">
						Delete Jobs
					</Typography>
				</Button>
			</S.ButtonRow>
		</S.Wrapper>
	</Modal>
);
DeleteDispatchModal.displayName = 'DeleteDispatchModal';
DeleteDispatchModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	handleDelete: PropTypes.func.isRequired,
};
