import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
	.mobile-only {
		display: none;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;

		.desktop-only {
			display: none;
		}

		.mobile-only {
			display: block;
		}
	}`;

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
	overflow: hidden;
	position: absolute;
	top: 7.5rem;
	bottom: 0;
	max-width: 100%;
    width: calc(100% - 3.25rem);

	@media screen and (max-width: 768px) {
		overflow: auto;
		padding: 0;
		position: relative;
		top: 0rem;
		width: 100%;
	}
`;

export const BacklogWrapper = styled.div`
	flex: 1;

	.backlog-inner-wrapper {
		padding: 0 1rem 6rem;
	}

	${({ isBacklogOpen }) => (isBacklogOpen
		? css`
			max-width: 100%;
			-webkit-transition: max-width .5s ease-in-out;
			-moz-transition: max-width .5s ease-in-out;
			-o-transition: max-width .5s ease-in-out;
			transition: max-width .5s ease-in-out;
			transition-delay: 0s;
		` : css`
			max-width: 2.5rem !important;
			-webkit-transition: max-width .15s ease-in-out;
			-moz-transition: max-width .15s ease-in-out;
			-o-transition: max-width .15s ease-in-out;
			transition: max-width .15s ease-in-out;
			transition-delay: 0s;
		`)}

	@media screen and (max-width: 768px) {
		width: 100%;
		position:fixed;
		top: 11.5rem;
		bottom: 3.5rem;
		padding: 0 1rem 1rem 1rem;
	}
`;

export const BacklogActionWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 1rem;
	margin: 1.25rem 1rem 1rem;

	.add-task-button {
		border-radius: .25rem;
		svg {
			height: .875rem;
		}
	}

	.backlog-right-actions {
		display: flex;
		align-items: center;

		.backlog-icon {
			margin-left: 1rem;

			&:hover {
				cursor: pointer;
				opacity: .5;
			}
		}
	}

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 2rem;

		.add-task-button {
			width: 100%;
			margin-top: .5rem;
		}
	}
`;

export const BacklogCollapsed = styled.div`
	h5 {
		transform: rotate(90deg);
		margin-top: .5rem;
	}

	.backlog-icon {
		width: 100%;
		margin-top: .5rem;

		&:hover {
			cursor: pointer;
			opacity: .5;
		}
	}
`;

export const LoaderContainer = styled.div`
	margin-top: 1rem;
	margin-bottom: .5rem;
`;

export const DispatchContainer = styled.div`
	width: 100%;
	position:fixed;
	top: 10rem;
	bottom: 2.5rem;
	overflow-y:scroll;
	overflow-x:hidden;
`;

export const DispatchAccordionWrapper = styled.div`
	flex: 3;

	@media screen and (max-width: 768px) {
		flex: 1;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
	}
`;

export const ToggleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	height: 2.5rem;
	box-shadow: ${colors.mediumShadow};
	z-index: 99;

	${({ showDispatches }) => (showDispatches
		? css`
			position: fixed;
			bottom: 0;
			right: 0;
			left: 0;
		` : css`
			position: fixed;
			top: 9.825rem;
			left: 0;
			right: 0;
		`)}
`;
