import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { openMenu } from '../../../app/slices/menu/menuSlice';

import { Popover, IconButton, LogoutModal } from '../..';
import logo from '../../../assets/dispatcher-logo.png';
import * as S from './Topbar.styles';

export const Topbar = () => {
	const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
	const user = useSelector((state) => state.user.value);
	const history = useHistory();
	const dispatch = useDispatch();

	const actions = [
		{
			text: 'My Account',
			onClick: () => history.push('/dashboard/account')
		},
		{
			text: 'Logout',
			onClick: () => setIsLogoutModalOpen(true)
		}
	];

	return (
		<S.Topbar>
			<S.TopbarWrapper>
				<IconButton className="menu-button mobile-only" icon={['fal', 'bars']} onClick={() => dispatch(openMenu())} />
			</S.TopbarWrapper>
			<S.TopbarWrapper>
				<S.Picture className="desktop-only">
					<img src={logo} alt="Dispatchr Logo" />
				</S.Picture>
			</S.TopbarWrapper>
			<S.TopbarWrapper className="mobile-only" fullWidth>
				<S.Picture>
					<img src={logo} alt="logo" />
				</S.Picture>
			</S.TopbarWrapper>
			<S.TopbarWrapper>
				<Popover id="avatar-popover" positions={['bottom']} actions={actions}>
					<S.AvatarWrapper>
						<S.Avatar>
							<img src={user?.profileImage?.['1500']} alt={`${user?.firstName} ${user?.lastName}`} />
						</S.Avatar>
					</S.AvatarWrapper>
				</Popover>
			</S.TopbarWrapper>
			<LogoutModal isOpen={isLogoutModalOpen} onRequestClose={() => setIsLogoutModalOpen(false)} />
		</S.Topbar>
	);
};
