import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
	fetchTasks as getTasks,
	createUpdateTask,
	duplicateTask as copyTask,
	deleteTask as destroyTask,
	moveTask as transitionTask
} from '../../../services/tasks/taskService';
import { errorHandler } from '../../../services/api';
import {
	createUpdateTag
} from '../../../services/tags/tagService';
import { getCookie } from '../../../utils/cookie-methods';

export const fetchTasks = createAsyncThunk('tasks/fetchTasks', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await getTasks(data);

		if (response?.data?.result?.tasks) {
			return { tasks: response.data.result.tasks, pagination: { total: response?.data?.result?.pagination?.total, page: response?.data?.result?.pagination?.page, isLastPage: response?.data?.result?.pagination?.isLastPage } };
		}
		return null;
	} catch (error) {
		errorHandler(error);
	}
});

export const fetchArchiveTasks = createAsyncThunk('tasks/fetchArchiveTasks', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await getTasks(data);
		if (response?.data?.result?.tasks) {
			return { tasks: response.data.result.tasks, pagination: { total: response?.data?.result?.pagination?.total, page: response?.data?.result?.pagination?.page }, shouldSkipState: data.shouldSkipState };
		}
		return null;
	} catch (error) {
		errorHandler(error);
	}
});


export const addTask = createAsyncThunk('tasks/addTask', async (data) => {
	let newTask = {
		...data,
		id: null
	};

	if (data?.dispatchId) {
		newTask = { ...newTask, dispatchId: data.dispatchId };
	}

	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await createUpdateTask(newTask);
		if (response?.data?.result?.task) {
			return { ...response.data.result.task, tempId: data.tempId };
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const updateTask = createAsyncThunk('tasks/updateTask', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await createUpdateTask(data);
		if (response?.data?.result?.task) {
			return { ...response.data.result.task, isSent: data.isSent, dispatch: response?.data?.result?.dispatch };
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const duplicateTask = createAsyncThunk('tasks/duplicateTask', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await copyTask(data);
		if (response?.data?.result?.task) {
			return response.data.result.task;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const deleteTask = createAsyncThunk('tasks/deleteTask', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await destroyTask(data);
		if (response?.data?.result?.task) {
			return response.data.result.task;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const moveTask = createAsyncThunk('tasks/moveTask', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await transitionTask(data);
		if (response?.data?.result?.task) {
			return response.data.result.task;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});


export const addTag = createAsyncThunk('tasks/updateTask', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await createUpdateTag(data);
		if (response?.data?.result?.tag) {
			return response.data.result.tag;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const tasksSlice = createSlice({
	name: 'tasks',
	initialState: {
		status: 'idle',
		error: null,
		value: null,
		taskStatus: 'idle',
		taskArchive: null,
		totalCount: null,
		isLastPage: false,
		archiveLoading: false
	},
	reducers: {
		updateTasks: (state, action) => {
			state.value = action.payload;
		},
		hoverMoveTask: (state, action) => {
			const tasksArr = [...state.value];
			const { dragIndex, hoverIndex } = action.payload;
			let numberOfDeletedElm = 1;
			const element = tasksArr.splice(dragIndex, numberOfDeletedElm)[0];
			numberOfDeletedElm = 0;
			tasksArr.splice(hoverIndex, numberOfDeletedElm, element);
			state.value = tasksArr;
		},
		addTaskToBacklog: (state, action) => {
			const tasksArr = [...state.value].sort((a, b) => a.order - b.order);
			const position = action.payload.position || action.payload.position === 0 ? action.payload.position : null;
			const total = state.totalCount + 1;
			if (position || position === 0) {
				for (let i = 0; i < tasksArr.length; i += 1) {
					if (i < position) {
						tasksArr[i].order = i;
					} else {
						tasksArr[i].order = i + 1;
					}
				}
				const task = { ...action.payload };
				task.order = action.payload.position;
				tasksArr.splice(task.order, 0, task);
			} else {
				tasksArr.splice(action.payload.order, 0, action.payload);
			}

			state.value = tasksArr;
			state.totalCount = total;
		},
		removeTaskFromBacklog: (state, action) => {
			const tasksArr = [...state.value];
			const total = state.totalCount - 1;
			tasksArr.splice(tasksArr.findIndex((i) => i.id === action.payload.id), 1);
			state.value = tasksArr;
			state.totalCount = total;
		},
		addPlaceholderTask: (state, action) => {
			if (action.payload.placement === 'backlog') {
				state.value = [action.payload, ...state.value];
			} else {
				state.value = [...state.value, action.payload];
			}
		},
		removePlaceholderTask: (state, action) => {
			const tasksArr = state.value;
			if (action.payload.placement === 'backlog') {
				tasksArr.splice(tasksArr.findIndex((i) => i.id === action.payload.id), 1);
			}
			state.value = tasksArr;
		},
		deleteTaskTag: (state, action) => {
			const tasksArr = [...state.value];
			tasksArr.forEach((task) => {
				const { tags } = task;
				if (tags.length > 0) {
					tags.splice(tags.findIndex((i) => i.id === action.payload.id), 1);
				}
			});
			state.value = tasksArr;
		},
		addTaskSocket: (state, action) => {
			const tasksArr = state.value;
			const total = state.totalCount + 1;
			if (action.payload.placement === 'backlog') {
				if (action.payload.tempId) {
					const index = tasksArr.findIndex((task) => task.id === action.payload.tempId);
					tasksArr[index] = action.payload;
				} else {
					tasksArr[0] = action.payload;
				}
				state.totalCount = total;
			}
			state.value = tasksArr;
		},
		updateTaskSocket: (state, action) => {
			if (action.payload.placement === 'backlog') {
				const tasksArr = state.value;
				const index = tasksArr.findIndex((task) => task.id === action.payload.id);

				if (index !== -1) {
					tasksArr[index] = action.payload;
				} else {
					tasksArr.push(action.payload);
				}
				state.value = tasksArr;
			}
		},
		deleteTaskSocket: (state, action) => {
			const total = state.total - 1;
			if (action.payload.placement === 'backlog') {
				const tasksArr = state.value;
				tasksArr.splice(tasksArr.findIndex((i) => i.id === action.payload.id), 1);
				state.value = tasksArr;
				state.totalCount = total;
			}
		},
		orderTasks: (state, action) => {
			if (action.payload.placement === 'backlog') {
				const tasksArr = [...state.value];
				const index = tasksArr.findIndex((task) => task.id === action.payload.id);
				tasksArr.splice(index, 1);
				tasksArr.splice(action.payload.order, 0, action.payload);
				state.value = tasksArr;
			}
		},
		duplicateTaskSocket: (state, action) => {
			const tasksArr = [...state.value];
			const total = state.total + 1;
			if (action.payload.placement === 'backlog') {
				tasksArr.splice(action.payload.order, 0, action.payload);
				state.value = tasksArr;
				state.totalCount = total;
			}
		},
		orderTasksByTask: (state, action) => {
			const tasksArr = [...state.value];
			if (action.payload.placement === 'backlog') {
				for (let i = 0; i < tasksArr.length; i += 1) {
					if (i < action.payload.order) {
						tasksArr[i].order = i;
					} else {
						tasksArr[i].order = i + 1;
					}
				}
				tasksArr.splice(action.payload.order, 0, action.payload);
				tasksArr.sort((a, b) => a.order - b.order);
				state.value = tasksArr;
			}
		},
		updateTaskOrder: (state) => {
			const tasksArr = [...state.value];
			for (let i = 0; i < tasksArr.length; i += 1) {
				tasksArr[i].order = i;
			}
			state.value = tasksArr;
		},
		updateOrderByTask: (state, action) => {
			const tasksArr = [...state.value];
			tasksArr.splice(tasksArr.findIndex((i) => i.id === action.payload.id), 1);
			tasksArr.sort((a, b) => a.order - b.order);
			for (let i = 0; i < tasksArr.length; i += 1) {
				if (i < action.payload.order) {
					tasksArr[i].order = i;
				} else {
					tasksArr[i].order = i + 1;
				}
			}
			tasksArr.splice(action.payload.order, 0, action.payload);
			state.value = tasksArr;
		},
		clearTasks: (state) => {
			state.status = 'idle';
			state.error = null;
			state.value = null;
			state.taskStatus = 'idle';
			state.taskArchive = null;
			state.totalCount = null;
			state.isLastPage = false;
			state.archiveLoading = false;
		}
	},
	extraReducers: {
		[fetchTasks.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchTasks.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			let tasksArr = [];
			if (action.payload?.pagination?.page) {
				tasksArr = [...state.value, ...action.payload.tasks];
			} else {
				tasksArr = action.payload.tasks;
			}

			if (action.payload?.pagination?.total) {
				state.totalCount = action.payload?.pagination?.total;
			}

			if (action.payload?.pagination?.isLastPage) {
				state.isLastPage = action.payload?.pagination?.isLastPage;
			}

			const uniqueTaskIds = [];

			const uniqueTasks = tasksArr.filter((element) => {
				const isDuplicate = uniqueTaskIds.includes(element.id);

				if (!isDuplicate) {
					uniqueTaskIds.push(element.id);

					return true;
				}

				return false;
			});

			state.value = uniqueTasks;
		},
		[fetchTasks.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
		[fetchArchiveTasks.pending]: (state, action) => {
			if (!action?.meta?.arg?.shouldSkipState) {
				state.status = 'loading';
			}
		},
		[fetchArchiveTasks.fulfilled]: (state, action) => {
			state.status = 'succeeded';

			if (action.payload?.pagination?.page === 0 || action.payload?.pagination?.page) {
				state.totalCount = action.payload.pagination?.total;
			}

			if (!action.payload.shouldSkipState) {
				state.taskArchive = action.payload.tasks;
			}
		},
		[fetchArchiveTasks.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
		[addTask.pending]: (state) => {
			state.taskStatus = 'loading';
		},
		[addTask.fulfilled]: (state, action) => {
			const tasksArr = state.value;
			const total = state.totalCount + 1;
			state.taskStatus = 'succeeded';
			if (action.payload.placement === 'backlog') {
				if (action.payload.tempId) {
					const index = tasksArr.findIndex((task) => task.id === action.payload.tempId);
					tasksArr[index] = action.payload;
				} else {
					tasksArr[0] = action.payload;
				}
				state.totalCount = total;
			}
			state.value = tasksArr;
		},
		[addTask.rejected]: (state, action) => {
			state.taskStatus = 'failed';
			state.error = action.error.message;
		},
		[updateTask.pending]: (state) => {
			state.taskStatus = 'loading';
		},
		[updateTask.fulfilled]: (state, action) => {
			state.taskStatus = 'succeeded';
			if (action.payload.placement === 'backlog') {
				const tasksArr = state.value;
				const index = tasksArr.findIndex((task) => task.id === action.payload.id);

				if (index !== -1) {
					tasksArr[index] = action.payload;
				} else {
					tasksArr.push(action.payload);
				}
				state.value = tasksArr;
			}
		},
		[updateTask.rejected]: (state, action) => {
			state.taskStatus = 'failed';
			state.error = action.error.message;
		},
		[duplicateTask.pending]: (state) => {
			state.taskStatus = 'loading';
		},
		[duplicateTask.fulfilled]: (state) => {
			state.taskStatus = 'succeeded';
		},
		[duplicateTask.rejected]: (state, action) => {
			state.taskStatus = 'failed';
			state.error = action.error.message;
		},
		[deleteTask.pending]: (state) => {
			state.taskStatus = 'loading';
		},
		[deleteTask.fulfilled]: (state, action) => {
			state.taskStatus = 'succeeded';
			const total = state.total - 1;
			if (action.payload.placement === 'backlog') {
				const tasksArr = state.value;
				tasksArr.splice(tasksArr.findIndex((i) => i.id === action.payload.id), 1);
				state.value = tasksArr;
				state.totalCount = total;
			}
		},
		[deleteTask.rejected]: (state, action) => {
			state.taskStatus = 'failed';
			state.error = action.error.message;
		},
		[moveTask.pending]: (state) => {
			state.taskStatus = 'loading';
		},
		[moveTask.fulfilled]: (state) => {
			state.taskStatus = 'succeeded';
		},
		[moveTask.rejected]: (state, action) => {
			state.taskStatus = 'failed';
			state.error = action.error.message;
		},
	},
});

export const {
	updateTasks,
	hoverMoveTask,
	addTaskToBacklog,
	removeTaskFromBacklog,
	addPlaceholderTask,
	removePlaceholderTask,
	deleteTaskTag,
	addTaskSocket,
	updateTaskSocket,
	deleteTaskSocket,
	orderTasks,
	duplicateTaskSocket,
	orderTasksByTask,
	updateTaskOrder,
	updateOrderByTask,
	clearTasks
} = tasksSlice.actions;

export default tasksSlice.reducer;
