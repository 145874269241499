import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import * as S from './DetailedCheckbox.styles';

export const DetailedCheckbox = forwardRef(({
	containerClassName, title, description, icon, ...rest
}, ref) => (
	<S.Wrapper>
		<S.Checkbox ref={ref} {...rest} />
		<S.SmallBox>
			<FontAwesomeIcon icon={['fal', 'check']} />
		</S.SmallBox>
		<S.IconWrapper>
			<FontAwesomeIcon icon={icon} size="2x" />
		</S.IconWrapper>
		<S.InfoWrapper>
			<Typography tag="p" weight="semibold" className="dc-title">
				{title}
			</Typography>
			<Typography tag="p" variation="2" className="dc-description">
				{description}
			</Typography>
		</S.InfoWrapper>
	</S.Wrapper>
));

DetailedCheckbox.displayName = 'DetailedCheckbox';
DetailedCheckbox.propTypes = {
	containerClassName: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
