import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faTachometer,
	faFilePlus,
	faUser,
	faChartBar,
	faGraduationCap,
	faUsersClass,
	faBooks,
	faEnvelope,
	faUsers,
	faBook,
	faCamera,
	faEdit,
	faFileSearch,
	faComment,
	faComments,
	faBuilding,
	faIdCardAlt,
	faLandmark,
	faCalendar,
	faBookmark,
	faInfoCircle,
	faCheckCircle,
	faSchool,
	faFile,
	faChalkboard,
	faMapMarker,
	faMapMarkerAlt,
	faSlidersH,
	faList,
	faBell,
	faAngleRight,
	faSearch,
	faMap,
	faTimes,
	faTimesCircle,
	faArrowLeft,
	faAngleDown,
	faPlus,
	faShare,
	faArrowRight,
	faThLarge,
	faMinus,
	faAngleLeft,
	faCheck,
	faUserFriends,
	faQuestionCircle,
	faFileUser,
	faAward,
	faBookUser,
	faBriefcase,
	faIdCard,
	faBookReader,
	faChalkboardTeacher,
	faStreetView,
	faCampground,
	faUserHardHat,
	faPencil,
	faMobile,
	faBars,
	faExternalLink,
	faArrowToTop,
	faArrowToBottom,
	faGlobe,
	faDownload,
	faLock,
	faBorderAll,
	faAddressBook,
	faCommentPlus,
	faEllipsisH,
	faEllipsisV,
	faAlignLeft,
	faEquals,
	faTag,
	faCaretUp,
	faCaretDown,
	faClock,
	faSpinner,
	faUndo,
	faCreditCard,
	faTrash,
	faGripLines,
	faEye,
	faEyeSlash,
} from '@fortawesome/pro-light-svg-icons';
import { faCircle as faCircleRegular, faCheckCircle as faCheckCircleRegular } from '@fortawesome/pro-regular-svg-icons';
import {
	faGraduationCap as faGraduationCapSolid,
	faBuilding as faBuildingSolid,
	faSchool as faSchoolSolid,
	faUser as faUserSolid,
	faBookmark as faBookmarkSolid,
	faFile as faFileSolid,
	faUsers as faUsersSolid,
	faCaretUp as faCaretUpSolid,
	faCaretDown as faCaretDownSolid,
	faCircle as faCircleSolid,
	faPaperPlane as faPaperPlaneSolid,
	faCreditCard as faCreditCardSolid,
} from '@fortawesome/pro-solid-svg-icons';

export function loadIcons() {
	library.add(
		faTachometer,
		faFilePlus,
		faUser,
		faChartBar,
		faGraduationCap,
		faUsersClass,
		faBooks,
		faEnvelope,
		faUsers,
		faBook,
		faCamera,
		faEdit,
		faFileSearch,
		faComment,
		faComments,
		faCommentPlus,
		faBuilding,
		faIdCardAlt,
		faLandmark,
		faCalendar,
		faBookmark,
		faInfoCircle,
		faCheckCircle,
		faSchool,
		faFile,
		faChalkboard,
		faMapMarker,
		faMapMarkerAlt,
		faSlidersH,
		faList,
		faBell,
		faAngleRight,
		faSearch,
		faMap,
		faTimes,
		faTimesCircle,
		faArrowLeft,
		faAngleDown,
		faPlus,
		faShare,
		faArrowRight,
		faThLarge,
		faMinus,
		faAngleLeft,
		faCheck,
		faUserFriends,
		faQuestionCircle,
		faFileUser,
		faAward,
		faBookUser,
		faBriefcase,
		faIdCard,
		faBookReader,
		faChalkboardTeacher,
		faStreetView,
		faCampground,
		faUserHardHat,
		faPencil,
		faMobile,
		faBars,
		faExternalLink,
		faArrowToTop,
		faArrowToBottom,
		faGlobe,
		faDownload,
		faLock,
		faGraduationCapSolid,
		faBuildingSolid,
		faSchoolSolid,
		faUserSolid,
		faBookmarkSolid,
		faFileSolid,
		faUsersSolid,
		faBorderAll,
		faAddressBook,
		faEllipsisH,
		faEllipsisV,
		faAlignLeft,
		faEquals,
		faTag,
		faCaretUp,
		faCaretDown,
		faCaretUpSolid,
		faCaretDownSolid,
		faCircleSolid,
		faPaperPlaneSolid,
		faClock,
		faSpinner,
		faUndo,
		faCreditCard,
		faCreditCardSolid,
		faTrash,
		faGripLines,
		faEye,
		faEyeSlash,
		faCircleRegular,
		faCheckCircleRegular
	);
}
