import api from './api';

export const getCurrentAuthenticatedUser = () => api.post('/core/functions/currentUser');

export const beginPasswordReset = (data) => api.post('/core/functions/beginPasswordReset', data);

export const validateResetAttempt = (data) => api.post('/core/functions/validateResetAttempt', data);

export const resetPassword = (data) => api.post('/core/functions/resetPassword', data);

export const loginUser = (data) => api.post('/users/loginUser', data, { withCredentials: true });

export const logoutUser = () => api.post('/users/logoutUser', {}, { withCredentials: true });
