import React, {
	useState, useEffect, useMemo, useRef
} from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

import { EmptyComponent } from '../../../components';
import { PlaceholderCard } from '../PlaceholderCard';
import { TaskCard } from '../TaskCard';
import * as S from './TaskList.styles';

import { useUserPermissions } from '../../../hooks/useUserPermissions';

const TaskList = ({
	tasks, dispatch, containerClassName, emptyIsOver, isBacklog, showEmptyMessage
}) => {
	const [taskList, setTaskList] = useState(tasks);
	const [hoverTask, setHoverTask] = useState(null);
	const [originalTaskList, setOriginalTaskList] = useState(tasks);
	const sortedTasks = useMemo(() => (tasks && tasks.length ? [...tasks].sort((a, b) => a.order - b.order) : []), [tasks]);
	const { canDrop } = useUserPermissions(isBacklog);
	const realTimeTaskList = useRef(tasks);

	realTimeTaskList.current = taskList;

	const onDrop = (item) => {
		setTaskList((prevState) => {
			const itemIndex = prevState.findIndex((task) => task.id === item.id);
			if (itemIndex > -1) {
				return [...prevState];
			} if (itemIndex === -1) {
				const newItems = prevState
					.filter((i) => i.id !== item.id);
				return [...newItems];
			}
		});
		setHoverTask(null);
	};

	const [{ isOver }, drop] = useDrop({
		accept: 'task',
		canDrop: (item) => taskList && taskList.length > 0 && taskList.findIndex((task) => task && (task.id === item.id)) === -1,
		drop: (item, monitor) => {
			onDrop(item, monitor);
		},
		collect: (monitor) => ({
			isOver: !!monitor.isOver()
		})
	});

	useEffect(() => {
		setTaskList(sortedTasks);
		setOriginalTaskList(sortedTasks);
	}, [sortedTasks, tasks]);

	useEffect(() => {
		if (!isOver && hoverTask && taskList.some((task) => task && task?.isTemporary)) {
			setTaskList((prevState) => {
				const newItems = prevState.filter((task) => task && !task?.isTemporary);
				return [...newItems];
			});
			setHoverTask(null);
		}
	}, [isOver, hoverTask, originalTaskList, taskList]);

	const handleMoveTask = (dragIndex, hoverIndex, item) => {
		let newItem = { ...item };
		const hasItem = realTimeTaskList.current && realTimeTaskList.current.findIndex((task) => task && (task.id === item.id)) > -1;
		if (hasItem) {
			newItem = realTimeTaskList.current[dragIndex];
		} else {
			newItem.isTemporary = true;
		}

		setHoverTask(item);
		setTaskList((/* prevState */) => {

			// const newItems = prevState.filter((v) => v != null).filter((i) => !(i.id === newItem.id || (i.isTemporary === true && newItem.isTemporary === true)));

			// const newItems = hasItem ? prevState.filter((i, index) => index !== dragIndex) : [...prevState];

			const newItems = hasItem ? realTimeTaskList.current.filter((i, index) => index !== dragIndex) : [...realTimeTaskList.current];

			// const newItems = [...prevState];
			newItems.splice(hoverIndex, 0, newItem);
			return [...newItems].filter((v) => v != null).filter((v, i, a) => a.findIndex((v2) => (v2.id === v.id)) === i); // TODO - FILTER
		});
	};

	return (
		<S.Wrapper
			ref={taskList && taskList.length > 0 && canDrop ? drop : null}
			className={containerClassName}
			isOver={isOver}
		>
			<S.ScrollWrapper>
				{emptyIsOver && <PlaceholderCard />}
				{taskList && taskList.length === 0 && showEmptyMessage && !emptyIsOver
					? (
						<S.EmptyWrapper>
							<EmptyComponent message="Your task backlog is empty" icon={['fal', 'list']} />
						</S.EmptyWrapper>
					)
					: taskList && [...taskList].map((task, index) => task && (
						<TaskCard
							key={isOver && hoverTask && hoverTask.id === task.id ? uuid() : task.id}
							index={index}
							task={task}
							dispatch={dispatch}
							handleMoveTask={handleMoveTask}
							isPlaceholder={isOver && hoverTask && hoverTask.id === task.id}
							isBacklog={isBacklog}
						/>
					))}
			</S.ScrollWrapper>
		</S.Wrapper>
	);
};

export default TaskList;

TaskList.displayName = 'TaskList';
TaskList.propTypes = {
	tasks: PropTypes.array,
	dispatch: PropTypes.object,
	containerClassName: PropTypes.string,
	showEmptyMessage: PropTypes.bool,
	emptyIsOver: PropTypes.bool,
	isBacklog: PropTypes.bool
};
