import React from 'react';
import PropTypes from 'prop-types';

import * as S from './Navigation.styles';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';

export const Navigation = ({ children, links, logoLink = '/' }) => (
	<S.Navigation>
		<Topbar />
		<S.Wrapper>
			<Sidebar links={links} logoLink={logoLink} />
			<S.Content>{children}</S.Content>
		</S.Wrapper>
	</S.Navigation>
);

Navigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	logoLink: PropTypes.string,
	links: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			to: PropTypes.string,
			label: PropTypes.string.isRequired,
			icon: PropTypes.string,
		})
	),
};
