import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	svg {
		color: ${colors.neutralText};
	}
`;
