import styled from 'styled-components';
import colors from '../../styles/colors';

export const Banner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 3rem;
	color: white;
	text-transform: uppercase;

	background-color: ${({ isCurrent }) => (isCurrent ? colors.brandSecondary : colors.brandPrimary)};
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3rem 3.5rem 2rem 3.5rem;
	border: 1px solid ${colors.neutralForm};
`;

export const PriceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 7rem;

	h1 {
		color: ${colors.brandSecondary};
	}

	h2 {
		text-transform: uppercase;
	}

	h6 {
		color: ${colors.neutralText};
		text-transform: capitalize;
	}

	.custom-text {
		color: ${colors.brandSecondary};
		margin-top: .5rem;
	}

	.pricing-text {
		color: ${colors.neutralText};
		margin-top: -.5rem;
	}
`;

export const ServicesWrapper = styled.div`
	height: 5rem;
`;

export const ServicesRow = styled.div`
	display: flex;
	align-items: center;

	svg {
		color: ${colors.brandSecondary};
		margin-right: .5rem;
	}

	.service-value {
		margin-right: .25rem;
	}
`;

export const ActionWrapper = styled.div`
	button {
		svg {
			margin-right: .5rem;
		}
	}
`;
