import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Radio, Typography, IconButton
} from '../..';
import * as S from './PaymentMethod.styles';

export const PaymentMethod = ({
	method, checked, canRemovePaymentMethod, handleOpenDeleteModal, handleSelectedMethod, user
}) => (
	<S.Wrapper>
		<S.LeftContent>
			<Radio value={method.id} name="payment-method" checked={checked} onChange={() => handleSelectedMethod(method.id, user)} />
			<FontAwesomeIcon icon={['fas', 'credit-card']} />
			<Typography tag="p">{`${method.card.brand} **** **** **** ${method.card.last4}`}</Typography>
		</S.LeftContent>
		{canRemovePaymentMethod && checked && (
			<S.RightContent>
				<IconButton icon={['fal', 'trash']} size={1.25} onClick={handleOpenDeleteModal} />
			</S.RightContent>
		)}
	</S.Wrapper>
);

PaymentMethod.displayName = 'PaymentMethod';
PaymentMethod.propTypes = {
	method: PropTypes.object,
	checked: PropTypes.bool,
	canRemovePaymentMethod: PropTypes.bool,
	handleOpenDeleteModal: PropTypes.func,
	handleSelectedMethod: PropTypes.func,
	user: PropTypes.object
};
