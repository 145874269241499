import React from 'react';
import PropTypes from 'prop-types';

import {
	Modal, IconButton, Button, Typography
} from '..';
import * as S from './DeleteModal.styles';

export const DeleteModal = ({
	type,
	isOpen,
	onRequestClose,
	content,
	handleDelete,
	isDeleting,
}) => (
	<Modal isOpen={isOpen} onRequestClose={onRequestClose} maxWidth="45.875rem" noOverflow>
		<S.Wrapper>
			<S.ActionsWrapper>
				<IconButton icon={['fal', 'times']} size={1.25} className="icon close-icon" onClick={onRequestClose} />
			</S.ActionsWrapper>
			<S.InfoWrapper>
				<Typography tag="h2" weight="bold">
					{`Delete ${type}`}
				</Typography>
				<Typography tag="p">
					{content || `Are you sure you want to delete this ${type}?`}
				</Typography>
			</S.InfoWrapper>
			<S.ButtonRow>
				<Button variant="outline" className="submit-button" onClick={onRequestClose}>
					<Typography variation="button-medium" weight="bold">
						Cancel
					</Typography>
				</Button>
				<Button variation="warning" className="submit-button" onClick={handleDelete}>
					<Typography variation="button-medium" weight="bold">
						{`Delet${isDeleting ? 'ing...' : 'e'}`}
					</Typography>
				</Button>
			</S.ButtonRow>
		</S.Wrapper>
	</Modal>
);

DeleteModal.displayName = 'DeleteModal';
DeleteModal.propTypes = {
	type: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	content: PropTypes.string,
	handleDelete: PropTypes.func.isRequired,
	isDeleting: PropTypes.bool.isRequired,
};
