import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { required } from '../../../utils/form-default-errors';
import {
	Typography, TextInput
} from '../..';
import * as S from './BillingForm.styles';

export const BillingForm = ({ address, handleAddressChange }) => {
	const {
		register,
		formState: { errors },
	} = useForm();

	const handleAddress = (e) => {
		const { id, value } = e.target;
		handleAddressChange(id, value);
	};

	return (
		<S.Wrapper>
			<Typography tag="h5" weight="bold" center>
				Billing Address
			</Typography>
			<S.Form>
				<TextInput
					id="street"
					placeholder="Street"
					error={errors.street}
					autoComplete="street"
					{...register('street', {
						required: required('street'),
					})}
					value={address.street}
					onChange={handleAddress}
				/>
				<S.FormRow>
					<TextInput
						id="city"
						placeholder="City"
						error={errors.city}
						autoComplete="city"
						className="text-input"
						{...register('city', {
							required: required('City'),
						})}
						value={address.city}
						onChange={handleAddress}
					/>
					<TextInput
						id="state"
						placeholder="State"
						error={errors.state}
						autoComplete="state"
						className="text-input"
						{...register('state', {
							required: required('State'),
						})}
						value={address.state}
						onChange={handleAddress}
					/>
					<TextInput
						id="postalCode"
						placeholder="Zipcode"
						error={errors.postalCode}
						autoComplete="postalCode"
						className="text-input"
						{...register('postalCode', {
							required: required('Zipcode'),
						})}
						value={address.postalCode}
						onChange={handleAddress}
					/>
				</S.FormRow>
			</S.Form>
		</S.Wrapper>
	);
};

BillingForm.displayName = 'BillingForm';
BillingForm.propTypes = {
	address: PropTypes.object,
	handleAddressChange: PropTypes.func
};
