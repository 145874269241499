import styled from 'styled-components';
import { Card as TaskCard } from '../../../components';

import colors from '../../../styles/colors';

export const Card = styled(TaskCard)`
	margin-top: .5rem;
	min-height: 3.75rem;
	padding: 1rem;
	background-color: ${({ status, isSent }) => (status === 'in_progress' ? colors.statePending : status === 'complete' ? colors.stateComplete : isSent ? colors.neutralForm : 'white')};
	position: relative;

	&:hover {
		cursor: pointer;
	}
`;

export const CardWrapper = styled.div`
	opacity: ${({ loading }) => (loading ? '.5' : '1')};
`;

export const Wrapper = styled.div`
	padding: .125rem;

	input {
		border: none;
		font-size: .75rem;
		padding: 0;
		min-height: 1rem;
		letter-spacing: .00625rem;
	}
`;

export const LabelWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: .5rem;
	color: ${colors.brandSecondary}
`;

export const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-right: .875rem;

	.notes-icon {
		transform: scale(1);
	}

	.map-icon {
		font-weight: 600;
	}
`;

export const TagWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;

export const LoadingWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;
