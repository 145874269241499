import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	thead {
		th {
			background-color: ${colors.lightNeutral};
			padding: .5rem 1rem;
			display: flex;
			align-items: center;

			&:first-child {
				border-radius: .25rem 0 0 .25rem ;
			}

			&:last-child {
				border-radius: 0 .25rem .25rem 0;
			}
		}
	}

	td {
		font-size: .875rem;
		padding: 0 !important;


		> * {
			height: 100%;
			padding: 1rem;
		}
	}
`;

export const SortingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: .625rem;

	svg {
		height: 1.125rem;
	}

	.sort-up-icon {
		margin-bottom: ${({ isSortedAsc }) => (isSortedAsc ? '.625rem' : '-.25rem')};
	}

	.sort-down-icon {
		margin-top: ${({ isSortedDesc }) => (isSortedDesc ? '.625rem' : '-.25rem')};
	}
`;
