import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 2rem;
	padding: 1rem 2rem;

	@media screen and (max-width: 992px) {
		grid-template-columns: 1fr;
		padding: 1rem;
	}
`;

export const ActionInfoWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	button {
		padding-right: 0;
		padding-top: 0;
		margin-bottom: 1.25rem;
	}
`;


export const InfoWrapper = styled.div`
	.info-label {
		color: ${colors.neutralText};
		margin-bottom: .5rem;
	}

	.info {
		margin-bottom: 2rem;
	}

	.plan-type, .payment-method, .permissions-text {
		text-transform: capitalize;
	}
`;

export const ProfileInfoWrapper = styled.div`
	display: flex;
	height: 8rem;

	> div {
		margin-right: 4rem;

		@media screen and (max-width: 1200px) {
			margin-right: 3rem;
		}
	}
`;


export const AccountInfoWrapper = styled.div`
	height: 8rem;
`;

export const BillingInfoWrapper = styled.div`
	hr {
		height: 1px;
        background-color: ${colors.neutralForm};
        border: none;
		margin-bottom: 1.5rem;
	}
`;

export const PlanUsageRow = styled.div`
	display: flex;
	align-items: center;
`;

export const PlanUsageInfoWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-right: 3rem;
	margin-bottom: 2rem;

	svg {
		margin-right: .625rem;
	}
`;
