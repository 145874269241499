import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { Button, Typography } from '..';
import * as S from './PricingTier.styles';

export const PricingTier = forwardRef(({
	type, title, price, frequency, services, isCustom, isCurrent, isSelected, handleSelectedTier
}, ref) => {
	const renderButtonText = () => {
		if (isSelected) {
			return 'Selected';
		} if (isCurrent) {
			return 'Keep Plan';
		} if (isCustom) {
			return 'Contact Us';
		} return 'Select Plan';
	};

	const handleSelected = () => {
		if (isCustom) {
			const emailTo = 'michaeldavidleach@gmail.com';
			const emailSub = 'Dispatchr Enterprise Inquiry';
			const emailBody = 'I am reaching out to inquire about how I can activate an Enterprise account for Dispatchr.';
			window.open(`mailto:${emailTo}?subject=${emailSub}&body=${emailBody}`, '_self');
		} else {
			handleSelectedTier(type);
		}
	};

	return (
		<div>
			{(isCurrent || isSelected) && <S.Banner isCurrent={isCurrent}><Typography tag="p" weight="bold" variation="2">{isCurrent ? 'Current Plan' : 'Selected Plan'}</Typography></S.Banner>}
			<S.Wrapper ref={ref} isCurrent={isCurrent} isSelected={isSelected}>
				<Typography tag="h4" weight="bold">
					{title}
				</Typography>
				<S.PriceWrapper>
					{isCustom ? (
						<>
							<Typography tag="h2" weight="semibold" className="custom-text">Custom</Typography>
							<Typography tag="h2" weight="semibold" className="pricing-text">Pricing</Typography>
						</>
					) : (
						<>
							<Typography tag="h1" weight="bold">{`$${price}`}</Typography>
							<Typography tag="h6">{frequency}</Typography>
						</>
					)}
				</S.PriceWrapper>
				<S.ServicesWrapper>
					{services.map((serviceValue, index) => {
						let text = '';
						switch (index) {
							case 0:
								text = 'Admins';
								break;
							case 1:
								text = 'Dispatches';
								break;
							default:
								text = '';
						}

						return (
							<S.ServicesRow key={text}>
								<FontAwesomeIcon icon={['fal', 'check-circle']} />
								<Typography tag="p" weight="bold" className="service-value">{!isCustom ? serviceValue : 'Unlimited'}</Typography>
								<Typography tag="p">{text}</Typography>
							</S.ServicesRow>
						);
					})}
				</S.ServicesWrapper>
				<S.ActionWrapper>
					<Button variant={isCurrent ? 'solid' : isSelected ? 'solid' : 'outline'} variation={isCurrent ? 'secondary' : isSelected ? 'default' : 'secondary'} onClick={handleSelected}>
						{isSelected && <FontAwesomeIcon icon={['fal', 'check-circle']} /> }
						{renderButtonText()}
					</Button>
				</S.ActionWrapper>
			</S.Wrapper>
		</div>
	);
});

PricingTier.displayName = 'PricingTier';
PricingTier.propTypes = {
	type: PropTypes.string,
	title: PropTypes.string,
	price: PropTypes.number,
	frequency: PropTypes.string,
	services: PropTypes.array,
	isCustom: PropTypes.bool,
	isCurrent: PropTypes.bool,
	isSelected: PropTypes.bool,
	handleSelectedTier: PropTypes.func
};
