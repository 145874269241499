import api from '../api';

export const updateUserProfile = (data) => api.post('/core/functions/updateUserProfile', data);

export const checkEmailExists = (data) => api.post('/core/functions/checkEmailExists', data);

export const addUpdatePaymentMethod = (data) => api.post('/core/functions/addUpdatePaymentMethod', data);

export const removePaymentMethod = (data) => api.post('/core/functions/removePaymentMethod', data);

export const uploadMedia = (data) => api.post('/users/upload', data);
