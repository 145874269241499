import React, { useState, useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotesRounded } from '@mui/icons-material';
import ReactTooltip from 'react-tooltip';
import debounce from 'lodash.debounce';

import { useDispatch } from 'react-redux';
import {
	addTask, moveTask, addTaskToBacklog, removeTaskFromBacklog, removePlaceholderTask, updateOrderByTask
} from '../../../app/slices/tasks/tasksSlice';
import {
	addDispatchTask, deleteDispatchTask, removeTaskFromDispatch, addTaskToDispatch, removeDispatchTaskInState, addDispatchTaskInState, updateOrderDispatchTasksByTask
} from '../../../app/slices/dispatches/dispatchesSlice';
import { errorHandler } from '../../../services/api';

import {
	TagPill, Typography, TextInput, Loader
} from '../../../components';
import { TaskModal } from '../TaskModal';
import { PlaceholderCard } from '../PlaceholderCard';
import * as S from './TaskCard.styles';

import { useUserPermissions } from '../../../hooks/useUserPermissions';

const TaskCard = ({
	task, dispatch, handleMoveTask, index, isPlaceholder, isBacklog
}) => {
	const reduxDispatch = useDispatch();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [content, setContent] = useState('');
	const [loading, setLoading] = useState(false);
	const { canDrop } = useUserPermissions(isBacklog);

	const ref = useRef(null);

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	const [, drop] = useDrop({
		accept: 'task',
		hover: debounce((item, monitor) => {
			if (!ref.current) {
				return;
			}

			const dragIndex = item.index;
			const hoverIndex = index;

			const hoveredRect = ref.current.getBoundingClientRect();

			// const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
			const mousePosition = monitor.getClientOffset();

			// const hoverClientY = mousePosition.y - hoveredRect.top;
			const isInside = mousePosition.y >= hoveredRect.top && mousePosition.y <= hoveredRect.bottom;

			if (dragIndex === hoverIndex) {
				return;
			}

			/* if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			} */
			if (isInside) {
				handleMoveTask(dragIndex, hoverIndex, item);
				item.index = hoverIndex;
			}
		}, 8, { leading: true, trailing: false }),
		drop(item) {
			if (dispatch && item.dispatchId === dispatch.id && item.placement === 'dispatch') {
				reduxDispatch(moveTask({
					taskId: item.id, targetPlacement: 'dispatch', position: item.index
				})).then((res) => {
					const payload = res?.payload;
					reduxDispatch(updateOrderDispatchTasksByTask(payload));
				});
			} else if (dispatch && item.dispatchId && item.dispatchId !== dispatch.id && item.placement === 'dispatch') {
				reduxDispatch(addDispatchTask({ ...item, dispatchId: dispatch.id, position: item.index }));
				reduxDispatch(deleteDispatchTask({ ...item, dispatchId: item.dispatchId }));
				reduxDispatch(moveTask({
					taskId: item.id, targetPlacement: 'dispatch', position: item.index, targetDispatchId: dispatch.id, previousDispatchId: item.dispatchId
				})).catch((err) => errorHandler(err));
			} else if (item.placement === 'backlog' && dispatch) {
				reduxDispatch(addDispatchTask({
					...item, dispatchId: dispatch.id, placement: 'dispatch', position: item.index
				}));
				reduxDispatch(removeTaskFromBacklog(item));
				reduxDispatch(moveTask({
					taskId: item.id, targetPlacement: 'dispatch', position: item.index, targetDispatchId: dispatch.id
				})).catch((err) => errorHandler(err));
			} else {
				if (item.placement === 'dispatch') {
					reduxDispatch(removeDispatchTaskInState({
						task: item, dispatchId: item.dispatchId, placement: 'backlog', isSent: false, status: 'not_started'
					}));
					reduxDispatch(addTaskToBacklog({
						...item, placement: 'backlog', isSent: false, status: 'not_started', position: item.index
					}));
					reduxDispatch(removeTaskFromDispatch({
						taskId: item.id, dispatchId: item.dispatchId, placement: 'backlog', isSent: false, status: 'not_started'
					})).catch(() => {
						reduxDispatch(addDispatchTaskInState({ task: item, id: dispatch.id, position: item.order }));
						reduxDispatch(removeTaskFromBacklog(item));
					});
				}
				reduxDispatch(moveTask({
					taskId: item.id, targetPlacement: 'backlog', position: item?.index ? item.index : 0
				})).then((res) => {
					const payload = res?.payload;
					reduxDispatch(updateOrderByTask(payload));
				}).catch((err) => errorHandler(err));
			}
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: 'task',
		item: { type: 'task', ...task, index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		})
	});

	const updateTaskContent = () => {
		if (content) {
			setLoading(true);
			reduxDispatch(addTask({ content, placement: dispatch ? 'dispatch' : 'backlog', tempId: task.id.includes('temp-id') ? task.id : '' })).then((res) => {
				if (res?.payload?.placement === 'dispatch') {
					const resObj = {
						taskId: res.payload.id,
						dispatchId: dispatch.id,
						tempId: task.id.includes('temp-id') ? task.id : ''
					};
					reduxDispatch(addTaskToDispatch(resObj));
				}
				setLoading(false);
			});
		} else if (!task.dispatchId) {
			reduxDispatch(removePlaceholderTask(task));
		} else if (task.dispatchId) {
			reduxDispatch(deleteDispatchTask(task));
		}
	};

	const handleCloseModal = (e) => {
		e.stopPropagation();
		setModalIsOpen(false);
	};

	const handleContentChange = (e) => {
		setContent(e.target.value);
	};

	const handleKeyPress = (e) => {

		// react to pressing enter and saving task content
		if (e.keyCode === 13) {
			e.target.blur();
		}
	};

	if (canDrop) {
		drag(drop(ref));
	}

	if (isPlaceholder) {
		return (
			<div ref={ref}>
				<PlaceholderCard isBacklog={isBacklog} />
			</div>
		);
	}

	return (
		<div role="button" onClick={task.content && !loading ? () => setModalIsOpen(true) : (e) => e.preventDefault()} onKeyDown={(e) => e.stopPropagation()} tabIndex={0} ref={ref} style={{ opacity: isDragging ? 0 : 1 }}>
			<S.Card className="task-card" status={task.status} isSent={(task.isSent) && task.placement === 'dispatch'}>
				{loading && <S.LoadingWrapper><Loader /></S.LoadingWrapper>}
				<S.CardWrapper loading={loading ? 'true' : undefined}>
					<S.Wrapper>
						{!task.content ? (
							<TextInput
								id="content"
								containerClassName="text-input task-content-input"
								onChange={handleContentChange}
								value={content}
								onBlur={updateTaskContent}
								onKeyDown={(e) => handleKeyPress(e)}
								autoFocus
							/>
						) : (
							<Typography tag="p" variation="3">
								{task.content}
							</Typography>
						)}
					</S.Wrapper>
					<S.LabelWrapper>
						{task.location && (
							<S.IconWrapper data-tip={task.location}>
								<FontAwesomeIcon icon={['fal', 'map-marker-alt']} className="icon map-icon" />
							</S.IconWrapper>
						)}

						{task.notes && (
							<S.IconWrapper data-tip={task.notes}>
								<NotesRounded className="icon notes-icon" />
							</S.IconWrapper>
						) }
						<S.TagWrapper>
							{task.tags && task.tags.length > 0 && [...task.tags].sort((a, b) => new Date(a.createdAt.iso).getTime() - new Date(b.createdAt.iso).getTime()).map((tag) => <TagPill key={tag.id} name={tag.title} />)}
						</S.TagWrapper>

					</S.LabelWrapper>
				</S.CardWrapper>
			</S.Card>
			<TaskModal task={task} isOpen={modalIsOpen} onRequestClose={handleCloseModal} />
		</div>
	);


};

export default TaskCard;

TaskCard.displayName = 'TaskCard';
TaskCard.propTypes = {
	task: PropTypes.object,
	dispatch: PropTypes.object,
	handleMoveTask: PropTypes.func,
	index: PropTypes.number,
	isPlaceholder: PropTypes.bool,
	isBacklog: PropTypes.bool
};
