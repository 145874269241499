export const PRICING_PLANS = {
	free: {
		price: 0,
		admins: 3,
		dispatches: 200
	},
	standard: {
		price: 35,
		admins: 1,
		dispatches: 1000
	},
	pro: {
		price: 70,
		admins: 3,
		dispatches: 3000
	}
};
