import api from '../api';

export const fetchTasks = (data) => api.post('/core/functions/fetchTasks', data);

export const createUpdateTask = (data) => api.post('/core/functions/createUpdateTask', data);

export const duplicateTask = (data) => api.post('/core/functions/duplicateTask', data);

export const deleteTask = (data) => api.post('/core/functions/deleteTask', data);

export const moveTask = (data) => api.post('/core/functions/moveTask', data);

export const updateTaskStatus = (data) => api.post('/core/functions/updateTaskStatus', data);
