import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
	Modal, IconButton, Button, Typography
} from '..';
import * as S from './LogoutModal.styles';

export const LogoutModal = ({
	isOpen,
	onRequestClose,
}) => {
	const history = useHistory();

	const handleLogout = () => {
		history.push('/logout');
	};

	return (
		<Modal isOpen={isOpen} onRequestClose={onRequestClose} maxWidth="35rem" noOverflow>
			<S.Wrapper>
				<S.ActionsWrapper>
					<IconButton icon={['fal', 'times']} size={1.25} className="icon close-icon" onClick={onRequestClose} />
				</S.ActionsWrapper>
				<S.InfoWrapper>
					<Typography tag="h2" weight="bold">
						Logout
					</Typography>
					<Typography tag="p">
						Are you sure you want to logout?
					</Typography>
				</S.InfoWrapper>
				<S.ButtonRow>
					<Button variant="outline" className="submit-button" onClick={onRequestClose}>
						<Typography variation="button-medium" weight="bold">
							Cancel
						</Typography>
					</Button>
					<Button variation="warning" className="submit-button" onClick={handleLogout}>
						<Typography variation="button-medium" weight="bold">
							Logout
						</Typography>
					</Button>
				</S.ButtonRow>
			</S.Wrapper>
		</Modal>
	);
};
LogoutModal.displayName = 'LogoutModal';
LogoutModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired
};
