import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

export const PublicRoute = ({ component: Component, path, exact = false }) => {
	const render = (props) => <Component {...props} />;

	return <Route exact={exact} path={path} render={render} />;
};

PublicRoute.propTypes = {
	component: PropTypes.func.isRequired,
	path: PropTypes.string,
	exact: PropTypes.bool,
};
