import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	& > label {
		cursor: pointer;
		color: ${({ error }) => (error ? colors.stateDanger : colors.neutralText)};
		text-transform: uppercase;
	}

	.react-tel-input, .form-control {
		width: 100% !important;
		min-height: 2.8125rem;
	}

	.react-tel-input {
		margin-top: .25rem;
	}

	.form-control {
		border-radius: 0.25rem;
		border: 1px solid ${colors.neutralForm};
		color: black;
		line-height: 'normal';
		width: 100%;
		outline: none;

		&:focus {
			border-color: ${colors.neutralButton};
		}

		&:read-only {
			background-color: ${colors.neutralBackground};
			border-color: ${colors.neutralForm};
		}
	}

	${({ error }) => error
		&& css`
			color: ${colors.stateDanger};
			border-color: ${colors.stateDanger};
			margin-bottom: .75rem;
			:focus {
				border-color: ${colors.stateDanger};
			}
		`}

`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: -1rem;
	color: ${colors.stateDanger};
	max-width: 100%;

	p {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		margin-bottom: .5rem;
	}
`;
