import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	width: 50%;

	hr {
		height: 1px;
        background-color: ${colors.neutralForm};
        border: none;
		margin-bottom: 1.5rem;
	}

	@media screen and (max-width: 992px) {
		width: 70%;
	}

	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

export const InfoWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 1.5rem;
	padding-top: 1rem;
`;

export const LeftContent = styled.div`
	.selected-tier, .payment-method {
		text-transform: capitalize;
	}
`;

export const RightContent = styled.div``;

export const TotalWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 1.5rem;
	padding-top: 1rem;
`;
