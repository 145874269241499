import React, {
	useState, useEffect, useRef, useCallback
} from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import { useDispatch, useSelector } from 'react-redux';
import {
	fetchArchiveTasks
} from '../../app/slices/tasks/tasksSlice';

import { statusText } from '../../utils/status-helpers';

import {
	UserNavigation, PageHeader, Pagination, DebouncedSearch, ScrollableContainer
} from '../../components';
import { TaskTable } from './TaskTable';
import { Filter } from './Filter';
import * as S from './TaskList.styles';

import { usePaywall } from '../../hooks/usePaywall';
import { shouldShowPaywall } from '../../utils/should-show-paywall';

import { useUserPermissions } from '../../hooks/useUserPermissions';

import { META_TITLE } from '../../../constants/general.constants';

const TaskList = () => {
	const [filteredTasks, setFilteredTasks] = useState([]);
	const [activeFilter, setActiveFilter] = useState(false);
	const [currentFilters, setCurrentFilters] = useState(null);
	const [dataToDownload, setDataToDownload] = useState([]);
	const [isDownloading, setIsDownloading] = useState(false);
	const [filterCount, setFilterCount] = useState(0);
	const [page, setPage] = useState(0);
	const [searchValue, setSearchValue] = useState('');
	const [sort, setSort] = useState(null);
	const csvRef = useRef(null);
	const {
		taskArchive: tasks, status: tasksStatus, totalCount: tasksTotal
	} = useSelector((state) => state.tasks);
	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();
	const { showPaywall, Paywall } = usePaywall();
	const filterCountRef = useRef();
	const filteredTasksRef = useRef();
	const currentFiltersRef = useRef();
	const { hasAdminAccess } = useUserPermissions();
	const pageSize = 10;
	const csvName = `dispatchr-export-${moment().format('MM-DD-YYYY')}`;

	filterCountRef.current = filterCount;
	filteredTasksRef.current = filteredTasks;
	currentFiltersRef.current = currentFilters;

	useEffect(() => {
		document.title = `Job Archive | ${META_TITLE}`;

		if (shouldShowPaywall(user)) {
			showPaywall();
		}
	}, [user]);

	useEffect(() => {
		setFilteredTasks(tasks);
	}, [tasks]);

	const handleUpdateFilters = (filters, reset) => {
		let count = 0;
		setPage(0);

		if (reset) {
			setFilteredTasks(tasks);
			setActiveFilter(false);
			setCurrentFilters(null);
			if (window.location.href.indexOf('job-archive') !== -1) {
				dispatch(fetchArchiveTasks({
					placement: 'dispatch', limit: pageSize, page: 0, sortDirection: sort?.direction, sortId: sort?.id
				}));
			}
		} else {
			const {
				startDate, endDate, status, tags
			} = filters;
			setActiveFilter(true);
			setCurrentFilters(filters);
			if (startDate && endDate && startDate.getTime() !== endDate.getTime()) {
				count += 1;
			}

			if (!Object.values(status).every((value) => value === false)) {
				const truthyKeys = Object.keys(status).filter((k) => status[k] === true);
				count += truthyKeys.length;
			}

			if (tags.length > 0) {
				count += tags.length;
			}

			dispatch(fetchArchiveTasks({
				placement: 'dispatch', filters: JSON.stringify(filters), limit: pageSize, page: 0, sortDirection: sort?.direction, sortId: sort?.id
			}));
		}

		setFilterCount(count);
	};

	const handleSearch = (value) => {
		setPage(0);
		setSearchValue(value);
		if (filterCountRef.current > 0 && value) {
			dispatch(fetchArchiveTasks({
				placement: 'dispatch', searchText: value, filters: JSON.stringify(currentFiltersRef.current), limit: pageSize, page: 0, sortDirection: sort?.direction, sortId: sort?.id
			}));
		} else if (filterCountRef.current > 0 && !value) {
			dispatch(fetchArchiveTasks({
				placement: 'dispatch', filters: JSON.stringify(currentFiltersRef.current), limit: pageSize, page: 0, sortDirection: sort?.direction, sortId: sort?.id
			}));
		} else {
			dispatch(fetchArchiveTasks({
				placement: 'dispatch', searchText: value, limit: pageSize, page: 0, sortDirection: sort?.direction, sortId: sort?.id
			}));
		}
	};

	const handlePageChange = useCallback((newPageNum) => {
		setPage(newPageNum);
		if (filterCountRef.current > 0 && searchValue) {
			dispatch(fetchArchiveTasks({
				placement: 'dispatch', searchText: searchValue, filters: JSON.stringify(currentFiltersRef.current), limit: pageSize, page: newPageNum, sortDirection: sort?.direction, sortId: sort?.id
			}));
		} else if (filterCountRef.current > 0 && !searchValue) {
			dispatch(fetchArchiveTasks({
				placement: 'dispatch', filters: JSON.stringify(currentFiltersRef.current), limit: pageSize, page: newPageNum, sortDirection: sort?.direction, sortId: sort?.id
			}));
		} else if (searchValue) {
			dispatch(fetchArchiveTasks({
				placement: 'dispatch', searchText: searchValue, limit: pageSize, page: newPageNum, sortDirection: sort?.direction, sortId: sort?.id
			}));
		} else {
			dispatch(fetchArchiveTasks({
				placement: 'dispatch', limit: pageSize, page: newPageNum, sortDirection: sort?.direction, sortId: sort?.id
			}));
		}
	}, [dispatch, searchValue, sort]);

	const downloadCSV = async () => {
		const dataArr = [
			['Date', 'Description', 'Contacts', 'Location', 'Status', 'Notes', 'Dispatch #', 'Tags']
		];
		setIsDownloading(true);

		dispatch(fetchArchiveTasks({
			placement: 'dispatch', searchText: searchValue || null, filters: filterCountRef.current > 0 ? JSON.stringify(currentFiltersRef.current) : null, shouldSkipState: true, sortDirection: sort?.direction, sortId: sort?.id
		})).then((res) => {
			res.payload.tasks.forEach((task) => {
				const { dispatch: taskDispatch } = task;
				if (taskDispatch != null) {
					const taskArr = [
						moment(taskDispatch.targetDate.iso, 'YYYY-MM-DD').format('MM-DD-YYYY'),
						task.content,
						taskDispatch.assignees && taskDispatch.assignees.length > 0 ? taskDispatch.assignees.map((assignee) => `${assignee.firstName} ${assignee.lastName ? assignee.lastName : ''}`).join(', ') : '',
						task.location || '',
						statusText(task.status),
						task.notes || '',
						taskDispatch.dispatchNumber,
						task.tags && task.tags.length > 0 ? [...task.tags].sort((a, b) => new Date(a.createdAt.iso).getTime() - new Date(b.createdAt.iso)).map((tag) => `${tag.title}`).join(', ') : ''
					];
					dataArr.push(taskArr);
				}
			});

			setDataToDownload(dataArr);
		}).then(() => {
			setTimeout(() => {
				csvRef.current.link.click();
				setIsDownloading(false);
			}, 500);
		}).catch(() => {
			setIsDownloading(false);
		});
	};

	const handleSort = (value) => {
		const sortValue = { id: value[0].id, direction: value[0].desc ? 'desc' : 'asc' };
		if (!sort) {
			setSort(sortValue);
			dispatch(fetchArchiveTasks({
				placement: 'dispatch', searchText: searchValue || null, filters: filterCountRef.current > 0 ? JSON.stringify(currentFiltersRef.current) : null, sortDirection: sortValue.direction, sortId: sortValue.id, limit: pageSize, page: 0,
			}));
		} else if (sort) {
			if (sort.id !== sortValue.id || sort.direction !== sortValue.direction) {
				setSort(sortValue);
				dispatch(fetchArchiveTasks({
					placement: 'dispatch', searchText: searchValue || null, filters: filterCountRef.current > 0 ? JSON.stringify(currentFiltersRef.current) : null, sortDirection: sortValue.direction, sortId: sortValue.id, limit: pageSize, page: 0,
				}));
			}
		}
	};

	return (
		<UserNavigation>
			<PageHeader
				title="Job Archive"
				action={filteredTasks && filteredTasks.length > 0 && hasAdminAccess ? [{
					id: 'task-list-header', onClick: () => downloadCSV(), label: `${isDownloading ? 'Exporting' : 'Export CSV'}`, icon: 'upload-file', variation: 'secondary'
				}] : []}
				loading={isDownloading}
			/>
			<S.Wrapper hasPagination={tasksTotal > pageSize}>
				<CSVLink
					data={dataToDownload}
					filename={csvName}
					ref={csvRef}
					target="_blank"
				/>
				<S.ActionsWrapper>
					<Filter updateFilters={handleUpdateFilters} filterCount={filterCount} />
					<S.SearchWrapper>
						<DebouncedSearch handleSearch={handleSearch} />
					</S.SearchWrapper>
				</S.ActionsWrapper>
				<ScrollableContainer className="table-scrollable-container">
					<TaskTable tasks={filteredTasks} isFilterActive={activeFilter} handleSort={handleSort} loading={tasksStatus === 'loading'} />
				</ScrollableContainer>
				<Pagination
					onPageChange={(pageNumber) => handlePageChange(pageNumber)}
					totalCount={tasksTotal}
					currentPage={page}
					pageSize={pageSize}
				/>
			</S.Wrapper>
			<Paywall />
		</UserNavigation>

	);
};

export default TaskList;
