import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { fetchDispatch, updateWorkerDispatchTask } from '../../app/slices/dispatches/dispatchesSlice';
import {
	updateTaskStatus
} from '../../services/tasks/taskService';
import { errorHandler } from '../../services/api';

import { useQuery } from '../../hooks/useQuery';

import {
	Spinner, Typography, Card, EmptyComponent
} from '../../components';
import { WorkerTaskCard } from './WorkerTaskCard';
import * as S from './WorkerDashboard.styles';
import { META_TITLE } from '../../../constants/general.constants';

const WorkerDashboard = () => {
	const [selectedAssignee, setSelectedAssignee] = useState(null);
	const [sortedTasks, setSortedTasks] = useState([]);
	const reduxDispatch = useDispatch();
	const { dispatchToken, assigneeToken } = useParams();
	const { dispatch, status: dispatchStatus } = useSelector((state) => state.dispatches);
	const isAdmin = useQuery('view') === 'admin';

	useEffect(() => {
		document.title = `Dashboard | ${META_TITLE}`;
		reduxDispatch(fetchDispatch({ dispatchToken, assigneeToken }));
	}, []);

	const handleTaskStatus = async (task, status) => {
		await updateTaskStatus({
			taskId: task.id,
			assigneeId: selectedAssignee.id,
			status,
			dispatchToken
		}).then((res) => {
			reduxDispatch(updateWorkerDispatchTask(res.data.result.task));
		}).catch((err) => errorHandler(err));
	};

	useEffect(() => {

		if (dispatch && dispatch.assignees.length > 0) {
			const assignee = dispatch.assignees.find((a) => a.token === assigneeToken);
			setSelectedAssignee(assignee);
		}

		if (dispatch && dispatch.tasks.length > 0 && !isAdmin) {
			Promise.all(dispatch.tasks.map(async (task) => {
				if (task.status === 'not_started') {
					await handleTaskStatus(task, 'in_progress');
				}
			}));
		}

		setSortedTasks(dispatch && dispatch.tasks.length > 0 ? [...dispatch.tasks].sort((a, b) => a.order - b.order) : []);

	}, [dispatch, assigneeToken]);

	return (
		<S.Wrapper>
			{!dispatch ? <S.EmptyWrapper><Card className="empty-card"><EmptyComponent message="This dispatch does not exist. Please get in contact with the administrator" icon={['fal', 'question-circle']} /></Card></S.EmptyWrapper> : dispatchStatus === 'loading' ? <Spinner /> : (
				<S.Content>
					<S.HeaderWrapper>
						<S.TitleWrapper>
							<Typography tag="h6" weight="semibold">{selectedAssignee ? `${selectedAssignee.firstName} ${selectedAssignee.lastName ? selectedAssignee.lastName : ''}` : ''}</Typography>
							<Typography tag="h2" weight="semibold">{moment(dispatch.targetDate.iso, 'YYYY-MM-DD').format('ddd MMM DD')}</Typography>
						</S.TitleWrapper>
						{isAdmin && (
							<S.AdminWrapper>
								<Typography tag="p" variation="2">Admin View</Typography>
							</S.AdminWrapper>
						)}
					</S.HeaderWrapper>
					<S.TaskWrapper>
						{sortedTasks.map((task) => <WorkerTaskCard key={task.id} task={task} handleTaskStatus={handleTaskStatus} />)}
					</S.TaskWrapper>
				</S.Content>
			)}
		</S.Wrapper>
	);
};

export default WorkerDashboard;
