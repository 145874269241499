import styled from 'styled-components';

export const Button = styled.button`
	display: flex;
	appearance: none;
	border: none;
	background-color: transparent;
	color: inherit;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}

	svg {
		width: ${({ size }) => size}rem !important;
		height: ${({ size }) => size}rem !important;
	}
`;
