import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
`;

export const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;

	.close-icon {
		margin-left: .75rem;
	}
`;

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1rem;

	h2, p {
		margin-bottom: .5rem;
	}
`;

export const Content = styled.div`
	width: 60%;
	padding: 2rem 0;

	@media screen and (max-width: 992px) {
		width: 75%;
	}

	@media screen and (max-width: 768px) {
		width: 90%;
	}
`;

export const FooterActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 1rem;

	button {
		margin: 0 .5rem;
	}
`;
