import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './Stepper.styles';

export const Stepper = ({
	activeStep = 0, steps = [], width = 10, maxWidth = 10
}) => (
	<S.Wrapper>
		<S.Container width={width} maxWidth={maxWidth}>
			{steps.map(({ id, label }, index) => (
				<React.Fragment key={id}>
					{index !== 0 && <S.Line />}
					<S.Dot status={activeStep === index ? 'active' : activeStep > index ? 'completed' : null}>
						<FontAwesomeIcon className="step-icon" icon={['fal', 'check']} />
						<span className="step-number">{id}</span>
						<span className="step-label">{label}</span>
					</S.Dot>
				</React.Fragment>
			))}
		</S.Container>
	</S.Wrapper>
);

Stepper.propTypes = {
	activeStep: PropTypes.number.isRequired,
	steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	width: PropTypes.number,
	maxWidth: PropTypes.number,
};
