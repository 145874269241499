import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { statusText, statusIcon, statusColor } from '../../../utils/status-helpers';

import {
	Table, Link, Card, EmptyComponent, Typography, Spinner
} from '../../../components';
import * as S from './TaskTable.styles';

const TaskTable = ({
	tasks, isFilterActive, handleSort, loading
}) => {
	const columns = useMemo(
		() => [
			{
				Header: 'Date',
				accessor: 'dispatch.targetDate.iso',
				width: 120,
				Cell: (data) => {
					const { value } = data;
					return (
						<div className="date-table-cell">{value ? moment(value, 'YYYY-MM-DD').format('MM-DD-YYYY') : '---'}</div>
					);
				}
			},
			{
				Header: 'Description',
				accessor: 'content',
				width: 210,
				Cell: (data) => {
					const { value } = data;
					return (
						<div className="task-description-table-cell">{value}</div>
					);
				}
			},
			{
				Header: 'Contacts',
				accessor: 'dispatch.assignees',
				Cell: (data) => {
					const { value } = data;
					return (
						<div className="contact-table-cell">
							{value ? value.map((assignee) => `${assignee.firstName} ${assignee.lastName ? assignee.lastName : ''}`).join(', ') : '---'}
						</div>
					);

				}
			},
			{
				Header: 'Location',
				accessor: 'location',
				Cell: (data) => {
					const { value } = data;
					return <div className="contact-table-cell">{value}</div>;
				}
			},
			{
				Header: 'Status',
				accessor: 'status',
				width: 150,
				Cell: (data) => {
					const { value } = data;
					return (
						<div className="status-table-cell" style={{ backgroundColor: statusColor(value) }}>
							<FontAwesomeIcon icon={['fal', statusIcon(value)]} />
							<Typography tag="p" variation="2" weight="semibold">{statusText(value)}</Typography>
						</div>
					);
				}
			},
			{
				Header: 'Dispatch #',
				accessor: 'dispatch.dispatchNumber',
				width: 150,
				Cell: (data) => {
					const { value } = data;
					return (
						<Link className="dispatch-id-table-cell" to="/dashboard">{value}</Link>
					);
				}
			},
			{
				Header: 'Tags',
				accessor: 'tags',
				width: 175,
				Cell: (data) => {
					const { value } = data;
					if (value && value.length > 0) {
						return (
							<div className="tags-table-cell">
								<div className="tags-wrapper">
									{[...value].sort((a, b) => new Date(a.createdAt.iso).getTime() - new Date(b.createdAt.iso)).map((tag) => <div className="tag-pill" key={tag.id}>{tag.title}</div>)}
								</div>
							</div>
						);
					}
				},
				disableSortBy: true
			},
		],
		[]
	);

	return (
		<S.Wrapper>
			{tasks && tasks.length > 0 ? <Table columns={columns} data={tasks} onSort={handleSort} loading={loading} hasManualSort /> : loading ? <Spinner /> : <Card><EmptyComponent message={isFilterActive ? 'No matches found for your filter' : 'There are no jobs currently listed for any contacts'} icon={['fal', 'list']} /></Card>}
		</S.Wrapper>
	);
};

export default TaskTable;
TaskTable.displayName = 'TaskTable';
TaskTable.propTypes = {
	tasks: PropTypes.array,
	isFilterActive: PropTypes.bool,
	handleSort: PropTypes.func,
	loading: PropTypes.bool,
};
