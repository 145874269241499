import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch, useSelector } from 'react-redux';
import { updateContact, fetchContact, deleteContact } from '../../app/slices/contacts/contactsSlice';
import { errorHandler } from '../../services/api';
import {
	uploadMedia
} from '../../app/slices/user/userSlice';

import {
	Spinner, UserNavigation, ProfileHeader, Button, Card, Typography, ProfileModal, DeleteModal
} from '../../components';
import * as S from './ContactDirectoryProfile.styles';
import { META_TITLE } from '../../../constants/general.constants';
import { formatPhoneNumber } from '../../utils/phone-formatter';

import { usePaywall } from '../../hooks/usePaywall';
import { shouldShowPaywall } from '../../utils/should-show-paywall';

const ContactDirectoryProfile = () => {
	const { id } = useParams();
	const [pageStatus, setPageStatus] = useState('idle');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isContactModalOpen, setIsContactModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const { currentContact, status: contactStatus } = useSelector((state) => state.contacts);
	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();
	const history = useHistory();
	const { showPaywall, Paywall } = usePaywall();

	async function initializeData() {
		setPageStatus('loading');
		try {
			await dispatch(fetchContact({ contactId: id }));
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		document.title = `Contact Directory | ${META_TITLE}`;
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	useEffect(() => {
		if (shouldShowPaywall(user)) {
			showPaywall();
		}
	}, []);

	const handleCloseContactModal = () => {
		setIsContactModalOpen(false);
	};

	const handleCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const handleEditFormSubmit = (data) => {
		const { avatar } = data;
		setIsSubmitting(true);
		if (avatar) {
			dispatch(uploadMedia(avatar)).then((res) => {
				const payload = res?.payload;
				dispatch(updateContact({ ...data, profileImageObj: payload?.data, contactId: data.id })).then(() => {
					setIsSubmitting(false);
					setIsContactModalOpen(false);
				}).catch((err) => errorHandler(err));
			});
		} else {
			dispatch(updateContact({ ...data, profileImageObj: currentContact.profileImage, contactId: data.id })).then(() => {
				setIsSubmitting(false);
				setIsContactModalOpen(false);
			}).catch((err) => errorHandler(err));
		}
	};

	const handleDeleteSubmit = () => {
		setIsDeleting(true);
		dispatch(deleteContact({ contactId: currentContact.id })).then(() => {
			setIsDeleting(false);
			history.push('/dashboard/directory');
		}).catch((err) => errorHandler(err));
	};

	return (
		<UserNavigation>
			{contactStatus === 'loading' || !currentContact ? <Spinner /> : (
				<>
					<S.Wrapper>
						<ProfileHeader title={`${currentContact.firstName} ${currentContact.lastName}`} avatar={currentContact.profileImage['1500']} backRoute="/dashboard/directory" backText="Back to All Contacts" handleDelete={() => setIsDeleteModalOpen(true)} />
					</S.Wrapper>
					<S.CardWrapper>
						<Card>
							<S.InnerCardWrapper>
								<S.InfoWrapper>
									<S.DetailWrapper>
										<Typography tag="p" variation="2" className="detail-heading">
											Title
										</Typography>
										<Typography tag="p" variation="2">
											{currentContact.title}
										</Typography>
									</S.DetailWrapper>
									<S.DetailWrapper>
										<Typography tag="p" variation="2" className="detail-heading">
											Email
										</Typography>
										<Typography tag="p" variation="2">
											{currentContact.email}
										</Typography>
									</S.DetailWrapper>
									<S.DetailWrapper>
										<Typography tag="p" variation="2" className="detail-heading">
											Phone Number
										</Typography>
										<Typography tag="p">
											{formatPhoneNumber(currentContact.phone)}
										</Typography>
									</S.DetailWrapper>
								</S.InfoWrapper>
								<S.ActionWrapper>
									<Button variant="text" onClick={() => setIsContactModalOpen(true)}>
										<FontAwesomeIcon icon={['fal', 'edit']} />
										<Typography variation="button-medium" weight="bold">
											Edit
										</Typography>
									</Button>
								</S.ActionWrapper>
							</S.InnerCardWrapper>
						</Card>
					</S.CardWrapper>
				</>
			)}
			<ProfileModal user={currentContact} isOpen={isContactModalOpen} onRequestClose={handleCloseContactModal} submit={handleEditFormSubmit} isSubmitting={isSubmitting} type="contact" />
			<DeleteModal type="contact" isOpen={isDeleteModalOpen} onRequestClose={handleCloseDeleteModal} handleDelete={handleDeleteSubmit} isDeleting={isDeleting} />
			<Paywall />
		</UserNavigation>
	);
};

export default ContactDirectoryProfile;
