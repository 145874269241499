import React from 'react';
import PropTypes from 'prop-types';

import {
	ErrorPage,
} from '../../pages';

/*
Class based component to act as wrapper for application to catch
errors for the user and show them in a better way than crash the application

Used class based component to tak advantage of getDerivedStateFromError
*/
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
		};
	}


	static getDerivedStateFromError(error) {
		return { error };
	}

	render() {
		const { error } = this.state;

		if (error) {
			return <ErrorPage message={error.message} isError />;
		}
		// eslint-disable-next-line react/destructuring-assignment
		return this.props.children;
	}
}

export default ErrorBoundary;
ErrorBoundary.displayName = 'ErrorBoundary';
ErrorBoundary.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
