import styled from 'styled-components';
import colors from '../../styles/colors';

export const TableWrapper = styled.div`
	overflow: auto;

	table {
		width: 100%;
	}
	thead {
		text-align: left;
		background-color: ${colors.neutralBackground};
		border-radius: 0.25rem;
	}
	tbody tr {
		border-bottom: 1px solid ${colors.neutralForm};
	}
	th,
	td {
		padding: 0.5rem 0.75rem;
	}
	th {
		color: ${colors.neutralText};
	}
	td {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}
`;

export const ClassStatusCell = styled.span`
	&.success {
		color: ${colors.stateSuccess};
	}
	&.danger {
		color: ${colors.stateDanger};
	}
	svg {
		margin-right: 0.25rem;
	}
`;
