import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
`;

export const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;

	.icon {
		color: ${colors.neutralText}
	}
	.close-icon {
		margin-left: .75rem;
	}
`;

export const Form = styled.form`
`;

export const FormRow = styled.div`
	display: flex;
	margin-bottom: .75rem;
	width: 100%;

	.form-icon {
		height: 1.25rem;
		width: 1.25rem;
		margin-right: 1.375rem;
	}

	.select-field {
		flex: 1;
	}

	.text-input {
		width: 100%;

		input, textarea {
			border-radius: 0;
			background-color: ${colors.neutralForm}
		}

		textarea {
			min-height: 3rem;
		}
	}

	.task-content-input {
		margin-bottom: ${({ addMargin }) => (addMargin ? '1.5rem' : '0')};
		input {
			background-color: transparent;
			border: none;
			min-height: 1.5rem;
			padding: 0;
		}
	}

	.notes-icon {
		transform: scale(1.3);
	}

	&:last-child {
		width: 100%;
	}
`;

export const DetailWrapper = styled.div`
	display: flex;
	flex-direction: column;
	color: ${colors.neutralText};
	margin-bottom: 1.5rem;
	margin-left: 2.5rem;
`;

export const DetailRow = styled.div`
	display: flex;
	align-items: center;

	span {
		margin-right: .25rem;
	}
`;

export const ButtonRow = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: .75rem;

	.cancel-button {
		color: ${colors.neutralText};
		padding-right: .75rem;
	}
`;
