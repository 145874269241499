import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import { Spinner } from '../Spinner';
import * as S from './Tab.styles';

export const Tab = ({
	tabs = [], setTabs = () => null, transparent, loading, className, headClassName, contentClassName
}) => {
	const [currentSelectedTabIndex, setCurrentSelectedTabIndex] = useState(tabs.findIndex((option) => option.active) || 0);
	const headRef = useRef(null);

	const handleSelectedTab = (id) => {
		const selectedTab = tabs.find((option) => option.id === id);
		if (!selectedTab.active) {
			setTabs((prev) => {
				const newArray = [...prev];
				newArray.forEach((item, index) => {
					if (item.active) newArray[index].active = false;
					if (item.id === id) item.active = true;
				});
				return newArray;
			});
		}
	};

	function handleOnWheel({ deltaY }) {
		headRef.current.scrollLeft += deltaY;
	}

	useEffect(() => {
		if (!tabs.find((option) => option.active)) {
			setTabs((prev) => {
				const newArray = [...prev];
				newArray[0].active = true;
				return newArray;
			});
		} else {
			setCurrentSelectedTabIndex(tabs.findIndex((option) => option.active));
		}
	}, [tabs]);

	return (
		<S.Wrapper className={className}>
			<S.Head onWheel={handleOnWheel} ref={headRef} transparent={transparent} className={headClassName}>
				{tabs.map((tab) => (
					<S.TabButton key={tab.id} onClick={() => handleSelectedTab(tab.id)} active={tab.active}>
						<Typography tag="h6" weight="bold">
							{tab.label}
							{tab.badge && <S.Badge>{tab.badge}</S.Badge>}
							{tab.active && <S.ActiveIndicator />}
						</Typography>
					</S.TabButton>
				))}
			</S.Head>
			<S.Content className={contentClassName}>{loading ? <Spinner /> : tabs[currentSelectedTabIndex]?.component}</S.Content>
		</S.Wrapper>
	);
};

Tab.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
			badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			active: PropTypes.bool,
			component: PropTypes.object,
		})
	),
	setTabs: PropTypes.func,
	transparent: PropTypes.bool,
	loading: PropTypes.bool,
	className: PropTypes.string,
	headClassName: PropTypes.string,
	contentClassName: PropTypes.string,
};
