/* eslint-disable react/prop-types */

import React from 'react';
import ReactDOM from 'react-dom';

import { useHistory } from 'react-router-dom';

import {
	Button, Modal, IconButton, Typography
} from '..';
import * as S from './PaywallModal.styles';

export const PaywallModal = React.memo(({
	isOpen, onRequestClose, isFreeTrial, allowClose
}) => {
	const history = useHistory();
	const handleClosePaywallModal = () => {
		onRequestClose();
	};

	const handleUpgradePlan = () => {
		handleClosePaywallModal();

		setTimeout(() => {
			history.push('/dashboard/account?modal=upgrade');

			if (window.location.href.includes('modal=upgrade')) {
				window.location.reload();
			}
		}, 250);
	};

	// Setup this Paywall as a portal to be able to call it easily in other components with minimal boilerplate
	// Created it this way just in case the someone wants to setup certain pages to be Paywalled or even certain features
	return ReactDOM.createPortal(
		<Modal isOpen={isOpen} onRequestClose={allowClose ? handleClosePaywallModal : (e) => e.preventDefault()} maxWidth="55.875rem">
			<S.Wrapper>
				{allowClose && (
					<S.ActionsWrapper>
						<IconButton icon={['fal', 'times']} size={1.25} className="icon close-icon" onClick={handleClosePaywallModal} />
					</S.ActionsWrapper>
				)}
				<S.HeaderWrapper>
					<Typography tag="h2" weight="bold">Upgrade Your Account</Typography>
					<Typography tag="p">{isFreeTrial ? 'Your free trial has ended. Please upgrade to continue using Dispatchr' : 'You have reached the limit of your current plan.'}</Typography>
				</S.HeaderWrapper>
				<S.FooterActionsWrapper>
					<Button onClick={handleUpgradePlan}>
						Upgrade
					</Button>
				</S.FooterActionsWrapper>
			</S.Wrapper>
		</Modal>,
		document.body
	);
});

PaywallModal.displayName = 'PaywallModal';
PaywallModal.propTypes = {};
