/**
 * Imports
 */

// Modules
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Typography } from '../Typography';
import colors from '../../styles/colors';

/**
 * Styles
 */

export const PasswordInputContainer = styled.div`
	position: relative;
`;

export const PasswordValidator = styled.div`
	position: absolute;
	width: 100%;
	max-width: 370px;
	padding: 15px 15px 10px;
	margin: 10px 0 0;
	background-color: white;
	box-shadow: 0px 4px 16px rgba(0 0 0 / 12%);
	border-radius: 8px;
	border: 1px solid ${colors.neutralForm};
	opacity: 0;
	visibility: hidden;
	transform: scale(1.05);
	pointer-events: none !important;
	transition: visibility 0s linear 0.3s, opacity 0.3s 0s, transform 0.3s;
	z-index: 99;

	* {
		pointer-events: none !important;
	}

	p {
		text-align: left;
		color: black;
	}
	.introduction {
		color: black;
		margin-bottom: 10px;
	}
	&.show {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		pointer-events: all !important;
		transition: visibility 0s linear 0s, opacity 0.3s 0s, transform 0.3s;

		* {
			pointer-events: all !important;
		}
	}
`;

export const ValidatorRule = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	margin: 0 0 7px;
`;

export const ValidatorState = styled(FontAwesomeIcon)`
	color: ${({ validated }) => (validated ? colors.stateSuccess : colors.neutralText)};
	font-size: 1.25em;
	height: 1em !important;
	margin-right: 12px;
`;

export const ValidatorText = styled(Typography)`
	color: ${({ validated }) => (validated ? colors.stateSuccess : colors.neutralText)} !important;
`;
