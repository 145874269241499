import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	width: 100%;

	svg {
		color: ${colors.neutralText} !important;
		height: 1.25rem;
	}

	input {
		padding-left: 2.5rem;
		background-color: transparent;
	}
`;
