import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	margin-bottom: 1rem;
	margin-right: 1rem;

	.filter-button {
		color: ${colors.neutralText};
		border-radius: .25rem;
		border-color: ${colors.neutralForm};
		min-height: 2.8125rem;

		svg {
			height: 1rem;
		}

		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}

	@media screen and (max-width: 768px) {
			width: 100%;
			flex: 1;
	}
`;

export const Content = styled.div`
	hr {
		height: 1px;
		background-color: ${colors.neutralForm};
		border: none;
		margin-bottom: 1.5rem;
	}
`;

export const FilterSection = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
`;

export const FilterSectionHeader = styled.div`
	margin-bottom: 1rem;
`;

export const DateWrapper = styled.div`
	display: flex;
	align-items: center;

	.separator-icon {
		margin-top: 1.5rem;
	}

	.select-start-date-field {
		margin-right: .75rem;
	}

	.select-end-date-field {
		margin-left: .75rem;
	}

	.react-datepicker__input-container {
		input {
			border: 1px solid ${colors.neutralForm};
			border-radius: .25rem;
			background-color: transparent;
			min-height: 2.8125rem;
			appearance: none;
			padding: 0 0.75rem;
			color: black;
			line-height: 'normal';
			width: 100%;
			outline: none;
			font-size: 1rem;
			margin-top: .25rem;
		}
	}
`;

export const ErrorWrapper = styled.div`
	color: ${colors.stateDanger};
	font-size: .825rem;
	margin-top: .5rem;
`;

export const CheckboxWrapper = styled.div`
	display: flex;
	align-items: center;

	.checkbox {
		margin-right: 1rem;

		label {
			margin-left: .5rem;
		}
	}
`;

export const SelectWrapper = styled.div``;

export const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const ClearWrapper = styled.div`
	button {
		padding: 0;
	}
`;

export const ApplyWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const FilterCount = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1.25rem;
	width: 1.25rem;
	background-color: ${colors.brandSecondary};
	border-radius: 500vh;
	color: white;
`;
