import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: ${({ isBacklogOpen }) => (isBacklogOpen ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr')};
	grid-gap: 1rem;
	width: 100%;
	background-color: ${colors.lightBackground};
	padding: 1.5625rem;
	border-left: 1px solid ${colors.neutralForm};
	border-right: 1px solid ${colors.neutralForm};
	border-bottom: 1px solid ${colors.neutralForm};

	@media screen and (max-width: 1200px) {
		grid-template-columns:  ${({ isBacklogOpen }) => (isBacklogOpen ? '1fr 1fr' : '1fr 1fr 1fr')};
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 480px) {
		grid-template-columns: 1fr;
	}
`;

export const DispatchPlaceholder = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 7.25rem;
	background-color: ${colors.neutralForm};
	box-shadow: ${colors.lightShadow};
`;
