import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import moment from 'moment';

import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { selectAccordion } from '../../../app/slices/dashboard/dashboardSlice';
import {
	moveDispatch, asyncMoveDispatch
} from '../../../app/slices/dispatches/dispatchesSlice';

import { Typography, IconButton } from '../../../components';
import * as S from './DispatchAccordionSection.styles';

const DispatchAccordionSection = ({
	isOpen, label, onClick, date, dispatchCount, children: childrenProps
}) => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const activeDate = urlParams.get('activeDate');
	const reduxDispatch = useDispatch();
	const accordionSelected = useSelector((state) => state.dashboard.accordionSelected);
	const handleSelectAccordion = (selectedValue, noClick) => {
		const url = new URL(window.location);
		url.searchParams.set('activeDate', selectedValue);
		window.history.pushState(null, '', url.toString());

		if (!noClick) {
			onClick(date);
		}

		reduxDispatch(selectAccordion(selectedValue));
	};

	const currentDate = moment(new Date(), 'MM-DD-YYYY').format('MM-DD-YYYY');

	const handleChangeTargetDate = (dispatch) => {
		reduxDispatch(asyncMoveDispatch({ dispatchId: dispatch.id, targetDate: date }));
		reduxDispatch(moveDispatch({ dispatchId: dispatch.id, targetDate: date })).then(() => {
			handleSelectAccordion(date);
		}).catch(() => {
			asyncMoveDispatch({ dispatchId: dispatch.id, targetDate: dispatch?.targetDate?.iso ? moment(dispatch.targetDate.iso, 'YYYY-MM-DD').format('MM-DD-YYYY') : dispatch.targetDate });
		});
	};

	const [{ isOver, canDrop }, drop] = useDrop(
		() => ({
			accept: 'dispatch',
			drop: (dispatch) => handleChangeTargetDate(dispatch),
			canDrop(dispatch) {
				return date !== moment(dispatch.targetDate.iso, 'YYYY-MM-DD').format('MM-DD-YYYY');
			},
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
				canDrop: !!monitor.canDrop()
			})
		}),
		[]
	);

	return (
		<S.Wrapper
			id={`accordion-${moment(date, 'MM-DD-YYYY').format('x')}`}
			isOpen={isOpen}
			ref={!isOpen ? drop : null}
			isOver={isOver && canDrop}
			className={`${isOpen ? 'accordion-open' : ''}`}
		>
			<S.IconLabelWrapper isAccordionSelected={date === accordionSelected}>
				<S.IconWrapper>
					{!isOpen && <IconButton icon={['fas', 'caret-up']} id={`${!isOpen && date === activeDate ? 'accordion-open-icon' : ''}`} onClick={() => onClick(date)} />}
					{isOpen && <IconButton icon={['fas', 'caret-down']} onClick={() => onClick(date)} />}
				</S.IconWrapper>
				<S.LabelWrapper role="button" onClick={() => handleSelectAccordion(date)} onKeyDown={(e) => e.preventDefault()} tabIndex={0}>
					<Typography tag="h5" weight="semibold">
						{label}
					</Typography>
					{dispatchCount > 0 && <Typography tag="p" variation="3" weight="semibold" className="dispatch-count">{dispatchCount}</Typography>}
					{date === currentDate && <FontAwesomeIcon className="indicator-icon" icon={['fas', 'circle']} />}
				</S.LabelWrapper>
			</S.IconLabelWrapper>
			<S.ContentWrapper isOpen={isOpen}>
				{isOpen && (
					<S.Content onClick={() => handleSelectAccordion(date, true)}>
						{childrenProps}
					</S.Content>
				)}
			</S.ContentWrapper>
		</S.Wrapper>
	);
};

export default DispatchAccordionSection;

DispatchAccordionSection.displayName = 'DispatchAccordionSection';
DispatchAccordionSection.propTypes = {
	children: PropTypes.instanceOf(Object).isRequired,
	isOpen: PropTypes.bool.isRequired,
	label: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	dispatchCount: PropTypes.number,
};
