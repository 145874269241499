import { createContext } from 'react';
import { io } from 'socket.io-client';
import { getCookie } from '../utils/cookie-methods';

export const socket = (organizationId) => io(process.env.REACT_APP_SERVER_URL, {
	transports: ['websocket'],
	query: {
		organizationId,
		sessionToken: getCookie('e_s_token'),
	},
});

export const SocketContext = createContext();
