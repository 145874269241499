import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	min-height: 3.75rem;
	background-color: white;
	box-shadow: ${colors.lightShadow};
	padding: 1rem;
	margin-top: ${({ isBacklog }) => (isBacklog ? '.5rem' : '0')};

	.animated {
		background: linear-gradient(124deg, ${colors.neutralForm}, ${colors.neutralBackground});
		background-size: 400% 400%;

		-webkit-animation: AnimationName 2s ease infinite;
		-moz-animation: AnimationName 2s ease infinite;
		animation: AnimationName 2s ease infinite;

		@-webkit-keyframes AnimationName {
			0%{background-position:7% 0%}
			50%{background-position:94% 100%}
			100%{background-position:7% 0%}
		}
		@-moz-keyframes AnimationName {
			0%{background-position:7% 0%}
			50%{background-position:94% 100%}
			100%{background-position:7% 0%}
		}
		@keyframes AnimationName {
			0%{background-position:7% 0%}
			50%{background-position:94% 100%}
			100%{background-position:7% 0%}
		}
	}
`;

export const EmptyContent = styled.div`
	height: 1rem;
	width: 100%;
	border-radius: .25rem;
`;

export const EmptyIconWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: 1rem;
`;

export const EmptyIcon = styled.div`
	height: 1rem;
	width: 1rem;
	margin-right: .25rem;
	border-radius: .25rem;
`;
