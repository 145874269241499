import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Avatar = styled.div`
	position: relative;
	width: 15rem;
	height: 15rem;
	border: 1px solid ${colors.neutralForm};
	border-radius: 9999px;

	overflow: hidden;

	${({ isDragActive }) => isDragActive
		&& css`
			border-style: dashed;
			opacity: 0.8;
		`}

	${({ isDragAccept }) => isDragAccept
		&& css`
			border-color: ${colors.stateSuccess};
		`}

	${({ isDragReject }) => isDragReject
		&& css`
			border-color: ${colors.stateDanger};
		`}

	${({ uploadDisabled }) => !uploadDisabled
		&& css`
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		`}
`;

export const EmptyPlaceholder = styled.div`
	position: absolute;
	inset: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: ${colors.neutralButton};

	svg {
		margin: 0 0 0.5rem;
	}
`;

export const Picture = styled.picture`
	pointer-events: none;
	position: absolute;
	inset: 0;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
`;
