import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
	fetchTeam as getTeam, fetchTeamMember as getTeamMember, createUpdateTeamMember, removeTeamMember as deleteTeamMember
} from '../../../services/user/team/teamService';
import { errorHandler } from '../../../services/api';
import { getCookie } from '../../../utils/cookie-methods';

export const fetchTeam = createAsyncThunk('team/fetchTeam', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await getTeam(data);
		if (response?.data?.result?.members) {
			return response.data.result.members;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const fetchTeamMember = createAsyncThunk('team/fetchTeamMember', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await getTeamMember(data);
		if (response?.data?.result?.member) {
			return response.data.result.member;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const addTeamMember = createAsyncThunk('team/addTeamMember', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await createUpdateTeamMember(data);
		if (response?.data?.result?.member) {
			return response.data.result.member;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const updateTeamMember = createAsyncThunk('team/updateTeamMember', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await createUpdateTeamMember(data);
		if (response?.data?.result?.member) {
			return response.data.result.member;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const removeTeamMember = createAsyncThunk('team/removeTeamMember', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await deleteTeamMember(data);
		if (response?.data?.result?.member) {
			return response.data.result.member;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const teamSlice = createSlice({
	name: 'team',
	initialState: {
		status: 'idle',
		error: null,
		value: [],
		currentMember: null,
		memberStatus: 'idle'
	},
	reducers: {
		clearTeam: (state) => {
			state.status = 'idle';
			state.error = null;
			state.value = [];
			state.currentMember = null;
			state.memberStatus = 'idle';
		}
	},
	extraReducers: {
		[fetchTeam.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchTeam.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.currentMember = null;
			state.value = action.payload;
		},
		[fetchTeam.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
		[fetchTeamMember.pending]: (state) => {
			state.currentMember = null;
			state.status = 'loading';
		},
		[fetchTeamMember.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.currentMember = action.payload;
		},
		[fetchTeamMember.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
		[addTeamMember.pending]: (state) => {
			state.memberStatus = 'loading';
		},
		[addTeamMember.fulfilled]: (state, action) => {
			state.memberStatus = 'succeeded';
			if (action.payload) {
				state.value = [...state.value, action.payload];
			}
		},
		[addTeamMember.rejected]: (state, action) => {
			state.memberStatus = 'failed';
			state.error = action.error.message;
		},
		[updateTeamMember.pending]: (state) => {
			state.memberStatus = 'loading';
		},
		[updateTeamMember.fulfilled]: (state, action) => {
			state.memberStatus = 'succeeded';
			const teamArr = state.value;
			const index = teamArr.findIndex((member) => member.id === action.payload.id);
			teamArr[index] = action.payload;
			state.value = teamArr;
			state.currentMember = action.payload;
		},
		[updateTeamMember.rejected]: (state, action) => {
			state.memberStatus = 'failed';
			state.error = action.error.message;
		},
		[removeTeamMember.pending]: (state) => {
			state.memberStatus = 'loading';
		},
		[removeTeamMember.fulfilled]: (state, action) => {
			state.memberStatus = 'succeeded';
			const teamArr = state.value;
			teamArr.splice(teamArr.findIndex((i) => i.id === action.payload.id), 1);
			state.value = teamArr;

		},
		[removeTeamMember.rejected]: (state, action) => {
			state.memberStatus = 'failed';
			state.error = action.error.message;
		},
	},
});

export const {
	clearTeam
} = teamSlice.actions;

export default teamSlice.reducer;
