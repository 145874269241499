import React from 'react';
import PropTypes from 'prop-types';
import * as S from './PlaceholderCard.styles';

const PlaceholderCard = ({ isBacklog }) => (
	<S.Wrapper isBacklog={isBacklog} className="fade-in">
		<S.EmptyContent className="animated" />
		<S.EmptyIconWrapper>
			<S.EmptyIcon className="animated" />
			<S.EmptyIcon className="animated" />
		</S.EmptyIconWrapper>
	</S.Wrapper>
);

export default PlaceholderCard;
PlaceholderCard.displayName = 'PlaceholderCard';
PlaceholderCard.propTypes = {
	isBacklog: PropTypes.bool
};
