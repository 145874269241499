import styled from 'styled-components';

import colors from '../../styles/colors';

export const ContentWrapper = styled.div`
	padding: 1.5rem;
	background-color: #fff;
	box-shadow: ${colors.lightShadow};
	border: 1px solid ${colors.neutralForm};
	border-radius: .185rem;
`;

export const ChildrenWrapper = styled.div``;

export const ActionContent = styled.div`
	button {
		padding: 0;
		padding-top: ${({ index }) => (index === 0 ? '0' : '1.5rem')};
	}
`;
