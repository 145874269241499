import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
	updateUser
} from '../../app/slices/user/userSlice';
import {
	removePaymentMethod
} from '../../services/user/userService';
import { errorHandler } from '../../services/api';


import { PaymentMethod } from './PaymentMethod';
import { PaymentForm } from './PaymentForm';
import { Button, Typography, DeleteModal } from '..';
import * as S from './PaymentSelect.styles';

export const PaymentSelect = ({
	address, selectedMethod, handleSelectedMethod, canCreatePaymentMethod, canRemovePaymentMethod
}) => {
	const user = useSelector((state) => state.user.value);
	const [showPaymentForm, setShowPaymentForm] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(selectedMethod);
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
	const dispatch = useDispatch();

	useEffect(() => {
		setSelectedPaymentMethod(selectedMethod);
	}, [selectedMethod]);

	const handleCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const handleDeleteSubmit = async () => {
		setIsDeleting(true);

		await removePaymentMethod({ methodId: selectedMethod }).then((res) => {
			dispatch(updateUser(res.data.result.user));
			setIsDeleteModalOpen(false);
			setIsDeleting(false);
		}).catch((err) => {
			setIsDeleting(false);
			errorHandler(err);
		});
	};

	return (
		<S.Wrapper>
			<Typography tag="h5" weight="bold">Payment</Typography>
			{canCreatePaymentMethod ? (
				<>
					<S.PaymentMethodWrapper onChange={handleSelectedMethod}>
						{user.paymentMethods.map((method) => <PaymentMethod key={method.id} method={method} checked={selectedPaymentMethod === method.id} canRemovePaymentMethod={canRemovePaymentMethod} handleOpenDeleteModal={() => setIsDeleteModalOpen(true)} handleSelectedMethod={handleSelectedMethod} user={user} />)}
					</S.PaymentMethodWrapper>
					{showPaymentForm ? <Elements stripe={stripePromise}><PaymentForm user={user} handleShowPaymentForm={() => setShowPaymentForm(false)} handleSelectedMethod={handleSelectedMethod} address={address} /></Elements> : (
						<Button variant="text" className="add-payment-button" onClick={() => setShowPaymentForm(true)}>
							<FontAwesomeIcon icon={['fal', 'plus']} />
							<Typography tag="p" weight="semibold">Add New Payment Method</Typography>
						</Button>
					)}
				</>
			) : <Typography tag="p">Please enter a valid billing address first</Typography>}
			<DeleteModal type="payment method" isOpen={isDeleteModalOpen} onRequestClose={handleCloseDeleteModal} handleDelete={handleDeleteSubmit} isDeleting={isDeleting} content="Are you sure you want to delete the selected payment method" />
		</S.Wrapper>
	);
};

PaymentSelect.displayName = 'PaymentSelect';
PaymentSelect.propTypes = {
	address: PropTypes.object,
	selectedMethod: PropTypes.string,
	handleSelectedMethod: PropTypes.func,
	canCreatePaymentMethod: PropTypes.bool,
	canRemovePaymentMethod: PropTypes.bool
};
