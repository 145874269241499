import { useDispatch, useSelector } from 'react-redux';
import { SOCKET_ACTIONS } from '../../constants/general.constants';

import {
	addTaskSocket, updateTaskSocket, deleteTaskSocket, orderTasks, deleteTaskTag, duplicateTaskSocket, addTaskToBacklog, removeTaskFromBacklog, orderTasksByTask, updateTaskOrder
} from '../app/slices/tasks/tasksSlice';
import {
	addDispatchSocket,
	deleteDispatchTaskTag,
	updateDispatchSocket,
	updateDispatchTask,
	deleteDispatchSocket,
	moveDispatchSocket,
	duplicateDispatchSocket,
	sendDispatchSocket,
	addDispatchTask,
	deleteDispatchTask,
	orderDispatchTasks,

	// deleteTaskFromPreviousDispatch
} from '../app/slices/dispatches/dispatchesSlice';
import { addContactSocket, updateContactSocket, deleteContactSocket } from '../app/slices/contacts/contactsSlice';
import { addTagSocket, deleteTagSocket } from '../app/slices/tags/tagsSlice';

// Hook to handle socket actions that are emitted from server
export const useSocket = (socket) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.value);

	const socketListener = () => {
		if (socket) {
			socket.onAny((eventName, res) => {
				const { action, payload, responsibleUser } = res;
				if (responsibleUser !== user.id) {
					switch (action) {

						// Task Cases
						case SOCKET_ACTIONS.CREATE_TASK:
							dispatch(addTaskSocket(payload.task));
							break;
						case SOCKET_ACTIONS.UPDATE_TASK:
							if (payload.task.placement === 'backlog') {
								dispatch(updateTaskSocket(payload.task));
							} else {
								dispatch(updateDispatchTask(payload.task));
							}
							break;
						case SOCKET_ACTIONS.DELETE_TASK:
							dispatch(deleteTaskSocket(payload.task));
							dispatch(updateTaskOrder());
							break;
						case SOCKET_ACTIONS.MOVE_TASK:
							if (payload.task.placement === 'backlog') {
								if (!payload?.isFromBacklog) {
									dispatch(addTaskToBacklog(payload.task));
								}
								dispatch(orderTasks(payload.task));
							} else {
								if (payload.previousDispatch && payload.previousDispatch !== payload.task.dispatchId) {
									dispatch(addDispatchTask({ ...payload.task, position: payload.task.order }));
								}
								if (payload?.isFromBacklog) {
									dispatch(removeTaskFromBacklog(payload.task));
									dispatch(addDispatchTask({ ...payload.task, position: payload.task.order }));
								}
								dispatch(orderDispatchTasks(payload.task));
							}
							break;
						case SOCKET_ACTIONS.DUPLICATE_TASK:
							dispatch(duplicateTaskSocket(payload.task));
							dispatch(orderTasksByTask(payload.task));
							break;
						case SOCKET_ACTIONS.UPDATE_TASK_STATUS:
							if (payload.task.placement === 'backlog') {
								dispatch(updateTaskSocket({ ...payload.task, isSent: true }));
							} else {
								dispatch(updateDispatchTask({ ...payload.task, isSent: true }));
							}
							break;

						// Dispatch Cases
						case SOCKET_ACTIONS.CREATE_DISPATCH:
							dispatch(addDispatchSocket(payload.dispatch));
							break;
						case SOCKET_ACTIONS.UPDATE_DISPATCH:
							dispatch(updateDispatchSocket(payload.dispatch));
							break;
						case SOCKET_ACTIONS.DELETE_DISPATCH:
							dispatch(deleteDispatchSocket(payload.dispatch));
							break;
						case SOCKET_ACTIONS.MOVE_DISPATCH:
							dispatch(moveDispatchSocket(payload.dispatch));
							break;
						case SOCKET_ACTIONS.SEND_DISPATCH:
							dispatch(sendDispatchSocket(payload.dispatch));
							break;
						case SOCKET_ACTIONS.ADD_TASK_TO_DISPATCH:
							dispatch(addDispatchTask({ ...payload.task, position: payload.task.order }));
							break;
						case SOCKET_ACTIONS.REMOVE_TASK_FROM_DISPATCH:
							dispatch(deleteDispatchTask(payload.task));
							break;
						case SOCKET_ACTIONS.DUPLICATE_DISPATCH:
							dispatch(duplicateDispatchSocket(payload.dispatch));
							break;

						// Contact Cases
						case SOCKET_ACTIONS.CREATE_CONTACT:
							dispatch(addContactSocket(payload.contact));
							break;
						case SOCKET_ACTIONS.UPDATE_CONTACT:
							dispatch(updateContactSocket(payload.contact));
							break;
						case SOCKET_ACTIONS.DELETE_CONTACT:
							dispatch(deleteContactSocket(payload.contact));
							break;

						// Tag Cases
						case SOCKET_ACTIONS.CREATE_TAG:
							dispatch(addTagSocket(payload.tag));
							break;
						case SOCKET_ACTIONS.DELETE_TAG:
							dispatch(deleteTagSocket(payload.tag));
							dispatch(deleteTaskTag(payload.tag));
							dispatch(deleteDispatchTaskTag(payload.tag));
							break;
						default:
							break;
					}
				}

			});
		}
	};

	return { socketListener };
};
