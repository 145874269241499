import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
	fetchContacts as getContacts, fetchContact as getContact, createUpdateContact, deleteContact as destroyContact
} from '../../../services/contacts/contactService';
import { errorHandler } from '../../../services/api';
import { getCookie } from '../../../utils/cookie-methods';

export const fetchContacts = createAsyncThunk('contacts/fetchContacts', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await getContacts(data);
		if (response?.data?.result?.contacts) {
			return { contacts: response.data.result.contacts, pagination: { total: response?.data?.result?.pagination?.total, page: response?.data?.result?.pagination?.page } };
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const fetchContact = createAsyncThunk('contacts/fetchContact', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await getContact(data);
		if (response?.data?.result?.contact) {
			return response.data.result.contact;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const addContact = createAsyncThunk('contacts/addContact', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await createUpdateContact(data);
		if (response?.data?.result?.contact) {
			return response.data.result.contact;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const updateContact = createAsyncThunk('contacts/updateContact', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await createUpdateContact(data);
		if (response?.data?.result?.contact) {
			return response.data.result.contact;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const deleteContact = createAsyncThunk('contacts/deleteContact', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await destroyContact(data);
		if (response?.data?.result?.contact) {
			return response.data.result.contact;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const contactsSlice = createSlice({
	name: 'contacts',
	initialState: {
		status: 'idle',
		error: null,
		value: [],
		currentContact: null,
		tagStatus: 'idle',
		totalCount: null
	},
	reducers: {
		addContactSocket: (state, action) => {
			state.value = [...state.value, action.payload];
		},
		updateContactSocket: (state, action) => {
			const contactsArr = state.value;
			const index = contactsArr.findIndex((contact) => contact.id === action.payload.id);
			contactsArr[index] = action.payload;
			state.value = contactsArr;
			state.currentContact = action.payload;
		},
		deleteContactSocket: (state, action) => {
			const contactsArr = state.value;
			contactsArr.splice(contactsArr.findIndex((i) => i.id === action.payload.id), 1);
			state.value = contactsArr;
		},
		clearContacts: (state) => {
			state.status = 'idle';
			state.error = null;
			state.value = [];
			state.currentContact = null;
			state.tagStatus = 'idle';
			state.totalCount = null;
		}
	},
	extraReducers: {
		[fetchContacts.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchContacts.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.currentContact = null;

			if (action.payload?.pagination?.page === 0 || action.payload?.pagination?.page) {
				state.totalCount = action.payload.pagination?.total;
			}

			state.value = action.payload.contacts;
		},
		[fetchContacts.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
		[fetchContact.pending]: (state) => {
			state.currentContact = null;
			state.status = 'loading';
		},
		[fetchContact.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.currentContact = action.payload;
		},
		[fetchContact.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
		[addContact.pending]: (state) => {
			state.contactStatus = 'loading';
		},
		[addContact.fulfilled]: (state, action) => {
			state.contactStatus = 'succeeded';
			state.value = [...state.value, action.payload];
		},
		[addContact.rejected]: (state, action) => {
			state.contactStatus = 'failed';
			state.error = action.error.message;
		},
		[updateContact.pending]: (state) => {
			state.contactStatus = 'loading';
		},
		[updateContact.fulfilled]: (state, action) => {
			state.contactStatus = 'succeeded';
			const contactsArr = state.value;
			const index = contactsArr.findIndex((contact) => contact.id === action.payload.id);
			contactsArr[index] = action.payload;
			state.value = contactsArr;
			state.currentContact = action.payload;
		},
		[updateContact.rejected]: (state, action) => {
			state.contactStatus = 'failed';
			state.error = action.error.message;
		},
		[deleteContact.pending]: (state) => {
			state.contactStatus = 'loading';
		},
		[deleteContact.fulfilled]: (state, action) => {
			state.contactStatus = 'succeeded';
			const contactsArr = state.value;
			contactsArr.splice(contactsArr.findIndex((i) => i.id === action.payload.id), 1);
			state.value = contactsArr;

		},
		[deleteContact.rejected]: (state, action) => {
			state.contactStatus = 'failed';
			state.error = action.error.message;
		},
	},
});

export const {
	addContactSocket, updateContactSocket, deleteContactSocket, clearContacts
} = contactsSlice.actions;

export default contactsSlice.reducer;
