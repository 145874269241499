import styled from 'styled-components';

export const Wrapper = styled.div`
	overflow: hidden;
	position: absolute;
	top: 7.5rem;
	bottom: 0;
	max-width: 100%;
	width: calc(100% - 3.25rem);
	padding: 2rem;

	.table-card {
		padding: 0;
	}

	.table-scrollable-container {
		padding: 0;
		height: ${({ hasPagination }) => (hasPagination ? '85%' : '100%')};
		padding-bottom: ${({ hasPagination }) => (hasPagination ? '0' : '3rem')};
	}

	@media screen and (max-width: 992px) {
		overflow: auto;
		padding: 1rem;
		position: relative;
		top: 0rem;
		width: 100%;
	}
`;

export const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const SearchWrapper = styled.div`
	width: 50%;
	margin-bottom: 1rem;

	@media screen and (max-width: 992px) {
		min-width: 70%;
		width: 70%;
	}

	@media screen and (max-width: 768px) {
		min-width: 100%;
		width: 100%;
	}
`;
