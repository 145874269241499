export default {

	// Brand
	brandPrimary: '#1E253C',
	brandSecondary: '#489FF4',
	brandTertiary: '#006BFF',

	// Lighter Brand
	lightBrandPrimary: '#4A5C94',
	lightBrandSecondary: '#B2D7FA',
	lightBrandTertiary: '#CCE1FF',

	// Brand Hover States
	brandPrimaryHover: '#151B2B',
	brandSecondaryHover: '#3092F3',

	// Overlay
	darkOverlay: 'rgba(0, 0, 0, 0.5)',

	// Shadow
	lightShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.1)',
	mediumShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
	boldShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.1)',
	extraBoldShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',

	// Neutral
	neutralBackground: '#F7F9FC',
	neutralMessage: '#F0F1F6',
	neutralForm: '#EBECF0',
	neutralText: '#7F8496',
	neutralButton: '#536177',
	neutralDisabled: '#747888',

	// Light Neutral
	lightNeutral: '#EDF1F6',

	// Neutral Hover state
	neutralTextHover: '#5D606C',

	// State
	stateSuccess: '#25953E',
	stateWarning: '#FFE81A',
	stateDanger: '#E13737',
	stateInfo: '#1A4ED4',
	stateComplete: '#C8F0D1',
	statePending: '#CCE1FF',

	// Lighter State
	lightStateSuccess: '#EAFFEE',
	lightStateWarning: '#FFFDE7',
	lightStateDanger: '#FFEBEB',
	lightStateInfo: '#E7EDFF',

	// Background
	lightBackground: '#FAFAFA',
};
