import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div``;

export const PillWrapper = styled.div`
	border-radius: 500vh;
	border: 2px solid ${colors.brandSecondary};
	margin: .1rem;
`;

export const Content = styled.div`
	padding: .15rem .525rem;
	color: ${colors.brandSecondary};
`;
