import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background-color: white;
	padding: 0.75rem 1rem;
`;

export const InfoWrapper = styled.div`


	.header {
		&__back-button {
			margin-left: -0.625rem;
			letter-spacing: 0.75px;

			svg {
				font-size: 0.75rem;
			}

			span {
				margin-left: 0.5rem;
			}
		}

		&__title-wrapper {
			display: flex;
			align-items: center;
			margin-top: 0.25rem;

			&__avatar {
				border: 1px solid ${colors.neutralForm};
				width: 3rem;
				height: 3rem;
				border-radius: 9999px;
				margin-right: 1rem;
			}

			.invitation-pill {
				color: ${colors.brandSecondary};
				border: 1px solid ${colors.brandSecondary};
				border-radius: 500vh;
				margin-top: .25rem;
				margin-left: .5rem;
				padding: .125rem .5rem;
				font-size: .75rem;
			}
		}
	}
`;

export const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;

	.invite-action {
		margin-right: 1rem;
	}
`;

export const ActionWrapper = styled.div`
	button {
		align-self: flex-end;
		margin-bottom: -1.5rem;
	}
`;
