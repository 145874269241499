import React, { forwardRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from './Loader.styles';

export const Loader = forwardRef((props, ref) => (
	<S.Wrapper {...props} ref={ref}>
		<FontAwesomeIcon icon={['fal', 'spinner']} className="fa-spin" />
	</S.Wrapper>
));

Loader.displayName = 'Loader';
