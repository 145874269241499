import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { required, pattern } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';

import {
	Modal, IconButton, Typography, Button, TextInput, Select, PhoneTextInput, Avatar
} from '..';
import * as S from './ProfileModal.styles';

export const ProfileModal = ({
	user, isOpen = false, onRequestClose, type = 'user', submit, isSubmitting
}) => {
	const [avatar, setAvatar] = useState('');
	const [file, setFile] = useState(null);
	const [telephone, setTelephone] = useState('');
	const [selectedPermission, setSelectedPermission] = useState({ value: 'admin', label: 'Admin' });
	const permissionOptions = useMemo(() => [{ value: 'admin', label: 'Admin' }, { value: 'foreman', label: 'Foreman' }], []);
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm();

	useEffect(() => {
		if (user) {
			setAvatar(user?.profileImage?.['1500'] || '');
			setTelephone(user ? user.phone : '');
			reset(user);
		}
	}, [user]);

	const resetForm = () => {
		setAvatar('');
		setFile(null);
		setTelephone('');
		reset();
	};

	function onSubmit(e) {
		e.preventDefault();
		handleSubmit((values) => submit({
			...values, avatar: file, phone: telephone.includes('+') ? telephone : `+${telephone}`, id: user ? user.id : null, role: (type === 'user' || type === 'team') ? selectedPermission?.value : null
		}, resetForm))();
	}

	const handleSetAvatarFile = (avatarFile) => {
		setFile(avatarFile);
	};

	const handleSetAvatar = (upload) => {
		setAvatar(upload);
	};

	const handlePhoneChange = (phone) => {
		setTelephone(phone);
	};

	const handleSelectPermission = (value) => {
		setSelectedPermission(value);
	};

	return (
		<Modal isOpen={isOpen} onRequestClose={onRequestClose} maxWidth="55.875rem">
			<S.Wrapper>
				<S.ActionsWrapper>
					<IconButton icon={['fal', 'times']} size={1.25} className="icon close-icon" onClick={onRequestClose} />
				</S.ActionsWrapper>
				<S.TitleWrapper>
					<Typography tag="h2" weight="bold">
						{`${user ? 'Edit' : 'Add'} ${type}`}
					</Typography>
					<Typography tag="p" variation="1">
						{`${user ? 'Edit' : 'Enter'} ${type} info below.`}
					</Typography>
				</S.TitleWrapper>
				<S.Form onSubmit={onSubmit}>
					<S.AvatarWrapper>
						<Avatar setFile={handleSetAvatarFile} setAvatar={handleSetAvatar} avatar={avatar} className="avatar" />
					</S.AvatarWrapper>
					<S.FormRow>
						<TextInput
							label="First Name"
							id="first-name"
							error={errors.firstName}
							autoComplete="first-name"
							defaultValue={user && user.firstName}
							{...register('firstName', {
								required: required('First Name'),
							})}
						/>
					</S.FormRow>
					<S.FormRow>
						<TextInput
							label="Last Name"
							id="last-name"
							error={errors.lastName}
							autoComplete="last-name"
							defaultValue={user && user.lastName}
							{...register('lastName', {
								required: type !== 'contact' && required('Last Name'),
							})}
						/>
					</S.FormRow>
					{type === 'contact' && (
						<S.FormRow>
							<TextInput
								label="Title"
								id="title"
								error={errors.title}
								autoComplete="title"
								defaultValue={user && user.title}
								{...register('title', {
									required: type !== 'contact' && required('Title'),
								})}
							/>
						</S.FormRow>
					)}
					{(type === 'user' || type === 'team') && (
						<S.FormRow>
							<Select
								id="permissions"
								label="Permissions"
								error={errors.permissions}
								options={permissionOptions}
								onChange={handleSelectPermission}
								defaultValue="admin"
								value={selectedPermission}
								containerClassName="permissions-select"
							/>
						</S.FormRow>
					)}
					<S.FormRow>
						<TextInput
							label="Email"
							id="email"
							type="email"
							error={errors.email}
							autoComplete="email"
							defaultValue={user && user.email}
							{...register('email', {
								required: type !== 'contact' && required('Email'),
								pattern: type !== 'contact' && pattern('Email', EMAIL),
							})}
						/>
					</S.FormRow>
					<S.FormRow>
						<PhoneTextInput
							label="Phone Number"
							id="phone"
							value={telephone}
							onChange={handlePhoneChange}
							error={errors.phone}
						/>
					</S.FormRow>
					<S.ButtonRow>
						<Button variant="outline" className="cancel-button" onClick={(e) => onRequestClose(e)}>
							<Typography variation="button-medium" weight="bold">
								Cancel
							</Typography>
						</Button>
						<Button type="submit" variation="default" className="submit-button">
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Saving...' : 'Save'}
							</Typography>
						</Button>
					</S.ButtonRow>
				</S.Form>
			</S.Wrapper>
		</Modal>
	);
};

ProfileModal.displayName = 'ProfileModal';
ProfileModal.propTypes = {
	user: PropTypes.object,
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	type: PropTypes.string,
	submit: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
};
