import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch, useSelector } from 'react-redux';
import { updateTeamMember, fetchTeamMember, removeTeamMember } from '../../app/slices/team/teamSlice';
import {
	uploadMedia
} from '../../app/slices/user/userSlice';
import { beginPasswordReset } from '../../services/authService';
import { resendInvite } from '../../services/user/invites/inviteService';
import { errorHandler } from '../../services/api';

import {
	Spinner, UserNavigation, ProfileHeader, Button, Card, Typography, ProfileModal, DeleteModal, PasswordModal
} from '../../components';
import * as S from './TeamMemberProfile.styles';
import { META_TITLE } from '../../../constants/general.constants';
import { formatPhoneNumber } from '../../utils/phone-formatter';

const TeamMemberProfile = () => {
	const { id } = useParams();
	const [pageStatus, setPageStatus] = useState('idle');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isSubmittingPasswordReset, setIsSubmittingPasswordReset] = useState(false);
	const [isEmailSent, setIsEmailSent] = useState(false);
	const [isInviteSent, setIsInviteSent] = useState(false);
	const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
	const { currentMember, status: memberStatus } = useSelector((state) => state.team);
	const { value: user } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const history = useHistory();

	async function initializeData() {
		setPageStatus('loading');
		try {
			await dispatch(fetchTeamMember({ memberId: id }));
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		document.title = `Team Directory | ${META_TITLE}`;
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const handleCloseMemberModal = () => {
		setIsMemberModalOpen(false);
	};

	const handleCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const handleEditFormSubmit = (data) => {
		const { avatar } = data;
		setIsSubmitting(true);
		if (avatar) {
			dispatch(uploadMedia(avatar)).then((res) => {
				const payload = res?.payload;
				dispatch(updateTeamMember({ ...data, profileImageObj: payload?.data, memberId: data.id })).then(() => {
					setIsSubmitting(false);
					setIsMemberModalOpen(false);
				}).catch((err) => errorHandler(err));
			});
		} else {
			dispatch(updateTeamMember({ ...data, profileImageObj: currentMember.profileImage, memberId: data.id })).then(() => {
				setIsSubmitting(false);
				setIsMemberModalOpen(false);
			}).catch((err) => errorHandler(err));
		}
	};

	const handleDeleteSubmit = () => {
		setIsDeleting(true);
		dispatch(removeTeamMember({ memberId: currentMember.id })).then(() => {
			setIsDeleting(false);
			history.push('/dashboard/account?tab=team');
		}).catch((err) => errorHandler(err));
	};

	const handleResetPassword = () => {
		setIsSubmittingPasswordReset(true);
		beginPasswordReset({ email: currentMember.email }).then(() => {
			setIsEmailSent(true);
			setIsSubmittingPasswordReset(false);
		}).catch((err) => errorHandler(err));
	};

	const handleInviteSubmit = () => {
		setIsSubmitting(true);
		resendInvite({ memberId: currentMember.id }).then(() => {
			setIsInviteSent(true);
			setIsSubmitting(false);
		}).catch((err) => errorHandler(err));
	};

	const isOwner = user.role === 'owner';

	return (
		<UserNavigation>
			{memberStatus === 'loading' || !currentMember ? <Spinner /> : (
				<>
					<S.Wrapper>
						<ProfileHeader
							title={`${currentMember.firstName} ${currentMember.lastName}`}
							avatar={currentMember.profileImage['1500']}
							backRoute="/dashboard/account?tab=team"
							backText="Back to Team"
							handleDelete={isOwner ? () => setIsDeleteModalOpen(true) : null}
							isInvitationPending={!currentMember?.accountSetupComplete}
							handleInvite={isOwner ? () => handleInviteSubmit() : null}
							isInviteSent={isInviteSent}
							isSubmittingInvite={isSubmitting}
						/>
					</S.Wrapper>
					<S.CardWrapper>
						<Card className="overview-card">
							<S.Header>
								<Typography tag="h6" weight="bold">Overview</Typography>
							</S.Header>
							<S.InnerCardWrapper>
								<S.InfoWrapper>
									<S.DetailWrapper>
										<Typography tag="p" variation="2" className="detail-heading">
											Permission
										</Typography>
										<Typography tag="p" variation="2" className="permission-text">
											{currentMember.role === 'owner' ? 'Owner' : currentMember.role}
										</Typography>
									</S.DetailWrapper>
									<S.DetailWrapper>
										<Typography tag="p" variation="2" className="detail-heading">
											Phone Number
										</Typography>
										<Typography tag="p" variation="2">
											{currentMember.phone ? formatPhoneNumber(currentMember.phone) : '---'}
										</Typography>
									</S.DetailWrapper>
								</S.InfoWrapper>
								{isOwner && (
									<S.ActionWrapper>
										<Button variant="text" onClick={() => setIsMemberModalOpen(true)}>
											<FontAwesomeIcon icon={['fal', 'edit']} />
											<Typography variation="button-medium" weight="bold">
												Edit
											</Typography>
										</Button>
									</S.ActionWrapper>
								)}
							</S.InnerCardWrapper>
						</Card>
						<Card className="account-card">
							<S.Header>
								<Typography tag="h6" weight="bold">Account</Typography>
							</S.Header>
							<S.InnerCardWrapper>
								<S.InfoWrapper>
									<S.DetailWrapper>
										<Typography tag="p" variation="2" className="detail-heading">
											Email
										</Typography>
										<Typography tag="p" variation="2">
											{currentMember.email}
										</Typography>
									</S.DetailWrapper>
									{isOwner && (
										<S.DetailWrapper>
											<Typography tag="p" variation="2" className="detail-heading">
												Password
											</Typography>
											<Typography tag="p" variation="2" className="permission-text">
												************
											</Typography>
										</S.DetailWrapper>
									)}
								</S.InfoWrapper>
								{isOwner && (
									<S.ActionWrapper>
										<Button variant="text" onClick={() => setIsResetPasswordModalOpen(true)}>
											<FontAwesomeIcon icon={['fal', 'undo']} />
											<Typography variation="button-medium" weight="bold">
												Reset Password
											</Typography>
										</Button>
									</S.ActionWrapper>
								)}
							</S.InnerCardWrapper>
						</Card>
					</S.CardWrapper>
				</>
			)}
			<ProfileModal type="team" user={currentMember} isOpen={isMemberModalOpen} onRequestClose={handleCloseMemberModal} submit={handleEditFormSubmit} isSubmitting={isSubmitting} />
			<DeleteModal type="user" isOpen={isDeleteModalOpen} onRequestClose={handleCloseDeleteModal} handleDelete={handleDeleteSubmit} isDeleting={isDeleting} />
			<PasswordModal isOpen={isResetPasswordModalOpen} onRequestClose={() => setIsResetPasswordModalOpen(false)} submit={handleResetPassword} isSubmitting={isSubmittingPasswordReset} isEmailSent={isEmailSent} />
		</UserNavigation>
	);
};

export default TeamMemberProfile;
