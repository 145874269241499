import React, {
	useState, useEffect, useRef
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { fetchTags } from '../../../app/slices/tags/tagsSlice';

import {
	Button, Popover, Typography, Checkbox, DateInput, Select
} from '../../../components';

import * as S from './Filter.styles';

const Filter = ({ updateFilters, filterCount }) => {
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [notStarted, setNotStarted] = useState(false);
	const [inProgress, setInProgress] = useState(false);
	const [complete, setComplete] = useState(false);
	const [tagOptions, setTagOptions] = useState([]);
	const [filterUpdated, setFilterUpdated] = useState(false);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const { value: tags } = useSelector((state) => state.tags);
	const tagsRef = useRef();
	const dispatch = useDispatch();

	useEffect(() => {
		if (tags === null) {
			dispatch(fetchTags());
		}
	}, [tags, dispatch]);

	const formatTag = (tag) => ({
		label: tag.title,
		value: tag.id
	});

	const handleTogglePopover = (isOpen) => {
		setIsPopoverOpen(isOpen);
	};

	const resetFilters = () => {
		setStartDate();
		setEndDate();
		setTagOptions([]);
		setNotStarted(false);
		setInProgress(false);
		setComplete(false);
		setFilterUpdated(false);
		updateFilters(null, true);
		handleTogglePopover(false);
	};

	useEffect(() => {
		resetFilters();
	}, []);

	const handleStartDateChange = (value) => {
		setStartDate(value);
		setFilterUpdated(true);
	};

	const handleEndDateChange = (value) => {
		setEndDate(value);
		setFilterUpdated(true);
	};

	const handleNotStartedChange = () => {
		setNotStarted(!notStarted);
		setFilterUpdated(true);
	};

	const handleInProgressChange = () => {
		setInProgress(!inProgress);
		setFilterUpdated(true);
	};

	const handleCompleteChange = () => {
		setComplete(!complete);
		setFilterUpdated(true);
	};

	const handleTagChange = (value) => {
		setTagOptions(value);
		setFilterUpdated(true);
	};

	const invalidDates = startDate && endDate && startDate.setHours(0, 0, 0, 0) > endDate.setHours(0, 0, 0, 0);

	const renderContent = () => (
		<S.Content>
			<S.FilterSection>
				<S.FilterSectionHeader>
					<Typography tag="h6" weight="bold">Date</Typography>
				</S.FilterSectionHeader>
				<S.DateWrapper onClick={(e) => e.stopPropagation()}>
					<DateInput id="start-date" selected={startDate} onChange={handleStartDateChange} label="Start Date" containerClassName="select-start-date-field" placeholderText="MM/DD/YYYY" />
					<FontAwesomeIcon icon={['fal', 'minus']} className="separator-icon" />
					<DateInput id="end-date" selected={endDate} onChange={handleEndDateChange} label="End Date" containerClassName="select-end-date-field" placeholderText="MM/DD/YYYY" />
				</S.DateWrapper>
				{startDate && endDate && invalidDates && (
					<S.ErrorWrapper>
						Start date cannot be ahead of end date
					</S.ErrorWrapper>
				)}
			</S.FilterSection>
			<S.FilterSection>
				<S.FilterSectionHeader>
					<Typography tag="h6" weight="bold">Status</Typography>
				</S.FilterSectionHeader>
				<S.CheckboxWrapper>
					<Checkbox id="not-started" label="Not Started" checked={notStarted} onChange={handleNotStartedChange} containerClassName="checkbox" />
					<Checkbox id="in-progress" label="In-Progress" checked={inProgress} onChange={handleInProgressChange} containerClassName="checkbox" />
					<Checkbox id="complete" label="Complete" checked={complete} onChange={handleCompleteChange} containerClassName="checkbox" />
				</S.CheckboxWrapper>
			</S.FilterSection>
			<S.FilterSection>
				<S.FilterSectionHeader>
					<Typography tag="h6" weight="bold">Tags</Typography>
				</S.FilterSectionHeader>
				{tags && (
					<S.SelectWrapper onClick={(e) => e.stopPropagation()}>
						<Select
							id="tags"
							ref={tagsRef}
							options={[...tags].sort((a, b) => new Date(a.createdAt.iso).getTime() - new Date(b.createdAt.iso)).map((tag) => formatTag(tag))}
							value={tagOptions}
							onChange={(val) => {
								if (val) {
									handleTagChange(val.map((c) => c));
								}

							}}
							placeholder="Select Tags"
							isMulti
							tag
						/>
					</S.SelectWrapper>

				)}
			</S.FilterSection>
			<hr />
			<S.ActionsWrapper>
				<S.ClearWrapper>
					<Button variant="text" variation="warning" onClick={resetFilters}>Clear all filters</Button>
				</S.ClearWrapper>
				<S.ApplyWrapper>
					<Button
						className="cancel-button"
						onClick={() => {
							updateFilters({
								startDate, endDate, status: { not_started: notStarted, in_progress: inProgress, complete }, tags: tagOptions
							});
							handleTogglePopover(false);
						}}
						disabled={!filterUpdated || invalidDates}
					>
						Apply
					</Button>
				</S.ApplyWrapper>
			</S.ActionsWrapper>
		</S.Content>

	);

	return (
		<S.Wrapper>
			<Popover id="filter-popover" positions={['bottom']} content={renderContent()} isOpen={isPopoverOpen} onClickOutside={() => handleTogglePopover(false)}>
				<Button variant="outline" className="filter-button" onClick={() => handleTogglePopover(true)}>
					{filterCount > 0 ? <S.FilterCount><Typography tag="p" variation="3" weight="semibold">{filterCount}</Typography></S.FilterCount> : <FontAwesomeIcon icon={['fal', 'sliders-h']} />}
					<Typography tag="p">Filter</Typography>
				</Button>
			</Popover>
		</S.Wrapper>
	);

};

export default Filter;
Filter.displayName = 'Filter';
Filter.propTypes = {
	updateFilters: PropTypes.func,
	filterCount: PropTypes.number
};
