import styled from 'styled-components';

export const Wrapper = styled.div`
	padding: 1rem 2rem;

	@media screen and (max-width: 992px) {
		padding: 1rem;
	}
`;

export const ActionWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
`;

export const SearchWrapper = styled.div`
	width: 50%;
	margin-bottom: 1rem;

	@media screen and (max-width: 992px) {
		min-width: 70%;
		width: 70%;
	}

	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 3rem;

	@media screen and (max-width: 768px) {
		margin-bottom: 1rem;

		.add-user-button {
			width: 100%;
		}
	}
`;
