import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getCurrentAuthenticatedUser } from '../../../services/authService';
import { uploadMedia as addMedia, updateUserProfile as updateCurrentUser } from '../../../services/user/userService';
import { errorHandler } from '../../../services/api';
import { getCookie, removeCookie } from '../../../utils/cookie-methods';

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await getCurrentAuthenticatedUser();
		if (response?.data?.result?.user) {
			return response.data.result.user;
		}
		removeCookie('e_s_token');
		return null;

	} catch (error) {
		removeCookie('e_s_token');
		return null;
	}
});

export const updateUserProfile = createAsyncThunk('user/updateUserProfile', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await updateCurrentUser(data);
		if (response?.data?.result?.user) {
			return response.data.result.user;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const uploadMedia = createAsyncThunk('user/uploadMedia', async (data) => {
	const formData = new FormData();
	formData.append('file', data);
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await addMedia(formData);
		if (response) {
			return response;
		}
		removeCookie('e_s_token');
		return null;

	} catch (error) {
		removeCookie('e_s_token');
		return null;
	}
});

export const userSlice = createSlice({
	name: 'user',
	initialState: {
		status: 'idle',
		mediaStatus: 'idle',
		error: null,
		value: null,
	},
	reducers: {
		updateUser: (state, action) => {
			state.value = action.payload;
		},
		clearUser: (state) => {
			state.value = null;
		},
	},
	extraReducers: {
		[fetchUser.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchUser.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.value = action.payload;
		},
		[fetchUser.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
		[updateUserProfile.pending]: (state) => {
			state.status = 'loading';
		},
		[updateUserProfile.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.value = action.payload;
		},
		[updateUserProfile.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
		[uploadMedia.pending]: (state) => {
			state.mediaStatus = 'loading';
		},
		[uploadMedia.fulfilled]: (state) => {
			state.mediaStatus = 'succeeded';
		},
		[uploadMedia.rejected]: (state, action) => {
			state.mediaStatus = 'failed';
			state.error = action.error.message;
		},
	},
});

export const { updateUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
