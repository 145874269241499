import styled from 'styled-components';

export const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
	position: absolute;
	top: 3rem;
	bottom: 0;
	max-width: 100%;
	width: calc(100% - 3.25rem);

	.page-header {
		border-bottom: none;
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		overflow: auto;
	}
`;

export const Wrapper = styled.div`
	width: 100%;

	.tabs {
		padding: 0rem 1rem;
	}

	.tab-content {
		@media screen and (max-width: 768px) {
			padding: 1rem;
			position: relative;
			top: 0rem;
			width: 100%;
		}
	}
`;
