import { configureStore } from '@reduxjs/toolkit';

import contactsReducer from './slices/contacts/contactsSlice';
import dashboardReducer from './slices/dashboard/dashboardSlice';
import dispatchesReducer from './slices/dispatches/dispatchesSlice';
import menuReducer from './slices/menu/menuSlice';
import tagsReducer from './slices/tags/tagsSlice';
import tasksReducer from './slices/tasks/tasksSlice';
import teamReducer from './slices/team/teamSlice';
import userReducer from './slices/user/userSlice';


export default configureStore({
	reducer: {
		contacts: contactsReducer,
		dashboard: dashboardReducer,
		dispatches: dispatchesReducer,
		menu: menuReducer,
		tags: tagsReducer,
		tasks: tasksReducer,
		team: teamReducer,
		user: userReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	}),
});
