import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { formatPhoneNumber } from '../../../../utils/phone-formatter';
import {
	Avatar, Table, Link, EmptyComponent, Card
} from '../../../../components';
import * as S from './TeamTable.styles';

const TeamTable = ({ team }) => {
	const user = useSelector((state) => state.user.value);
	const [filteredTeam, setFilteredTeam] = useState(team);

	useEffect(() => {
		const teamArr = team && team.length > 0 ? [...team] : [];
		setFilteredTeam(teamArr.filter((member) => {
			if (member && user) {
				return member.id !== user.id;
			} return false;
		}));
	}, [team]);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'firstName lastName',
				width: 225,
				Cell: (data) => {
					const { row } = data;
					return (
						<div className="name-table-cell">
							<Link to={`/dashboard/team/${row.original.id}`}>
								<Avatar avatar={row.original.profileImage['1500']} className="avatar" />
								<div className="name">{`${row.original.firstName} ${row.original.lastName}`}</div>
							</Link>
							{!row.original.accountSetupComplete && <div className="invitation-pill">Invitation Pending</div>}
						</div>
					);
				},
			},
			{
				Header: 'Permissions',
				accessor: 'role',
				Cell: (data) => {
					const { value } = data;
					return (
						<div className="role-table-cell">
							{value === 'owner' ? 'Owner' : value}
						</div>
					);
				},
			},
			{
				Header: 'Phone',
				accessor: 'phone',
				Cell: (data) => {
					const { value } = data;
					return (
						<div className="phone-table-cell">
							{formatPhoneNumber(value)}
						</div>
					);
				},
			},
			{
				Header: 'Email',
				accessor: 'email',
				width: 150,
				Cell: (data) => {
					const { value } = data;
					return (
						<div className="email-table-cell">
							{value}
						</div>
					);
				},
			},
		],
		[]
	);

	return (
		<S.Wrapper>
			{filteredTeam && filteredTeam.length > 0 ? <Table columns={columns} data={filteredTeam} /> : <Card><EmptyComponent message="You currently have no team members" icon={['fal', 'users']} /></Card>}
		</S.Wrapper>
	);
};

export default TeamTable;

TeamTable.displayName = 'TeamTable';
TeamTable.propTypes = {
	team: PropTypes.array
};
