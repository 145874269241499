/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { fetchDispatches, fetchMoreDispatches, fetchPreviousDispatches } from '../../../app/slices/dispatches/dispatchesSlice';
import { selectAccordion, setAccordionDates } from '../../../app/slices/dashboard/dashboardSlice';

import { DispatchAccordion } from '../DispatchAccordion';
import { DispatchCard } from '../DispatchCard';
import { DispatchDropZoneContainer } from './DispatchDropZoneContainer';
import { Loader, Button, Typography } from '../../../components';
import { shouldShowPaywall } from '../../../utils/should-show-paywall';

import * as S from './DispatchAccordionList.styles';

const DispatchAccordionList = ({ loading }) => {
	const currentDate = moment(new Date(), 'MM-DD-YYYY');
	const initialDate = moment(currentDate, 'YYYY-MM-DD').subtract(7, 'd');
	const [loadingMore, setLoadingMore] = useState(false);
	const [loadingPrevious, setLoadingPrevious] = useState(false);
	const [/* isElementScrolling */, setIsElementScrolling] = useState(false);
	const [/* isElementVisible */, setIsElementVisible] = useState(false);
	const user = useSelector((state) => state.user.value);
	const { value: dispatches } = useSelector((state) => state.dispatches);
	const accordionDates = useSelector((state) => state.dashboard.accordionDates);
	const reduxDispatch = useDispatch();
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const activeDate = urlParams.get('activeDate');

	const updateDateArray = useCallback((num, date, shouldIncrement, shouldFetchDispatches) => {

		// Create Updated Dates Array based on date sent
		let n = 1;
		const datesArr = [...accordionDates];
		const newDatesArr = [];
		while (n <= num) {
			if (shouldIncrement) {
				datesArr.push(moment(date, 'MM-DD-YYYY').add(n, 'd').format('MM-DD-YYYY'));
				newDatesArr.push(moment(date, 'MM-DD-YYYY').add(n, 'd').format('MM-DD-YYYY'));
			} else {
				datesArr.unshift(moment(date, 'MM-DD-YYYY').subtract(n, 'd').format('MM-DD-YYYY'));
				newDatesArr.unshift(moment(date, 'MM-DD-YYYY').subtract(n, 'd').format('MM-DD-YYYY'));
			}

			n += 1;
		}

		if (!shouldFetchDispatches) {
			reduxDispatch(setAccordionDates(datesArr));
		}

		if (shouldFetchDispatches) {
			if (shouldIncrement) {
				setLoadingMore(true);
				reduxDispatch(fetchMoreDispatches({ datesArr: newDatesArr })).then(() => {
					reduxDispatch(setAccordionDates(datesArr));
					setLoadingMore(false);
				});
			} else {
				setLoadingPrevious(true);
				reduxDispatch(fetchPreviousDispatches({ datesArr: newDatesArr })).then(() => {
					reduxDispatch(setAccordionDates(datesArr));
					setLoadingPrevious(false);
				});
			}
		}

		return { datesArr, newDatesArr };
	}, [accordionDates, reduxDispatch]);

	useEffect(() => {
		const el = document.getElementById('accordion-list');
		const visibleEl = document.getElementById('bottom-load-more');

		if (accordionDates && accordionDates.length === 1) {
			const datesArr = updateDateArray(13, initialDate, true)?.datesArr;
			if (!shouldShowPaywall(user)) {
				if ((dispatches && dispatches.length === 0) || dispatches === null) {
					reduxDispatch(fetchDispatches({ datesArr })).then(() => {
						if (!datesArr.includes(activeDate)) {
							const url = new URL(window.location);
							url.searchParams.set('activeDate', datesArr[0]);
							window.history.pushState(null, '', url.toString());

							reduxDispatch(selectAccordion(datesArr[0]));
						}
					});
				}
			}
		}

		const checkScroll = () => {
			const { scrollTop, scrollHeight, offsetHeight } = el;

			const contentHeight = scrollHeight - offsetHeight;
			if (contentHeight <= (scrollTop + 0.5) || contentHeight <= scrollTop) {
				setIsElementScrolling(true);
			}
		};

		const observer = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting) {
				setIsElementVisible(true);
			}
		});

		if (el) {
			el.addEventListener(
				'scroll',
				checkScroll,
				false
			);
		}

		if (visibleEl) {
			observer.observe(el);
		}

		return () => window.removeEventListener('resize', checkScroll);
	}, [accordionDates, activeDate, dispatches, initialDate, reduxDispatch, updateDateArray, user]);

	const loadPreviousAccordions = () => {
		const dates = [...accordionDates];
		const firstDate = dates[0];
		updateDateArray(7, firstDate, false, true);
	};

	const loadMoreAccordions = () => {
		const dates = [...accordionDates];
		const lastDate = dates[dates.length - 1];
		updateDateArray(7, lastDate, true, true);
	};

	const filteredDispatchList = useCallback((date) => (dispatches && dispatches.length > 0 ? dispatches.filter((dispatch) => date === moment(dispatch.targetDate.iso, 'YYYY-MM-DD').format('MM-DD-YYYY')) : []), [dispatches]);

	return (
		<>
			{!loading && (
				<>
					{/* isElementVisible && isElementScrolling && ( */
						<S.LoadWrapper className="fade-in">
							{loadingPrevious ? <Loader /> : (
								<Button id="top-load-more" variant="text" className="load-button" onClick={() => loadPreviousAccordions()}>
									<Typography variation="button-medium" weight="semibold">
										Load Previous
									</Typography>
								</Button>
							)}
						</S.LoadWrapper>
					/* ) */}
					<DispatchAccordion allowMultipleOpen>
						{[...accordionDates].map((date) => (
							<div key={date} label={`${moment(date, 'MM-DD-YYYY').format('ddd, MMM DD')}`} dispatchCount={filteredDispatchList(date).length} date={date} currentDate={currentDate}>
								<DispatchDropZoneContainer date={date}>
									{loading ? <Loader /> : dispatches && dispatches.length > 0 ? filteredDispatchList(date).sort((a, b) => a.order - b.order).map((dispatch, index) => <DispatchCard key={dispatch.id} index={index} dispatch={dispatch} />) : null}
								</DispatchDropZoneContainer>
							</div>
						))}
					</DispatchAccordion>
					<S.LoadWrapper className="fade-in">
						{loadingMore ? <Loader /> : (
							<Button id="bottom-load-more" variant="text" className="load-button" onClick={() => loadMoreAccordions()}>
								<Typography variation="button-medium" weight="semibold">
									Load More
								</Typography>
							</Button>
						)}

					</S.LoadWrapper>
				</>
			)}
			<div />
		</>
	);
};

export default DispatchAccordionList;

DispatchAccordionList.displayName = 'DispatchAccordionList';
DispatchAccordionList.propTypes = {
	loading: PropTypes.bool,
};
