import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useDispatch } from 'react-redux';

import { updateUser } from '../../app/slices/user/userSlice';
import { loginUser } from '../../services/authService';
import { errorHandler } from '../../services/api';
import { pattern, required } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';
import logo from '../../assets/dispatcher-logo.png';
import {
	Typography, Button, TextInput, Link
} from '../../components';
import * as S from './LoginForm.styles';
import { META_TITLE } from '../../../constants/general.constants';

const LoginForm = () => {
	const history = useHistory();
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm();
	const dispatch = useDispatch();

	useEffect(() => {
		document.title = `Log In | ${META_TITLE}`;
	}, []);

	async function asyncCaller(data) {
		const {
			data: { user },
		} = await loginUser({ username: data.email, password: data.password });

		dispatch(updateUser(user));
		history.push('/dashboard');
	}

	function onSubmit(e) {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	}

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1" />
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="Dispatchr Logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			<S.Container>
				<Typography tag="h2" weight="bold" center>
					Login
				</Typography>
				<Typography tag="p" variation="1" className="description" center>
					Enter login info below.
				</Typography>
				<S.Form onSubmit={onSubmit}>
					<TextInput
						label="Email"
						id="email"
						error={errors.email}
						autoComplete="email"
						{...register('email', {
							required: required('Email'),
							pattern: pattern('Email', EMAIL),
						})}
					/>
					<div>
						<TextInput
							label="Password"
							id="password"
							type="password"
							error={errors.password}
							autoComplete="current-password"
							{...register('password', {
								required: required('Password'),
							})}
						/>
						<S.Link to="/forgot-password">Reset Password</S.Link>
					</div>
					<Typography tag="p" variation="2" className="login-message" center>
						<span>Don&apos;t have an account?</span>
						{' '}
						<Link to="/sign-up">Signup</Link>
					</Typography>
					<Button type="submit" className="submit-button">
						<Typography variation="button-medium" weight="bold">
							{isSubmitting ? 'Loading...' : 'Login'}
						</Typography>
					</Button>
				</S.Form>
			</S.Container>
		</S.Wrapper>
	);
};

export default LoginForm;
