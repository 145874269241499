import colors from '../styles/colors';

export const statusText = (status) => {
	switch (status) {
		case 'not_started':
			return 'Not Started';
		case 'in_progress':
			return 'In Progress';
		case 'complete':
			return 'Complete';
		default:
			return 'Not Started';
	}
};

export const statusIcon = (status) => {
	switch (status) {
		case 'not_started':
			return 'times-circle';
		case 'in_progress':
			return 'clock';
		case 'complete':
			return 'check-circle';
		default:
			return 'times-circle';
	}
};

export const statusColor = (status) => {
	switch (status) {
		case 'not_started':
			return colors.neutralForm;
		case 'in_progress':
			return colors.lightStateInfo;
		case 'complete':
			return colors.lightStateSuccess;
		default:
			return 'times-circle';
	}
};
