import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PaywallModal } from '../components';

// Hook to show, hide, and render Paywall
export const usePaywall = () => {
	const [isVisible, setIsVisible] = useState(false);
	const user = useSelector((state) => state.user.value);
	const isFreeTrial = user?.subscription?.tier === 'free';

	const showPaywall = () => setIsVisible(true);
	const hidePaywall = () => setIsVisible(false);

	const Paywall = () => (
		<div>
			{isVisible && <PaywallModal isOpen={isVisible} onRequestClose={hidePaywall} isFreeTrial={isFreeTrial} />}
		</div>
	);

	return {
		showPaywall,
		hidePaywall,
		Paywall,
	};
};
