import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import * as S from './TextInput.styles';

export const TextInput = forwardRef(({
	id, label, error, type = 'text', size = 'medium', containerClassName, icon, hideLastPass, ...rest
}, ref) => {
	const [showPassword, setShowPassword] = useState(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);

	};
	return (
		<S.Wrapper error={error} className={containerClassName}>
			{label && (
				<Typography tag="label" weight="bold" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null}>
					{label}
				</Typography>
			)}
			{icon && (
				<S.IconWrapper>
					<FontAwesomeIcon icon={icon} />
				</S.IconWrapper>
			)}
			<S.TextInput ref={ref} id={id} error={error} {...rest} type={showPassword ? 'text' : type} size={size} withIcon={!!icon} withPasswordIcon={type === 'password'} data-lpignore={hideLastPass} />
			{error && error.message && (
				<S.FloatingWrapper title={error.message}>
					<Typography tag="p" variation="2">
						{error.message}
					</Typography>
				</S.FloatingWrapper>
			)}
			{type === 'password' && (
				<S.PasswordIconWrapper onClick={handleShowPassword}>
					<FontAwesomeIcon icon={['fal', `${showPassword ? 'eye-slash' : 'eye'}`]} />
				</S.PasswordIconWrapper>
			)}
		</S.Wrapper>
	);
});

TextInput.displayName = 'TextInput';
TextInput.propTypes = {
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	containerClassName: PropTypes.string,
	type: PropTypes.oneOf(['text', 'password', 'email']),
	size: PropTypes.oneOf(['small', 'medium']),
	id: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.shape(),
	hideLastPass: PropTypes.bool,
};
