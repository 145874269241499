import React, {
	useState, useEffect, useCallback, useRef, useMemo, useContext
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { FirstPageRounded, LastPageRounded } from '@mui/icons-material/';

import { useDrop } from 'react-dnd';

import { useDispatch, useSelector } from 'react-redux';
import {
	fetchTasks, addPlaceholderTask, addTaskToBacklog, moveTask
} from '../../app/slices/tasks/tasksSlice';
import { addDispatch, removeTaskFromDispatch } from '../../app/slices/dispatches/dispatchesSlice';
import { fetchTags } from '../../app/slices/tags/tagsSlice';
import { fetchContacts } from '../../app/slices/contacts/contactsSlice';
import { setIsBacklogOpen } from '../../app/slices/dashboard/dashboardSlice';
import { errorHandler } from '../../services/api';

import {
	UserNavigation, PageHeader, Typography, Button, ScrollableContainer, Loader, IconButton
} from '../../components';
import { TaskList } from './TaskList';
import { DispatchAccordionList } from './DispatchAccordionList';
import * as S from './Dashboard.styles';

import { usePaywall } from '../../hooks/usePaywall';
import { shouldShowPaywall } from '../../utils/should-show-paywall';
import { scrollToTarget } from '../../utils/scroll-to-target';
import { openTarget } from '../../utils/open-target';

import { META_TITLE } from '../../../constants/general.constants';

import { useSocket } from '../../hooks/useSocket';
import { SocketContext } from '../../context/socket';

import { useUserPermissions } from '../../hooks/useUserPermissions';

const Dashboard = () => {
	const [showDispatches, setShowDispatches] = useState(true);
	const [dispatchLoading, setDispatchLoading] = useState(false);
	const [moreTasksLoading, setMoreTasksLoading] = useState(false);
	const [backlogLoading, setBacklogLoading] = useState(false);
	const [page, setPage] = useState(0);
	const dispatch = useDispatch();
	const scrollRef = useRef();
	const { value: tasks, totalCount: tasksTotal, isLastPage } = useSelector((state) => state.tasks);
	const { status: dispatchesStatus } = useSelector((state) => state.dispatches);
	const { value: tags } = useSelector((state) => state.tags);
	const { value: contacts } = useSelector((state) => state.contacts);
	const user = useSelector((state) => state.user.value);
	const accordionSelected = useSelector((state) => state.dashboard.accordionSelected);
	const isBacklogOpen = useSelector((state) => state.dashboard.isBacklogOpen);
	const { showPaywall, Paywall } = usePaywall();
	const screenWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	const isMobileView = useMemo(() => screenWidth < 960, [screenWidth]);
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const activeDate = urlParams.get('activeDate');
	const scrollableDiv = document.getElementById('accordion-list');
	const targetElement = document.getElementById(`accordion-${moment(activeDate, 'MM-DD-YYYY').format('x')}`);
	const pageLimit = 20;
	const socket = useContext(SocketContext);
	const { socketListener: dashboardSocket } = useSocket(socket);
	const { hasAdminAccess } = useUserPermissions();

	useEffect(() => {
		const accordionContainer = document.getElementById('accordion-list');
		const targetAccordion = document.getElementById(`accordion-${moment(activeDate || accordionSelected, 'MM-DD-YYYY').format('x')}`);
		if (targetAccordion && accordionContainer) {
			scrollToTarget(targetAccordion, accordionContainer);
		}
	}, []);

	useEffect(() => {
		if (scrollableDiv && targetElement) {
			scrollToTarget(targetElement, scrollableDiv);
		}
	}, [targetElement, scrollableDiv]);

	useEffect(() => {
		document.title = `Dashboard | ${META_TITLE}`;

		dashboardSocket();

		if (!shouldShowPaywall(user)) {
			if (tasks === null) {
				setBacklogLoading(true);
				dispatch(fetchTasks({ placement: 'backlog', limit: pageLimit, page })).then(() => {
					setBacklogLoading(false);
				});
			}

			if (tags === null) {
				dispatch(fetchTags());
			}

			if (contacts === null || (contacts && contacts.length === 0)) {
				dispatch(fetchContacts());
			}
		}

		if (shouldShowPaywall(user)) {
			showPaywall();
		}

		// eslint-disable-next-line react/destructuring-assignment
		return () => socket.disconnect();
	}, [user, socket]);

	const handleLoadMoreTasks = () => {
		const currentPage = page;
		const nextPage = currentPage + 1;
		setPage(nextPage);
		setMoreTasksLoading(true);
		dispatch(fetchTasks({ placement: 'backlog', limit: pageLimit, page: nextPage })).then(() => setMoreTasksLoading(false));
	};

	const onScroll = () => {
		if (scrollRef.current) {
			const {
				scrollTop, scrollHeight, offsetHeight
			} = scrollRef.current;

			if (Math.ceil(scrollTop + offsetHeight) === scrollHeight) {
				if (tasks && tasks.length !== tasksTotal && !moreTasksLoading && !isLastPage) {
					handleLoadMoreTasks();
				}
			}
		}
	};

	const handleSearch = (value) => {
		dispatch(fetchTasks({ searchText: value, placement: 'backlog', limit: pageLimit }));
	};

	const handleMoveTask = useCallback(
		(task) => {
			dispatch(addTaskToBacklog({
				...task, placement: 'backlog', isSent: false, status: 'not_started'
			}));
			dispatch(removeTaskFromDispatch({ taskId: task.id, dispatchId: task.dispatchId, persist: true }));
			dispatch(moveTask({
				taskId: task.id, targetPlacement: 'backlog', position: 0
			})).catch(() => errorHandler());
		},
		[dispatch],
	);

	const [{ isOver }, drop] = useDrop(
		() => ({
			accept: 'task',
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
			}),
			drop: (item) => {
				handleMoveTask(item);
			}
		}),
		[]
	);

	const handleShowDispatches = () => {
		setShowDispatches(!showDispatches);
	};

	const handleAddPlaceholderTask = (e) => {
		e.stopPropagation();
		dispatch(addPlaceholderTask({
			id: `temp-id-${Math.round(Math.random() * (100000 - 2) + 2)}`, content: '', tags: [], placement: 'backlog'
		}));
	};

	const handleAddDispatch = () => {
		setDispatchLoading(true);
		dispatch(addDispatch({ targetDate: accordionSelected })).then(() => {
			setDispatchLoading(false);
			scrollToTarget(targetElement, scrollableDiv);
			openTarget(targetElement, 'accordion-open', 'accordion-open-icon');
		}).catch(() => setDispatchLoading(false));
	};

	return (
		<UserNavigation>
			<PageHeader
				title="Dashboard"
				action={showDispatches && hasAdminAccess ? [{
					id: 'dashboard-header',
					onClick: () => handleAddDispatch(),
					label: 'New Dispatch',
					icon: 'add-comment',
					variation: 'secondary'
				}] : []}
				handleSearch={!showDispatches || !isMobileView ? handleSearch : null}
				searchPlaceholder="Search Backlog"
				loading={dispatchLoading}
			/>
			<S.Container>
				<S.Wrapper className="desktop-only">
					<S.BacklogWrapper ref={tasks && tasks.length === 0 ? drop : null} isBacklogOpen={isBacklogOpen} className="fade-in">
						{isBacklogOpen ? (
							<>
								<S.BacklogActionWrapper>
									<Typography tag="h5" weight="semibold">Backlog</Typography>
									<div className="backlog-right-actions">
										<Button
											variant="outline"
											size="small"
											className="add-task-button fade-in"
											onClick={(e) => setTimeout(handleAddPlaceholderTask(e))}
										>
											<FontAwesomeIcon icon={['fal', 'plus']} />
											<Typography tag="span" variation="2">
												Add Job
											</Typography>
										</Button>
										<FirstPageRounded className="backlog-icon" onClick={() => dispatch(setIsBacklogOpen(false))} />
									</div>
								</S.BacklogActionWrapper>

								<ScrollableContainer ref={scrollRef} onScroll={onScroll}>
									<div className="backlog-inner-wrapper">
										{backlogLoading ? <Loader /> : (
											<TaskList tasks={tasks} containerClassName="dashboard-task-list" showEmptyMessage emptyIsOver={isOver} handleLoadMore={handleLoadMoreTasks} isBacklog />
										)}
										{moreTasksLoading && <S.LoaderContainer><Loader /></S.LoaderContainer>}
									</div>
								</ScrollableContainer>

							</>

						)
							: (
								<S.BacklogCollapsed className="fade-in-long">
									<LastPageRounded className="backlog-icon" onClick={() => dispatch(setIsBacklogOpen(true))} />
									<Typography tag="h5" weight="semibold">Backlog</Typography>
								</S.BacklogCollapsed>
							) }
					</S.BacklogWrapper>
					<S.DispatchAccordionWrapper>
						<ScrollableContainer id="accordion-list">
							<DispatchAccordionList loading={dispatchesStatus === 'loading'} />
						</ScrollableContainer>
					</S.DispatchAccordionWrapper>
				</S.Wrapper>
				<S.Wrapper className="mobile-only fade-in">
					<S.DispatchContainer>
						{showDispatches && (
							<S.DispatchAccordionWrapper>
								<ScrollableContainer id="accordion-list">
									<DispatchAccordionList loading={dispatchesStatus === 'loading'} />
								</ScrollableContainer>
							</S.DispatchAccordionWrapper>
						)}
						<S.ToggleWrapper showDispatches={showDispatches}>
							<IconButton icon={['fal', 'grip-lines']} onClick={handleShowDispatches} />
						</S.ToggleWrapper>
					</S.DispatchContainer>
					{!showDispatches && (
						<S.BacklogWrapper ref={tasks && tasks.length === 0 ? drop : null}>
							<S.BacklogActionWrapper>
								<Typography tag="h5" weight="semibold">Backlog</Typography>
								<Button
									variant="outline"
									className="add-task-button"
									onClick={(e) => setTimeout(handleAddPlaceholderTask(e))}
								>
									<FontAwesomeIcon icon={['fal', 'plus']} />
									<Typography tag="span" variation="2">
										Add Job
									</Typography>
								</Button>
							</S.BacklogActionWrapper>
							<ScrollableContainer ref={scrollRef} onScroll={onScroll}>
								{backlogLoading ? <Loader /> : <TaskList tasks={tasks} containerClassName="dashboard-task-list" showEmptyMessage emptyIsOver={isOver} lastIndex={tasks && tasks.length} handleLoadMore={handleLoadMoreTasks} isBacklog />}
								{moreTasksLoading && <S.LoaderContainer className="fade-in"><Loader /></S.LoaderContainer>}
							</ScrollableContainer>
						</S.BacklogWrapper>
					)}
				</S.Wrapper>
			</S.Container>
			<Paywall />
			<ReactTooltip delayShow={500} textColor="black" backgroundColor="white" borderColor="#EBECF0" border />
		</UserNavigation>
	);
};

export default Dashboard;
