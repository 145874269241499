import styled from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
	overflow: hidden;
	position: absolute;
	top: 7.5rem;
	bottom: 0;
	max-width: 100%;
	width: calc(100% - 3.25rem);
	padding: 2rem;

	.table-scrollable-container {
		height: ${({ hasPagination }) => (hasPagination ? '85%' : '100%')};
		padding: 0;
	}

	@media screen and (max-width: 992px) {
		overflow: auto;
		padding: 1rem;
		position: relative;
		top: 0rem;
		width: 100%;
	}
`;

export const SearchWrapper = styled.div`
	width: 50%;
	margin-bottom: 1rem;

	@media screen and (max-width: 768px) {
		display: none;
	}

	@media screen and (max-width: 992px) {
		min-width: 70%;
		width: 70%;
	}
`;

export const EmptyWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5rem;

	.empty-card {
		width: 50%;
	}
`;
