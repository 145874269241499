import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import * as S from './TagPill.styles';

export const TagPill = ({ name }) => (
	<S.Wrapper>
		<S.PillWrapper>
			<S.Content>
				<Typography tag="p" variation="3">
					{name}
				</Typography>
			</S.Content>
		</S.PillWrapper>
	</S.Wrapper>
);

TagPill.propTypes = {
	name: PropTypes.string.isRequired
};
