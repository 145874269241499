import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash.debounce';

import {
	TextInput
} from '..';
import * as S from './DebouncedSearch.styles';

export const DebouncedSearch = ({ handleSearch, placeholder = 'Search' }) => {
	const [, setSearchTerm] = useState('');

	const handleChange = (e) => {
		const { value } = e.target;
		setSearchTerm(value);
		handleSearch(value);
	};

	// Function to return results based on an interval
	const debouncedResults = useMemo(() => debounce(handleChange, 300), []);

	useEffect(() => () => {
		debouncedResults.cancel();
	});

	return (
		<S.Wrapper>
			<TextInput
				id="search"
				onChange={debouncedResults}
				placeholder={placeholder}
				icon={['fal', 'search']}
				containerClassName="text-input"
			/>
		</S.Wrapper>
	);
};


DebouncedSearch.displayName = 'DebouncedSearch';
DebouncedSearch.propTypes = {
	handleSearch: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
};
