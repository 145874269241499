import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch, useSelector } from 'react-redux';
import {
	addTeamMember, fetchTeam
} from '../../../app/slices/team/teamSlice';
import { errorHandler } from '../../../services/api';
import {
	uploadMedia
} from '../../../app/slices/user/userSlice';

import {
	Button, DebouncedSearch, ProfileModal, ScrollableContainer, Spinner, Typography
} from '../../../components';
import { TeamTable } from './TeamTable';
import * as S from './TeamTab.styles';

import { usePaywall } from '../../../hooks/usePaywall';
import { shouldShowPaywall } from '../../../utils/should-show-paywall';

const TeamTab = () => {
	const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const dispatch = useDispatch();
	const { value: team, status: teamStatus } = useSelector((state) => state.team);
	const { value: user } = useSelector((state) => state.user);
	const { showPaywall, Paywall } = usePaywall();

	useEffect(() => {
		dispatch(fetchTeam());
		if (shouldShowPaywall(user)) {
			showPaywall();
		}
	}, []);

	const handleSearch = (value) => {
		dispatch(fetchTeam({ searchText: value }));
	};

	const handleFormSubmit = (data, reset) => {
		const { avatar, role } = data;
		setIsSubmitting(true);
		if (avatar) {
			dispatch(uploadMedia(avatar)).then((res) => {
				const payload = res?.payload;
				dispatch(addTeamMember({ ...data, profileImageObj: payload?.data, role })).then(() => {
					setIsSubmitting(false);
					setIsMemberModalOpen(false);
					reset();
				}).catch((err) => errorHandler(err));
			});
		} else {
			dispatch(addTeamMember({ ...data, role })).then(() => {
				setIsSubmitting(false);
				setIsMemberModalOpen(false);
				reset();
			}).catch((err) => errorHandler(err));
		}
	};

	return (
		<>
			<S.Wrapper>
				<S.ActionWrapper>
					<S.SearchWrapper>
						<DebouncedSearch handleSearch={handleSearch} />
					</S.SearchWrapper>
					{user.role === 'owner' && (
						<S.ButtonWrapper>
							<Button onClick={() => setIsMemberModalOpen(true)} variation="secondary" className="add-user-button">
								<FontAwesomeIcon icon={['fal', 'plus']} />
								<Typography tag="p">
									Add User
								</Typography>
							</Button>
						</S.ButtonWrapper>
					)}

				</S.ActionWrapper>
				<ScrollableContainer className="table-scrollable-container">
					{teamStatus === 'loading' ? <Spinner /> : (
						<TeamTable team={team} />
					)}
				</ScrollableContainer>
			</S.Wrapper>
			<ProfileModal type="team" isOpen={isMemberModalOpen} onRequestClose={() => setIsMemberModalOpen(false)} submit={handleFormSubmit} isSubmitting={isSubmitting} />
			<Paywall />
		</>
	);
};

export default TeamTab;
