import styled from 'styled-components';

export const Navigation = styled.div`
	display: flex;
	flex-direction: column;
	height: inherit;
	position: relative;
`;

export const Wrapper = styled.div`
	flex-grow: 1;
	width: 100%;
	display: flex;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow: auto;
`;
