import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Typography } from '..';
import * as S from './ProfileHeader.styles';

export const ProfileHeader = ({
	title, avatar, backRoute, backText = 'Back to Resource', handleDelete, isInvitationPending, handleInvite, isInviteSent, isSubmittingInvite
}) => {
	const history = useHistory();

	const backToResources = () => {
		history.push(backRoute);
	};

	return (
		<S.Wrapper>
			<S.InfoWrapper>
				<Button className="header__back-button" variant="text" size="small">
					<FontAwesomeIcon icon={['fal', 'arrow-left']} />
					<Typography weight="bold" variation="button-small" onClick={backToResources}>
						{backText}
					</Typography>
				</Button>
				<div className="header__title-wrapper">
					<img className="header__title-wrapper__avatar" src={avatar} alt={`${title} logo`} />
					<Typography tag="h2" weight="bold">
						{title}
					</Typography>
					{isInvitationPending && <div className="invitation-pill">Invitation Pending</div>}
				</div>
			</S.InfoWrapper>
			<S.ActionsWrapper>
				{isInvitationPending && handleInvite && (
					<S.ActionWrapper className="invite-action">
						<Button variation={isInviteSent ? 'success' : 'default'} onClick={isInviteSent ? (e) => e.preventDefault() : () => handleInvite()} disabled={isInviteSent}>
							{isInviteSent ? (
								<>
									<FontAwesomeIcon icon={['fal', 'check']} />
									<Typography variation="button-medium" weight="bold">
										Invite Sent
									</Typography>
								</>

							) : (
								<Typography variation="button-medium" weight="bold">
									{isSubmittingInvite ? 'Sending...' : 'Resend Invite'}
								</Typography>
							) }

						</Button>
					</S.ActionWrapper>
				)}
				{handleDelete && (
					<S.ActionWrapper className="delete-action">
						<Button variant="outline" variation="warning" onClick={handleDelete}>
							<Typography variation="button-medium" weight="bold">
								Delete
							</Typography>
						</Button>
					</S.ActionWrapper>
				)}
			</S.ActionsWrapper>
		</S.Wrapper>
	);
};

ProfileHeader.propTypes = {
	title: PropTypes.string,
	avatar: PropTypes.string,
	backRoute: PropTypes.string,
	backText: PropTypes.string,
	handleDelete: PropTypes.func,
	isInvitationPending: PropTypes.bool,
	handleInvite: PropTypes.func,
	isInviteSent: PropTypes.bool,
	isSubmittingInvite: PropTypes.bool,
};
