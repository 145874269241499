import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import {
	Modal, IconButton, Button, Typography, DateInput
} from '../../../components';
import * as S from './MoveDispatchModal.styles';

const MoveDispatchModal = ({
	dispatch,
	isOpen,
	onRequestClose,
	targetDate,
	moveDispatch
}) => {

	const {
		formState: { isSubmitting },
	} = useForm();
	const [date, setDate] = useState(new Date());

	useEffect(() => {
		setDate(new Date(targetDate));
	}, [targetDate]);

	const onSubmit = (e) => {
		e.preventDefault();
		moveDispatch(date);
		onRequestClose(e);
	};

	const handleDateChange = (value) => {
		setDate(value);
	};

	return (
		<Modal isOpen={isOpen} onRequestClose={onRequestClose} maxWidth="20.9375rem" disableOverflow>
			<S.Wrapper>
				<S.ActionsWrapper>
					<IconButton icon={['fal', 'times']} size={1.25} className="icon close-icon" onClick={onRequestClose} />
				</S.ActionsWrapper>
				<S.Form onSubmit={onSubmit}>
					<S.Header>
						<Typography tag="h6">{`Move Dispatch-${dispatch?.dispatchNumber}`}</Typography>
						<hr />
					</S.Header>
					<S.FormRow>
						<DateInput id="date" selected={date} onChange={handleDateChange} label="Date" containerClassName="select-date-field" size="medium" placeholder="Select Date" />
					</S.FormRow>
					<S.ButtonRow>
						<Button type="submit" variation="secondary" className="submit-button">
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading...' : 'Move'}
							</Typography>
						</Button>
					</S.ButtonRow>
				</S.Form>
			</S.Wrapper>
		</Modal>
	);
};

export default MoveDispatchModal;

MoveDispatchModal.displayName = 'MoveDispatchModal';
MoveDispatchModal.propTypes = {
	dispatch: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	targetDate: PropTypes.string.isRequired,
	moveDispatch: PropTypes.func.isRequired,
};
