import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	tbody {
		&:before {
			content: "@";
			display: block;
			line-height: 1.5rem;
			text-indent: -99999px;
		}

	}

	tr {
		border: none !important;
		box-shadow: ${colors.lightShadow};
	}

	td {
		border: 1px solid ${colors.neutralForm};
		width: 100%;
		background-color: #fff;
	}

	.table-card {
		box-shadow: none;
		background-color: transparent;
	}

	.date-table-cell, .dispatch-id-table-cell, .status-table-cell {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1rem;
	}

	.task-description-table-cell, .contact-table-cell, .tags-table-cell {
		display: flex;
		align-items: center;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.dispatch-id-table-cell {
		font-size: initial;
		font-weight: normal;
	}

	.status-table-cell {
		width: 100%;

		svg {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			height: 1.25rem;
			width: 3rem;
		}

		p {
			width: 6rem;
			text-align: left;
		}
	}

	.tags-table-cell {
		.tags-icon-wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			color: ${colors.brandSecondary};
			white-space: nowrap;

			svg {
				height: 1rem;
			}


			> * {
				margin-left: 1rem;
				margin-top: .25rem;
			}
		}

		.tag-pill {
			border: 2px solid ${colors.brandSecondary};
			border-radius: 500vh;
			display: inline-block;
			color: ${colors.brandSecondary};
			padding: .05rem .5rem;
			margin-right: .25rem;
			margin-top: .25rem;
			font-size: .75rem;
		}

		.tags-wrapper {
			overflow: scroll;
			-ms-overflow-style: none;  /* IE and Edge */
  			scrollbar-width: none;  /* Firefox */
		}

		.tags-wrapper::-webkit-scrollbar {
			display: none;
		}

		> * {
			flex-grow: 1;
		}
	}
}
`;
