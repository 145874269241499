import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';

import { Button, TagPill, Typography } from '../../../components';
import * as S from './WorkerTaskCard.styles';

const WorkerTaskCard = ({ task, handleTaskStatus }) => {
	const nextStatus = () => {
		switch (task.status) {
			case 'not_started':
				return 'in_progress';
			case 'in_progress':
				return 'complete';
			default:
				return 'not_started';
		}
	};

	const renderButtonText = () => {
		switch (task.status) {
			case 'not_started':
				return 'Start';
			case 'in_progress':
				return 'Complete';
			default:
				return 'Start';
		}
	};

	return (
		<S.Wrapper status={task.status}>
			<S.Content>
				{task.content}
			</S.Content>
			{task.location && (
				<S.InfoWrapper>
					<FontAwesomeIcon icon={['fal', 'map-marker-alt']} className="form-icon" />
					<Typography tag="p">{task.location}</Typography>
				</S.InfoWrapper>
			)}
			{task.notes && (
				<S.InfoWrapper>
					<NotesRoundedIcon className="notes-icon form-icon" />
					<Typography tag="p">{task.notes}</Typography>
				</S.InfoWrapper>
			)}

			{task.tags && task.tags.length > 0
			&& (
				<S.InfoWrapper>
					<FontAwesomeIcon icon={['fal', 'tag']} className="form-icon" />
					<S.TagWrapper>
						{[...task.tags].sort((a, b) => new Date(a.createdAt.iso).getTime() - new Date(b.createdAt.iso)).map((tag) => <TagPill key={tag.id} name={tag.title} />)}
					</S.TagWrapper>
				</S.InfoWrapper>
			)}
			{task.status !== 'complete' && (
				<S.ActionsWrapper>
					<Button variation="secondary" size="small" onClick={() => handleTaskStatus(task, nextStatus())}>{renderButtonText()}</Button>
				</S.ActionsWrapper>
			)}
		</S.Wrapper>
	);
};

export default WorkerTaskCard;
WorkerTaskCard.displayName = 'WorkerTaskCard';
WorkerTaskCard.propTypes = {
	task: PropTypes.object,
	handleTaskStatus: PropTypes.func,
};
