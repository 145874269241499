import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

export const Modal = forwardRef(({
	children, maxWidth = '72rem', fullHeight, disableOverflow, noOverlay, topMargin, ...rest
}, ref) => (
	<ReactModal
		ref={ref}
		closeTimeoutMS={200}
		{...rest}
		style={{
			overlay: { backgroundColor: noOverlay ? 'transparent !important' : 'rgba(0, 0, 0, 0.5)' },
			content: {
				maxWidth, height: fullHeight ? '100%' : 'auto', overflow: disableOverflow ? 'visible !important' : 'auto', marginTop: topMargin || '0'
			}
		}}
	>
		{children}
	</ReactModal>
));

Modal.displayName = 'Modal';
Modal.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	fullHeight: PropTypes.bool,
	disableOverflow: PropTypes.bool,
	noOverlay: PropTypes.bool,
	maxWidth: PropTypes.string,
	topMargin: PropTypes.string,
};
