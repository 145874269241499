import api from '../api';

export const fetchDispatches = (data) => api.post('/core/functions/fetchDispatches', data);

export const fetchDispatch = (data) => api.post('/core/functions/fetchDispatch', data);

export const createUpdateDispatch = (data) => api.post('/core/functions/createUpdateDispatch', data);

export const duplicateDispatch = (data) => api.post('/core/functions/duplicateDispatch', data);

export const deleteDispatch = (data) => api.post('/core/functions/deleteDispatch', data);

export const moveDispatch = (data) => api.post('/core/functions/moveDispatch', data);

export const sendDispatch = (data) => api.post('/core/functions/sendDispatch', data);

export const addTaskToDispatch = (data) => api.post('/core/functions/addTaskToDispatch', data);

export const removeTaskFromDispatch = (data) => api.post('/core/functions/removeTaskFromDispatch', data);
