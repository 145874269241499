import React from 'react';
import moment from 'moment';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { selectAccordion } from '../../../../app/slices/dashboard/dashboardSlice';
import {
	moveDispatch, asyncMoveDispatch
} from '../../../../app/slices/dispatches/dispatchesSlice';

import * as S from './DispatchDropZoneContainer.styles';

const DispatchDropZoneContainer = ({ children, date }) => {
	const isBacklogOpen = useSelector((state) => state.dashboard.isBacklogOpen);
	const reduxDispatch = useDispatch();

	const handleChangeTargetDate = (dispatch) => {
		reduxDispatch(asyncMoveDispatch({ dispatchId: dispatch.id, targetDate: date }));
		reduxDispatch(selectAccordion(date));
		reduxDispatch(moveDispatch({ dispatchId: dispatch.id, targetDate: date })).catch(() => {
			const targetDate = dispatch?.targetDate?.iso ? moment(dispatch.targetDate.iso, 'YYYY-MM-DD').format('MM-DD-YYYY') : dispatch.targetDate;
			asyncMoveDispatch({ dispatchId: dispatch.id, targetDate });
			reduxDispatch(selectAccordion(targetDate));
		});
	};

	const [{ isOver, canDrop }, drop] = useDrop(
		() => ({
			accept: 'dispatch',
			drop: (dispatch) => handleChangeTargetDate(dispatch),
			canDrop(dispatch) {
				return date !== moment(dispatch.targetDate.iso, 'YYYY-MM-DD').format('MM-DD-YYYY');
			},
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
				canDrop: !!monitor.canDrop()
			})
		}),
		[]
	);

	return (
		<S.Wrapper ref={drop} isBacklogOpen={isBacklogOpen}>
			{children}
			{isOver && canDrop && <S.DispatchPlaceholder />}
		</S.Wrapper>

	);


};

export default DispatchDropZoneContainer;

DispatchDropZoneContainer.displayName = 'DispatchDropZoneContainer';
DispatchDropZoneContainer.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	date: PropTypes.string
};
