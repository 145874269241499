import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Card, Typography, Button } from '../../../../components';
import * as S from './OverviewCard.styles';

const OverviewCard = ({ title, editAction, children }) => (
	<Card>
		<S.HeaderWrapper>
			<Typography tag="h3" weight="bold">{title}</Typography>
			{editAction && (
				<Button variant="text" onClick={editAction}>
					<FontAwesomeIcon icon={['fal', 'edit']} />
					<Typography variation="button-medium" weight="bold">
						Edit
					</Typography>
				</Button>
			)}
		</S.HeaderWrapper>
		<S.ContentWrapper>
			{children}
		</S.ContentWrapper>
	</Card>
);

export default OverviewCard;

OverviewCard.propTypes = {
	title: PropTypes.string.isRequired,
	editAction: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
