import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROLES } from '../../../constants/general.constants';

const ROUTER_ROLES = [ROLES.FOREMAN, ROLES.ADMIN, ROLES.OWNER];

export const UserRoute = ({ component: Component, path, exact = false }) => {
	const user = useSelector((state) => state.user.value);
	const status = useSelector((state) => state.user.status);
	const userHasRequiredRole = user != null && ROUTER_ROLES.includes(user?.role);

	const render = (props) => (user && userHasRequiredRole ? (
		<Component {...props} />
	) : (
		<Redirect
			to={{
				pathname: '/logout',
			}}
		/>
	));

	if (status === 'idle') {
		return null;
	}

	return <Route exact={exact} path={path} render={render} />;
};

UserRoute.propTypes = {
	component: PropTypes.func.isRequired,
	path: PropTypes.string,
	exact: PropTypes.bool,
};
