import { createSlice } from '@reduxjs/toolkit';

export const menuSlice = createSlice({
	name: 'menu',
	initialState: {
		opened: false,
		collapsed: false,
	},
	reducers: {
		openMenu: (state) => {
			state.opened = true;
		},
		closeMenu: (state) => {
			state.opened = false;
		},
		toggleOpenedMenu: (state) => {
			state.opened = !state.opened;
		},
		toggleCollapsedMenu: (state) => {
			state.collapsed = !state.collapsed;
		},
		clearMenu: (state) => {
			state.opened = false;
			state.collapsed = false;
		}
	},
});

export const {
	openMenu, closeMenu, toggleOpenedMenu, toggleCollapsedMenu, clearMenu
} = menuSlice.actions;

export default menuSlice.reducer;
