import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	background-color: white;
	padding: 0.75rem 1.25rem;
	column-gap: 1.25rem;
	border-bottom: 1px solid ${colors.neutralForm};

	grid-template-areas:
		'back-button'
		'title'
		'subtitle'
		'actions';

	@media screen and (min-width: 992px) {
		grid-template-areas:
			'back-button back-button'
			'title       actions'
			'subtitle    subtitle';
	}

	.page-header {
		&__back-button-wrapper {
			grid-area: back-button;
			margin: 0 -0.625rem 0.25rem;
		}

		&__title-wrapper {
			grid-area: title;
		}

		&__subtitle-wrapper {
			grid-area: subtitle;
			margin-bottom: 1rem;
			margin-top: 0.25rem;

			@media screen and (min-width: 992px) {
				margin-bottom: 0;
			}
		}

		&__actions-wrapper {
			grid-area: actions;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;
			margin: -0.375rem;

			.icon-button {
				color: ${colors.brandPrimary};
			}

			& > * {
				margin: 0.375rem;
			}
		}

		&__back-button {
			letter-spacing: 0.75px;

			svg {
				font-size: 0.75rem;
			}

			span {
				margin-left: 0.5rem;
			}
		}
	}
`;
