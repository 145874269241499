import React, { useState, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
	addContact, fetchContacts
} from '../../app/slices/contacts/contactsSlice';
import { errorHandler } from '../../services/api';
import {
	uploadMedia
} from '../../app/slices/user/userSlice';

import {
	Card, UserNavigation, PageHeader, Pagination, ProfileModal, DebouncedSearch, ScrollableContainer, EmptyComponent
} from '../../components';
import { ContactTable } from './ContactTable';
import * as S from './ContactDirectory.styles';

import { usePaywall } from '../../hooks/usePaywall';
import { shouldShowPaywall } from '../../utils/should-show-paywall';

import { useUserPermissions } from '../../hooks/useUserPermissions';

const ContactDirectory = () => {
	const [isContactModalOpen, setIsContactModalOpen] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [page, setPage] = useState(0);
	const [sort, setSort] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const dispatch = useDispatch();
	const { showPaywall, Paywall } = usePaywall();
	const { hasAdminAccess } = useUserPermissions();
	const { value: contacts, status: contactsStatus, totalCount: contactsTotal } = useSelector((state) => state.contacts);
	const user = useSelector((state) => state.user.value);
	const pageSize = 10;

	useEffect(() => {
		dispatch(fetchContacts({ limit: pageSize, page: 0 }));
		if (shouldShowPaywall(user)) {
			showPaywall();
		}
	}, []);

	const handleCloseContactModal = () => {
		setIsContactModalOpen(false);
	};

	const handleSearch = (value) => {
		setPage(0);
		setSearchValue(value);
		dispatch(fetchContacts({
			searchText: value, limit: pageSize, page: 0, sortDirection: sort?.direction, sortId: sort?.id
		}));
	};

	const handleFormSubmit = (data, reset) => {
		const { avatar } = data;
		setIsSubmitting(true);
		if (avatar) {
			dispatch(uploadMedia(avatar)).then((res) => {
				const payload = res?.payload;
				dispatch(addContact({ ...data, profileImageObj: payload?.data })).then(() => {
					setIsSubmitting(false);
					setIsContactModalOpen(false);
					reset();
				}).catch((err) => errorHandler(err));
			});
		} else {
			dispatch(addContact(data)).then(() => {
				setIsSubmitting(false);
				setIsContactModalOpen(false);
				reset();
			}).catch((err) => errorHandler(err));
		}
	};

	const handlePageChange = useCallback((newPageNum) => {
		setPage(newPageNum);

		if (searchValue) {
			dispatch(fetchContacts({
				limit: pageSize, page: newPageNum, searchText: searchValue, sortDirection: sort?.direction, sortId: sort?.id
			}));
		} else {
			dispatch(fetchContacts({
				limit: pageSize, page: newPageNum, sortDirection: sort?.direction, sortId: sort?.id
			}));
		}
	}, [dispatch, searchValue, sort]);

	const handleSort = (value) => {
		let sortValue = { id: value[0].id, direction: value[0].desc ? 'desc' : 'asc' };

		if (value[0].id === 'firstName lastName') {
			sortValue = { ...sortValue, id: 'combinedName' };
		}

		if (!sort) {
			setSort(sortValue);
			dispatch(fetchContacts({
				limit: pageSize, page: 0, searchText: searchValue, sortDirection: sortValue.direction, sortId: sortValue.id
			}));
			setPage(0);
		} else if (sort) {
			if (sort.id !== sortValue.id || sort.direction !== sortValue.direction) {
				setSort(sortValue);
				dispatch(fetchContacts({
					limit: pageSize, page: 0, searchText: searchValue, sortDirection: sortValue.direction, sortId: sortValue.id
				}));
				setPage(0);
			}
		}
	};

	return (
		<UserNavigation>
			<PageHeader
				title="Contact Directory"
				action={hasAdminAccess ? [{
					id: 'contact-directory-header', onClick: () => setIsContactModalOpen(true), label: 'Add Contact', icon: ['fal', 'plus'], variation: 'secondary'
				}] : []}
			/>
			{hasAdminAccess ? (
				<>
					<S.Wrapper hasPagination={contactsTotal > pageSize}>
						<S.SearchWrapper>
							<DebouncedSearch handleSearch={handleSearch} />
						</S.SearchWrapper>
						<ScrollableContainer className="table-scrollable-container">
							<ContactTable contacts={contacts} handleSort={handleSort} loading={contactsStatus === 'loading'} />
						</ScrollableContainer>
						<Pagination
							onPageChange={(pageNumber) => handlePageChange(pageNumber)}
							totalCount={contactsTotal}
							currentPage={page}
							pageSize={pageSize}
						/>
					</S.Wrapper>
					<ProfileModal isOpen={isContactModalOpen} onRequestClose={handleCloseContactModal} submit={handleFormSubmit} isSubmitting={isSubmitting} type="contact" />
				</>
			) : (
				<S.EmptyWrapper>
					<Card className="empty-card">
						<EmptyComponent message="You do not have the proper permissions to access this page" icon={['fal', 'lock']} />
					</Card>
				</S.EmptyWrapper>
			) }

			<Paywall />
		</UserNavigation>
	);
};


export default ContactDirectory;
