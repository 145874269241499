import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Material Icons
import { SendRounded, ReplayRounded, MoreVertRounded } from '@mui/icons-material';

import * as S from './IconButton.styles';

const renderIcon = (icon) => {
	switch (icon) {
		case 'send-rounded':
			return <SendRounded className="send-icon" />;
		case 'replay':
			return <ReplayRounded className="replay-icon" />;
		case 'more-vert':
			return <MoreVertRounded className="more-vert-icon" />;
		default:
			return null;
	}
};

export const IconButton = forwardRef(({
	size = 1.75, icon, materialIcon, ...rest
}, ref) => (
	<S.Button ref={ref} size={size} {...rest}>
		{materialIcon ? renderIcon(materialIcon) : <FontAwesomeIcon icon={icon} />}
	</S.Button>
));

IconButton.displayName = 'IconButton';
IconButton.propTypes = {
	size: PropTypes.number,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	materialIcon: PropTypes.string,
};
