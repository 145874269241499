import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	BrowserRouter, Switch, Redirect
} from 'react-router-dom';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SocketContext, socket } from './context/socket';
import { PublicRoute, UserRoute, PublicAuthRoute } from './navigation/route-components';
import {
	LoginForm,
	SignUpForm,
	ForgotPasswordForm,
	ResetPasswordForm,
	Dashboard,
	TaskList,
	ContactDirectory,
	ContactDirectoryProfile,
	TeamMemberProfile,
	Account,
	ErrorPage,
	Logout,
	AcceptInviteForm,
	WorkerDashboard
} from './pages';
import { fetchUser } from './app/slices/user/userSlice';
import {
	Button, IconButton, Spinner, Typography
} from './components';

const Router = () => {
	const user = useSelector((state) => state.user.value);
	const status = useSelector((state) => state.user.status);
	const error = useSelector((state) => state.user.error);
	const dispatch = useDispatch();
	const shouldOpenUpgradeModal = window.location.href.includes('account');

	const handleUpgradeNav = () => {
		window.location.href = `${window.location.protocol}//${window.location.host}/dashboard/account?modal=upgrade`;
	};

	useEffect(() => {
		if (status === 'idle') {
			dispatch(fetchUser());
		}
		if (status === 'failed' && error) {
			toast.error(error);
		}

		if (user?.subscription?.tier === 'free' && user?.subscription?.daysUntilExpiration > 0 && !shouldOpenUpgradeModal) {
			toast(
				(t) => (
					<div className="toast-wrapper">
						<Typography tag="p" variation="2">
							You have only
							<b>{user?.subscription?.daysUntilExpiration}</b>
							days until your free trial ends
						</Typography>
						<div className="toast-actions-wrapper">
							<Button onClick={handleUpgradeNav} className="upgrade-button">Upgrade</Button>
							<IconButton onClick={() => toast.dismiss(t.id)} icon={['fal', 'times']} size={1.25} />
						</div>
					</div>
				),
				{
					icon: <FontAwesomeIcon icon={['fal', 'clock']} />,
					className: 'free-trial-toast',
					duration: 100000,
				}
			);
		}

		if (!user && !error) {
			toast.dismiss();
		}
	}, [user, error, status, dispatch]);

	return (
		<BrowserRouter>
			{status === 'loading' ? <Spinner /> : (
				<SocketContext.Provider value={socket(user?.organizationId)}>
					<Switch>
						<PublicAuthRoute exact path="/login" component={LoginForm} />
						<PublicAuthRoute exact path="/sign-up" component={SignUpForm} />

						<PublicRoute exact path="/forgot-password" component={ForgotPasswordForm} />
						<PublicRoute exact path="/reset-password/:aToken/:bToken" component={ResetPasswordForm} />

						<PublicRoute exact path="/invite/:aToken/:bToken" component={AcceptInviteForm} />
						<PublicRoute exact path="/d/:dispatchToken/:assigneeToken" component={WorkerDashboard} />

						<UserRoute exact path="/dashboard" component={Dashboard} />
						<UserRoute exact path="/dashboard/directory" component={ContactDirectory} />
						<UserRoute exact path="/dashboard/directory/:id" component={ContactDirectoryProfile} />
						<UserRoute exact path="/dashboard/team/:id" component={TeamMemberProfile} />
						<UserRoute exact path="/dashboard/account" component={Account} />
						<UserRoute exact path="/dashboard/job-archive" component={TaskList} />

						<PublicRoute exact path="/logout" component={Logout} />
						<PublicRoute exact path="/" component={() => <Redirect to="/login" />} />
						<PublicRoute component={ErrorPage} />
					</Switch>
				</SocketContext.Provider>
			)}
		</BrowserRouter>
	);
};

export default Router;
