import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch, useSelector } from 'react-redux';
import {
	updateUserProfile, uploadMedia, updateUser
} from '../../../app/slices/user/userSlice';
import {
	fetchTeam
} from '../../../app/slices/team/teamSlice';
import { beginPasswordReset } from '../../../services/authService';
import {
	updateSubscription
} from '../../../services/user/subscription/subscriptionService';
import { errorHandler } from '../../../services/api';

import { useQuery } from '../../../hooks/useQuery';
import { useUserPermissions } from '../../../hooks/useUserPermissions';

import { formatPhoneNumber } from '../../../utils/phone-formatter';
import {
	Button, PasswordModal, ProfileModal, BillingModal, PaymentModal, Typography
} from '../../../components';
import { OverviewCard } from './OverviewCard';
import * as S from './OverviewTab.styles';

const OverviewTab = () => {
	const shouldOpenUpgradeModal = useQuery('modal') === 'upgrade';
	const user = useSelector((state) => state.user.value);
	const { value: team } = useSelector((state) => state.team);
	const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
	const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
	const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
	const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmittingPasswordReset, setIsSubmittingPasswordReset] = useState(false);
	const [isEmailSent, setIsEmailSent] = useState(false);
	const dispatch = useDispatch();
	const { hasOwnerAccess } = useUserPermissions();
	const subscription = user?.subscription;
	const defaultPaymentMethod = subscription.default_payment_method ? user.paymentMethods.find((method) => user?.subscription?.default_payment_method === method.stripeId) : null;
	const hasPaymentMethods = user.paymentMethods && user.paymentMethods.length > 0;

	const handleOpenEditProfileModal = () => {
		setIsEditProfileModalOpen(true);
	};

	const handleOpenResetPasswordModal = () => {
		setIsEmailSent(false);
		setIsSubmittingPasswordReset(false);
		setIsResetPasswordModalOpen(true);
	};

	const handleOpenUpgradePlanModal = () => {
		setIsUpgradePlanModalOpen(true);
	};

	const handleOpenPaymentModal = () => {
		setIsPaymentModalOpen(true);
	};

	useEffect(() => {
		if (shouldOpenUpgradeModal) {
			handleOpenUpgradePlanModal();
		}
	}, []);

	// This useEffect is a setup as a patch for making sure admin counts are the same for the subscription
	// This is made to be a temporary patch
	useEffect(() => {
		if (!team || (team && team.length === 0)) {
			dispatch(fetchTeam());
		}

		if (team && team.length > 0) {
			let adminCount = 0;
			team.forEach((member) => {
				if (member && member.role === 'admin') {
					adminCount += 1;
				}
			});

			if (adminCount !== subscription.adminsUsed) {
				updateSubscription({
					adminsUsed: adminCount,
					adminOnly: true
				}).then((res) => {
					if (res.data.result.success) {
						dispatch(updateUser(res.data.result.user));
					}
				}).catch((err) => {
					errorHandler(err);
				});
			}
		}
	}, [defaultPaymentMethod, dispatch, subscription, team]);

	const handleFormSubmit = (data) => {
		const { avatar } = data;
		setIsSubmitting(true);
		if (avatar) {
			dispatch(uploadMedia(avatar)).then((res) => {
				const payload = res?.payload;
				dispatch(updateUserProfile({ ...data, profileImageObj: payload?.data, contactId: data.id })).catch((err) => errorHandler(err));
			});
		} else {
			dispatch(updateUserProfile({ ...data, profileImageObj: user.profileImage, contactId: data.id })).catch((err) => errorHandler(err));
		}
	};

	const handleResetPassword = () => {
		setIsSubmittingPasswordReset(true);
		beginPasswordReset({ email: user.email }).then(() => {
			setIsEmailSent(true);
			setIsSubmittingPasswordReset(false);
		}).catch((err) => errorHandler(err));
	};

	return (
		<S.Wrapper>
			<OverviewCard title="Profile" editAction={handleOpenEditProfileModal}>
				<S.ProfileInfoWrapper>
					<S.InfoWrapper>
						<Typography tag="p" variation="2" weight="bold" className="info-label">
							Name
						</Typography>
						<Typography tag="p" variation="2" className="info">
							{`${user.firstName} ${user.lastName}`}
						</Typography>
					</S.InfoWrapper>
					<S.InfoWrapper>
						<Typography tag="p" variation="2" weight="bold" className="info-label">
							Phone Number
						</Typography>
						<Typography tag="p" variation="2" className="info">
							{user.phone ? formatPhoneNumber(user.phone) : '---'}
						</Typography>
					</S.InfoWrapper>
					<S.InfoWrapper>
						<Typography tag="p" variation="2" weight="bold" className="info-label">
							Permissions
						</Typography>
						<Typography tag="p" variation="2" className="info permissions-text">
							{user.role === 'owner' ? 'Owner' : user.role}
						</Typography>
					</S.InfoWrapper>
				</S.ProfileInfoWrapper>
			</OverviewCard>
			<OverviewCard title="Account">
				<S.AccountInfoWrapper>
					<S.ActionInfoWrapper>
						<S.InfoWrapper>
							<Typography tag="p" variation="2" weight="bold" className="info-label">
								Email
							</Typography>
							<Typography tag="p" variation="2" className="info">
								{user.email}
							</Typography>
						</S.InfoWrapper>
						<Button variant="text" onClick={handleOpenEditProfileModal}>
							<FontAwesomeIcon icon={['fal', 'edit']} />
							<Typography variation="button-medium" weight="bold">
								Edit
							</Typography>
						</Button>
					</S.ActionInfoWrapper>
					<S.ActionInfoWrapper>
						<S.InfoWrapper>
							<Typography tag="p" variation="2" weight="bold" className="info-label">
								Password
							</Typography>
							<Typography tag="p" variation="2" className="info">
								*************
							</Typography>
						</S.InfoWrapper>
						<Button variant="text" onClick={handleOpenResetPasswordModal}>
							<FontAwesomeIcon icon={['fal', 'undo']} />
							<Typography variation="button-medium" weight="bold">
								Reset Password
							</Typography>
						</Button>
					</S.ActionInfoWrapper>
				</S.AccountInfoWrapper>
			</OverviewCard>
			{hasOwnerAccess && (
				<OverviewCard title="Billing">
					<S.BillingInfoWrapper>
						<S.ActionInfoWrapper>
							<S.InfoWrapper>
								<Typography tag="p" variation="2" weight="bold" className="info-label">
									Plan Type
								</Typography>
								<Typography tag="p" variation="2" className="info plan-type">
									{`${subscription.tier === 'free' ? 'Free Trial' : subscription.tier}`}
								</Typography>
							</S.InfoWrapper>
							<Button variant="text" onClick={handleOpenUpgradePlanModal}>
								<FontAwesomeIcon icon={['fal', 'plus']} />
								<Typography variation="button-medium" weight="bold">
									Upgrade Plan
								</Typography>
							</Button>
						</S.ActionInfoWrapper>
						<S.InfoWrapper>
							<Typography tag="p" variation="2" weight="bold" className="info-label">
								Plan Usage
							</Typography>
							<S.PlanUsageRow>
								<S.PlanUsageInfoWrapper>
									<FontAwesomeIcon icon={['fal', 'user']} />
									<Typography tag="p" variation="2">
										{`${subscription.adminsUsed}/${subscription.adminsLimit} Admins`}
									</Typography>
								</S.PlanUsageInfoWrapper>
								<S.PlanUsageInfoWrapper>
									<FontAwesomeIcon icon={['fal', 'comment']} />
									<Typography tag="p" variation="2">
										{`${subscription.dispatchesUsed}/${subscription.dispatchesLimit} Dispatches`}
									</Typography>
								</S.PlanUsageInfoWrapper>
							</S.PlanUsageRow>
						</S.InfoWrapper>
						{subscription.tier !== 'free' && (
							<>
								<hr />
								<S.ActionInfoWrapper>
									<S.InfoWrapper>
										<Typography tag="p" variation="2" weight="bold" className="info-label">
											Payment Method
										</Typography>
										<Typography tag="p" variation="2" className="info payment-method">
											{`${defaultPaymentMethod ? `${defaultPaymentMethod.card.brand} **** **** **** ${defaultPaymentMethod.card.last4}` : 'Default method not selected'}`}
										</Typography>
									</S.InfoWrapper>
									<Button variant="text" onClick={handleOpenPaymentModal}>
										<FontAwesomeIcon icon={['fal', `${hasPaymentMethods ? 'edit' : 'plus'}`]} />
										<Typography variation="button-medium" weight="bold">
											{`${hasPaymentMethods ? 'Edit' : 'Add'} Payment`}
										</Typography>
									</Button>
								</S.ActionInfoWrapper>
							</>
						)}
					</S.BillingInfoWrapper>
				</OverviewCard>
			)}
			<ProfileModal user={user} type="profile" isOpen={isEditProfileModalOpen} onRequestClose={() => setIsEditProfileModalOpen(false)} submit={handleFormSubmit} isSubmitting={isSubmitting} />
			<PasswordModal isOpen={isResetPasswordModalOpen} onRequestClose={() => setIsResetPasswordModalOpen(false)} submit={handleResetPassword} isSubmitting={isSubmittingPasswordReset} isEmailSent={isEmailSent} />
			{hasOwnerAccess && <BillingModal isOpen={isUpgradePlanModalOpen} onRequestClose={() => setIsUpgradePlanModalOpen(false)} />}
			{hasOwnerAccess && <PaymentModal isOpen={isPaymentModalOpen} onRequestClose={() => setIsPaymentModalOpen(false)} />}
		</S.Wrapper>
	);
};

export default OverviewTab;
