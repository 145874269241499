import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
	fetchTags as getTags, createUpdateTag, removeTag as deleteTag
} from '../../../services/tags/tagService';
import { errorHandler } from '../../../services/api';
import { getCookie } from '../../../utils/cookie-methods';

export const fetchTags = createAsyncThunk('tags/fetchTags', async () => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await getTags();
		if (response?.data?.result?.tags) {
			return response.data.result.tags;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const addTag = createAsyncThunk('tags/addTag', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await createUpdateTag(data);
		if (response?.data?.result?.tag) {
			return response.data.result.tag;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const removeTag = createAsyncThunk('tags/removeTag', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await deleteTag(data);
		if (response?.data?.result?.tag) {
			return response.data.result.tag;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const tagsSlice = createSlice({
	name: 'tags',
	initialState: {
		status: 'idle',
		error: null,
		value: null,
		tagStatus: 'idle'
	},
	reducers: {
		addTagSocket: (state, action) => {
			state.value = [...state.value, action.payload];
		},
		deleteTagSocket: (state, action) => {
			state.tagStatus = 'succeeded';
			const tagsArr = state.value;
			tagsArr.splice(tagsArr.findIndex((i) => i.id === action.payload.id), 1);
			state.value = tagsArr;
		},
		clearTags: (state) => {
			state.status = 'idle';
			state.error = null;
			state.value = null;
			state.tagStatus = 'idle';
		}
	},
	extraReducers: {
		[fetchTags.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchTags.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.value = action.payload;
		},
		[fetchTags.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
		[addTag.pending]: (state) => {
			state.tagStatus = 'loading';
		},
		[addTag.fulfilled]: (state, action) => {
			state.tagStatus = 'succeeded';
			state.value = [...state.value, action.payload];
		},
		[addTag.rejected]: (state, action) => {
			state.tagStatus = 'failed';
			state.error = action.error.message;
		},
		[removeTag.pending]: (state) => {
			state.tagStatus = 'loading';
		},
		[removeTag.fulfilled]: (state, action) => {
			state.tagStatus = 'succeeded';
			const tagsArr = state.value;
			tagsArr.splice(tagsArr.findIndex((i) => i.id === action.payload.id), 1);
			state.value = tagsArr;
		},
		[removeTag.rejected]: (state, action) => {
			state.tagStatus = 'failed';
			state.error = action.error.message;
		},
	},
});

export const {
	addTagSocket, deleteTagSocket, clearTags
} = tagsSlice.actions;

export default tagsSlice.reducer;
