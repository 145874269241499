import React, {
	useState, useEffect, useCallback
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { useDispatch, useSelector } from 'react-redux';
import {
	duplicateDispatch, deleteDispatch, moveDispatch, addDispatchTask, deleteDispatchTask, updateDispatchTask, updateDispatch, sendDispatch, asyncMoveDispatch
} from '../../../app/slices/dispatches/dispatchesSlice';
import {
	removeTaskFromBacklog, moveTask
} from '../../../app/slices/tasks/tasksSlice';
import { errorHandler } from '../../../services/api';

import {
	Typography, IconButton, Button, Popover, Select, Loader
} from '../../../components';
import { TaskList } from '../TaskList';
import { MoveDispatchModal } from '../MoveDispatchModal';
import { DeleteDispatchModal } from './DeleteDispatchModal';
import * as S from './DispatchCard.styles';

import { useUserPermissions } from '../../../hooks/useUserPermissions';

const DispatchCard = ({ dispatch, index }) => {
	const [moveDispatchModalIsOpen, setMoveDispatchModalIsOpen] = useState(false);
	const [sendingDispatch, setSendingDispatch] = useState(false);
	const [allTasksSent, setAllTasksSent] = useState(false);
	const [allTasksComplete, setAllTasksComplete] = useState(false);
	const [contactOptions, setContactOptions] = useState([]);
	const [activeContacts, setActiveContacts] = useState([]);
	const [isDeleteDispatchModalOpen, setIsDeleteDispatchModalOpen] = useState(false);
	const { value: contacts } = useSelector((state) => state.contacts);
	const reduxDispatch = useDispatch();
	const { hasAdminAccess } = useUserPermissions();

	useEffect(() => {
		const assigneeArr = [...dispatch.assignees].map((assignee) => ({ value: assignee.id, label: `${assignee.firstName} ${assignee.lastName}` }));
		const contactArr = [...contacts].map((contact) => ({ value: contact.id, label: `${contact.firstName} ${contact.lastName}` }));
		setContactOptions(contactArr);
		setActiveContacts(assigneeArr.sort((a, b) => (a.label !== b.label ? a.label < b.label ? -1 : 1 : 0)));
		setAllTasksSent(dispatch.tasks && dispatch.tasks.length > 0 && dispatch.tasks.every((task) => task.isSent));
		setAllTasksComplete(dispatch.tasks && dispatch.tasks.length > 0 && dispatch.tasks.every((task) => task.status === 'complete'));
	}, [dispatch, contacts]);

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	const [{ opacity }, drag] = useDrag(
		() => ({
			type: 'dispatch',
			item: { ...dispatch, index },
			collect: (monitor) => ({
				opacity: monitor.isDragging() ? 0.5 : 1
			}),
		}),
		[]
	);

	const handleMoveTask = useCallback(
		(task) => {
			if (dispatch.id && task.placement === 'backlog') {
				reduxDispatch(addDispatchTask({ ...task, dispatchId: dispatch.id, placement: 'dispatch' }));
				reduxDispatch(removeTaskFromBacklog(task));
				reduxDispatch(moveTask({
					taskId: task.id, targetPlacement: 'dispatch', position: 0, targetDispatchId: dispatch.id
				})).then((res) => {
					const payload = res?.payload;
					reduxDispatch(updateDispatchTask(payload));
				}).catch(() => errorHandler());

			} else if (task.dispatchId !== dispatch.id) {
				reduxDispatch(addDispatchTask({ ...task, dispatchId: dispatch.id }));
				reduxDispatch(deleteDispatchTask({ ...task, dispatchId: task.dispatchId }));
				reduxDispatch(moveTask({
					taskId: task.id, targetPlacement: 'dispatch', position: 0, targetDispatchId: dispatch.id, previousDispatchId: task.dispatchId
				})).then((res) => {
					const payload = res?.payload;
					reduxDispatch(updateDispatchTask(payload));
				}).catch(() => errorHandler());
			}
		},
		[dispatch.id, reduxDispatch],
	);

	const [{ isOver }, drop] = useDrop(
		() => ({
			accept: 'task',
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
			}),
			drop: (item) => {
				handleMoveTask(item);
			}
		}),
		[]
	);

	const handleDuplicateDispatch = () => {
		reduxDispatch(duplicateDispatch({ dispatchId: dispatch.id }));
	};

	const handleDeleteDispatch = (action) => {
		reduxDispatch(deleteDispatch({ dispatchId: dispatch.id, shouldDeleteTasks: action === 'delete' }));
	};

	const handleChangeTargetDate = (targetDate) => {
		let date = moment().format('MM-DD-YYYY');
		if (targetDate === 'tomorrow') {
			date = moment(dispatch.targetDate.iso, 'YYYY-MM-DD').add(1, 'd').format('MM-DD-YYYY');
		} else {
			date = moment(targetDate, 'YYYY-MM-DD').format('MM-DD-YYYY');
		}

		reduxDispatch(asyncMoveDispatch({ dispatchId: dispatch.id, targetDate: date }));
		reduxDispatch(moveDispatch({ dispatchId: dispatch.id, targetDate: date })).catch(() => {
			asyncMoveDispatch({ dispatchId: dispatch.id, targetDate: dispatch?.targetDate?.iso ? moment(dispatch.targetDate.iso, 'YYYY-MM-DD').format('MM-DD-YYYY') : dispatch.targetDate });
		});
	};

	let actions = [
		{
			text: 'Delete Dispatch',
			onClick: dispatch.tasks && dispatch.tasks.length > 0 ? () => setIsDeleteDispatchModalOpen(true) : () => handleDeleteDispatch()
		},
		{
			text: 'Duplicate Dispatch',
			onClick: () => handleDuplicateDispatch()
		},
		{
			text: 'View Live Link',
			onClick: () => window.open(`/d/${dispatch.token}/${dispatch.assignees[0].token}?view=admin`, '_blank')
		},
		{
			text: 'Move to Different Date',
			onClick: () => setMoveDispatchModalIsOpen(true)
		},
		{
			text: 'Move to Tomorrow',
			onClick: () => handleChangeTargetDate('tomorrow')
		}
	];

	if (!dispatch.tasks.length > 0 || !dispatch.assignees.length > 0) {
		actions = actions.filter((action) => action.text !== 'View Live Link');
	}

	const handleSelectContact = (value) => {
		const assigneeIds = value.map((v) => v.value);
		setActiveContacts(value);
		reduxDispatch(updateDispatch({
			dispatchId: dispatch.id, targetDate: moment(dispatch.targetDate.iso, 'YYYY-MM-DD').format('MM-DD-YYYY'), assigneeIds, position: dispatch.order
		}))
			.then(() => {
				setActiveContacts(value);
			}).catch(() => setActiveContacts([...activeContacts]));
	};

	const handleSendDispatch = (isResend) => {
		setSendingDispatch(true);
		reduxDispatch(sendDispatch({ dispatchId: dispatch.id, isResend })).then(() => {
			setSendingDispatch(false);
		}).catch(() => {
			setSendingDispatch(false);
		});
	};

	const handleAddPlaceholderTask = (e) => {
		e.stopPropagation();
		reduxDispatch(addDispatchTask({
			id: `temp-id-${Math.round(Math.random() * (100000 - 2) + 2)}`, content: '', tags: [], placement: 'dispatch', dispatchId: dispatch.id,
		}));
	};

	return (
		<S.Wrapper ref={hasAdminAccess ? drag : null} style={{ opacity }}>
			<div ref={dispatch.tasks.length === 0 && hasAdminAccess ? drop : null}>
				<S.SendWrapper allTasksSent={allTasksSent && dispatch.isSent} allTasksComplete={allTasksComplete}>
					<S.ContactWrapper>
						{allTasksSent && dispatch.isSent && activeContacts.length > 0 ? <Typography tag="p" variation="2">{activeContacts.map((contact) => contact.label).join(', ')}</Typography> : hasAdminAccess ? (
							<>
								<Typography tag="p" variation="2">To:</Typography>
								<Select
									id="contacts"
									containerClassName="select-field"
									placeholder=""
									size="small"
									options={contactOptions}
									value={activeContacts}
									onChange={(val) => {
										handleSelectContact(val.map((c) => c));
									}}
									noOptionsMessage={({ inputValue }) => (!inputValue ? 'No contacts added yet' : 'No contacts found')}
									transparent
									isMulti
								/>
							</>
						) : activeContacts.length > 0 ? <Typography tag="p" variation="2">{activeContacts.map((contact) => contact.label).join(', ')}</Typography> : <Typography tag="p" variation="2">No Contacts</Typography>}
					</S.ContactWrapper>
					{hasAdminAccess && (
						<S.IconWrapper allTasksSent={allTasksSent && dispatch.isSent}>
							{activeContacts.length > 0 && sendingDispatch ? <Loader className="loader-icon" /> : allTasksSent && dispatch.isSent ? null : dispatch.hasBeenSent ? <IconButton className="replay-button-wrapper" materialIcon="replay" onClick={() => handleSendDispatch(true)} data-tip="Resend dispatch" /> : <IconButton className="send-button-wrapper" materialIcon="send-rounded" onClick={() => handleSendDispatch(false)} data-tip="Send dispatch" />}
							<Popover id="dispatch-card-popover" actions={actions}>
								<IconButton className="ellipsis" materialIcon="more-vert" />
							</Popover>
						</S.IconWrapper>
					)}
				</S.SendWrapper>
				<S.DispatchInfoWrapper allTasksComplete={allTasksComplete}>
					<Typography tag="p" variation="3" className="dispatch-id-text">{`dispatch-${dispatch.dispatchNumber}`}</Typography>
					<Typography tag="p" variation="3" className="dispatch-tasks-text">{`${dispatch.tasks.length || 0} Jobs`}</Typography>
				</S.DispatchInfoWrapper>
				<S.TaskListWrapper>
					<TaskList tasks={dispatch.tasks} dispatch={dispatch} emptyIsOver={isOver} />
				</S.TaskListWrapper>
				{!allTasksSent && hasAdminAccess && (
					<S.AddTaskWrapper>
						<Button
							variant="text"
							className="add-task-button"
							onClick={(e) => setTimeout(handleAddPlaceholderTask(e))}
						>
							<FontAwesomeIcon icon={['fal', 'plus']} className="add-task-icon" />
							Add Job
						</Button>
					</S.AddTaskWrapper>
				)}
				<MoveDispatchModal
					dispatch={dispatch}
					isOpen={moveDispatchModalIsOpen}
					onRequestClose={() => setMoveDispatchModalIsOpen(false)}
					targetDate={moment(dispatch.targetDate.iso, 'YYYY-MM-DD').format('MM-DD-YYYY')}
					moveDispatch={handleChangeTargetDate}
				/>
				<DeleteDispatchModal isOpen={isDeleteDispatchModalOpen} onRequestClose={() => setIsDeleteDispatchModalOpen(false)} handleDelete={handleDeleteDispatch} />
			</div>
		</S.Wrapper>
	);
};

export default DispatchCard;

DispatchCard.displayName = 'DispatchCard';
DispatchCard.propTypes = {
	dispatch: PropTypes.object,
	index: PropTypes.number
};
