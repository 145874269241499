import React from 'react';
import PropTypes from 'prop-types';

import { Navigation } from '..';

const links = [
	{
		id: 1, to: '/dashboard', label: 'Dashboard', icon: 'dashboard'
	},
	{
		id: 2, to: '/dashboard/job-archive', label: 'Job Archive', icon: 'job-archive'
	},
	{
		id: 3, to: '/dashboard/directory', label: 'Contact Directory', icon: 'contact'
	},
];

export const UserNavigation = ({ children }) => (
	<Navigation links={links} logoLink="/dashboard">
		{children}
	</Navigation>
);

UserNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
