// Polyfill IE
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Imports
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactModal from 'react-modal';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';

// Configuration
import { loadIcons } from './fonts/font-awesome-icon-loader';
import Router from './Router';
import GlobalStyles from './styles/global';
import store from './app/store';
import reportWebVitals from './reportWebVitals';
import { isTouchDevice } from './utils/touch-device';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';


ReactModal.setAppElement('#root');
loadIcons();

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<DndProvider backend={backendForDND}>
			<Provider store={store}>
				<ErrorBoundary>
					<GlobalStyles />
					<Router />
					<Toaster />
				</ErrorBoundary>
			</Provider>
		</DndProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
