import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
`;

export const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;

	.icon {
		color: ${colors.neutralText}
	}
	.close-icon {
		margin-left: .75rem;
	}
`;

export const Form = styled.form`
	label {
		font-weight: normal;
		font-size: .75rem;
		margin-bottom: .25rem;
	}
`;

export const FormRow = styled.div`
	margin-bottom: .5rem;

	.backlog-button {
		position: absolute;
		right: 0;
		margin-right: 1rem;
		font-weight: bold;
		marging: 0;
		padding: 0;
	}
`;

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 1.25rem;

	h6 {
		text-align: center;
		padding-bottom: .5rem;
	}
`;

export const ButtonRow = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: .75rem;
`;
