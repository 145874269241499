import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	place-items: center;
	height: inherit;
	align-content: center;
	padding: 0rem 2rem;
	color: ${colors.neutralText};

	.description {
		margin-top: 1rem;
	}

	.question-icon,
	.envelope-icon {
		display: block;
		align-self: center;
		margin-bottom: 2rem;
	}

	.envelope-icon {
		margin-bottom: 1rem;
	}

	@media screen and (min-width: 768px) {
		padding: 3.4375rem 1rem;
	}
`;
