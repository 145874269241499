import React from 'react';
import PropTypes from 'prop-types';

import { formatPhoneNumber } from '../../../utils/phone-formatter';
import {
	Avatar, Table, Link, EmptyComponent, Card
} from '../../../components';
import * as S from './ContactTable.styles';

const ContactTable = ({ contacts, handleSort, loading }) => {
	const columns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'firstName lastName',
				Cell: (data) => {
					const { row } = data;
					return (
						<div className="name-table-cell">
							<Link to={`/dashboard/directory/${row.original.id}`}>
								<Avatar avatar={row.original.profileImage['1500']} className="avatar" />
								<div className="name">{`${row.original.firstName} ${row.original.lastName}`}</div>
							</Link>
						</div>
					);
				},
			},
			{
				Header: 'Title',
				accessor: 'title',
				Cell: (data) => {
					const { value } = data;
					return (
						<div className="title-table-cell">
							{value || '---'}
						</div>
					);
				},
			},
			{
				Header: 'Phone',
				accessor: 'phone',
				Cell: (data) => {
					const { value } = data;
					return (
						<div className="phone-table-cell">
							{formatPhoneNumber(value)}
						</div>
					);
				},
			},
			{
				Header: 'Email',
				accessor: 'email',
				width: 150,
				Cell: (data) => {
					const { value } = data;
					return (
						<div className="email-table-cell">
							{value || '---'}
						</div>
					);
				},
			},
		],
		[]
	);

	return (
		<S.Wrapper>
			{contacts && contacts.length > 0 ? <Table columns={columns} data={contacts} onSort={handleSort} loading={loading} hasManualSort /> : <Card><EmptyComponent message="The contact directory is currently empty" icon={['fal', 'users']} /></Card>}
		</S.Wrapper>

	);
};

export default ContactTable;

ContactTable.displayName = 'ContactTable';
ContactTable.propTypes = {
	contacts: PropTypes.array,
	handleSort: PropTypes.func,
	loading: PropTypes.bool,
};
