import styled from 'styled-components';

// import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-top: 3rem;

	h5 {
		margin-bottom: 1rem;
	}

	.add-payment-button {
		padding: 0;
		margin-top: .75rem;

		svg {
			height: 1rem;
		}
	}
`;

export const PaymentMethodWrapper = styled.div`
	width: 100%;
`;
