import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div``;

export const EmptyWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5rem;

	.empty-card {
		width: 50%;
	}
`;

export const Content = styled.div``;

export const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.5rem;
	border-bottom: 1px solid ${colors.neutralForm};
	box-shadow: ${colors.lightShadow};
	background-color: white;

	h6 {
		color: ${colors.neutralText};
	}
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const AdminWrapper = styled.div`
	p {
		background-color: ${colors.neutralForm};
		border-radius: 500vh;
		padding: .25rem .75rem;
		color: ${colors.neutralText};
	}
`;

export const TaskWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 1.125rem;
	padding: 1.125rem 1rem;
	background-color: ${colors.neutralBackground};

	@media screen and (max-width: 992px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;
