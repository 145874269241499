import styled, { css } from 'styled-components';

import colors from '../../styles/colors';
import { Typography } from '../Typography';
import { Button } from '../Button';

export const Card = styled.div`
	background-color: white;
	box-shadow: ${colors.lightShadow};
	padding: 0.625rem 1.25rem;
	overflow: hidden;
`;

export const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: 0.375rem;
	margin-bottom: 0.625rem;
	border-bottom: 1px solid ${({ transparentHeaderBorder }) => (transparentHeaderBorder ? 'transparent' : colors.neutralForm)};
`;

export const Title = styled(Typography).attrs({ tag: 'h3', weight: 'bold', truncate: true })`
	flex: 1;
`;

export const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;

	& > * + * {
		margin-left: 0.3125rem;
	}
`;

const BUTTON_VARIATIONS = {
	text: css`
		&:last-child {
			margin-right: -0.625rem;
		}
	`,
};
export const ActionButton = styled(Button)`
	${({ variant }) => css`
		${BUTTON_VARIATIONS[variant]}
	`}
`;
