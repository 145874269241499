import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	padding: 1rem;
	z-index: 2;

	.close-icon {
		margin-left: .75rem;
	}
`;

export const StepperWrapper = styled.div`
	display: absolute;
	margin-top: -3rem;
	padding-bottom: .5rem;
	border-bottom: 1px solid ${colors.neutralForm};
	width: 100%;
`;

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1rem;

	h2, p {
		margin-bottom: .5rem;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
	width: 100%;
`;

export const PricingTierContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;

	> div {
		margin: .5rem;
	}
`;

export const BillingFormWrapper = styled.div`
	width: 50%;

	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

export const FooterActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 1rem;

	button {
		margin: 0 .5rem;
	}
`;
