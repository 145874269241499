import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	box-shadow: ${colors.mediumShadow};
	padding: 1rem;
	background-color: ${({ status }) => (status === 'in_progress' ? colors.statePending : status === 'complete' ? colors.stateComplete : 'white')};
	min-height: 7rem;
`;

export const Content = styled.div`
	padding-bottom: .5rem;
	border-bottom: 3px solid ${colors.brandSecondary};
	margin-bottom: 1rem;
`;

export const InfoWrapper = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: 1rem;

	svg {
		color: ${colors.brandSecondary};
		margin-right: 1rem;
		width: 1rem;
	}

	.notes-icon {
		transform: scale(1.5);
	}
`;

export const TagWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;

export const ActionsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex: 1;
`;
