import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
`;

export const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;

	.close-icon {
		margin-left: .75rem;
	}
`;

export const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;

	h2 {
		text-transform: capitalize;
	}

	p {
		margin: .75rem 0;
	}
`;

export const Form = styled.form`
	width: 50%;

	@media screen and (max-width: 768px) {
		width: 100%;
	}

	@media screen and (max-width: 992px) {
		min-width: 75%;
	}
`;

export const AvatarWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 1rem 0;
`;

export const FormRow = styled.div`
	display: flex;
	margin-bottom: .75rem;
	width: 100%;

	.select-field {
		flex: 1;
	}

	& > div {
		width: 100% !important;
	}

	.permissions-select {
		label {
			text-transform: uppercase;
			font-weight: bold;
		}
	}
`;

export const DetailWrapper = styled.div`
	display: flex;
	flex-direction: column;
	color: ${colors.neutralText};
	margin-bottom: 1.5rem;
	margin-left: 2.5rem;
`;

export const DetailRow = styled.div`
	display: flex;
	align-items: center;

	span {
		margin-right: .25rem;
	}
`;

export const ButtonRow = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1.75rem;

	button {
		margin: .5rem;
	}
`;
