import styled from 'styled-components';

export const HeaderWrapper = styled.div`
	display: flex;
	align-item: center;
	justify-content: space-between;
	margin-bottom: 1.25rem;

	button {
		padding-right: 0;
	}
`;

export const ContentWrapper = styled.div`

`;
