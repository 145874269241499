import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AddCommentOutlined, UploadFileOutlined } from '@mui/icons-material';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { DebouncedSearch } from '../DebouncedSearch';
import * as S from './PageHeader.styles';

// Material Icons

const renderIcon = (icon) => {
	switch (icon) {
		case 'add-comment':
			return <AddCommentOutlined className="icon" />;
		case 'upload-file':
			return <UploadFileOutlined className="icon" />;
		default:
			return null;
	}
};

export const PageHeader = ({
	title, action = [], handleSearch, loading, searchPlaceholder
}) => (
	<S.Wrapper className="page-header">
		<S.LeftWrapper>
			<Typography tag="h2" weight="bold">
				{title}
			</Typography>
			{handleSearch && (
				<S.SearchWrapper>
					<DebouncedSearch handleSearch={handleSearch} placeholder={searchPlaceholder} />
				</S.SearchWrapper>
			)}
		</S.LeftWrapper>
		{!!action.length && (
			<S.ButtonWrapper>
				{action.map((item) => (
					<Button key={item.id} onClick={item.onClick} variant={item.type} variation={item.variation} size={item.size} disabled={loading}>
						{Array.isArray(item?.icon) ? <FontAwesomeIcon icon={item.icon} /> : renderIcon(item.icon)}
						<Typography tag="span" variation={item.type === 'text' ? '1' : '2'} weight="bold">
							{item.label}
						</Typography>
					</Button>
				))}
			</S.ButtonWrapper>
		)}
	</S.Wrapper>
);

PageHeader.propTypes = {
	title: PropTypes.string,
	action: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			onClick: PropTypes.func,
			label: PropTypes.string,
			icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]) || PropTypes.string,
			type: PropTypes.oneOf(['solid', 'outline', 'text']),
			variation: PropTypes.oneOf(['default', 'secondary', 'warning']),
			size: PropTypes.oneOf(['medium', 'small']),
		})
	),
	handleSearch: PropTypes.func,
	loading: PropTypes.bool,
	searchPlaceholder: PropTypes.string,
};
