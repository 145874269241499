import { COOKIE_DOMAIN } from '../../constants/general.constants';

export function setCookie(name, value, days = 365) {
	let expires = '';
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = `; expires=${date.toUTCString()}`;
	}
	const cookieDomain = (process.env.REACT_APP_ENV === 'development') ? '' : `domain=${COOKIE_DOMAIN()};`;
	document.cookie = `${name}=${value || ''}${expires}; path=/;${cookieDomain}`;
}

export function getCookie(cname) {
	const name = `${cname}=`;
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i += 1) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

export function removeCookie(cname) {
	const cookieDomain = (process.env.REACT_APP_ENV === 'development') ? '' : `domain=${COOKIE_DOMAIN()};`;
	document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ${cookieDomain}`;
}
