import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './Checkbox.styles';

export const Checkbox = forwardRef(({
	id, label, containerClassName, ...rest
}, ref) => (
	<S.Wrapper className={containerClassName}>
		<S.CheckboxWrapper>
			<S.Checkbox id={id} ref={ref} {...rest} />
			<FontAwesomeIcon className="checkmark" icon={['fal', 'check']} />
			{label && <label htmlFor={id}>{label}</label>}
		</S.CheckboxWrapper>
	</S.Wrapper>
));

Checkbox.displayName = 'Checkbox';
Checkbox.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	containerClassName: PropTypes.string,
};
