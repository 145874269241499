import styled from 'styled-components';
import ReactSlider from 'react-slider';

import colors from '../../styles/colors';

export const Container = styled.div`
	label {
		color: ${colors.neutralText};
	}
`;

export const Wrapper = styled.div`
	position: relative;

	.floating-top {
		position: absolute;
		top: -1.75rem;
	}
	.floating-bottom {
		position: absolute;
		bottom: 0.75rem;

		&.left {
			left: -0.5rem;
		}
		&.right {
			right: -0.5rem;
		}
	}
`;

export const Slider = styled(ReactSlider)`
	width: 100%;
	height: 5rem;
	display: flex;
	align-items: center;
`;

export const Thumb = styled.div`
	position: relative;
	height: 1.625rem;
	line-height: 1.625rem;
	width: 1.625rem;
	text-align: center;
	background-color: ${colors.brandPrimary};
	box-shadow: ${colors.darkShadow};
	border-radius: 50%;
	cursor: grab;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Track = styled.div`
	height: 0.375rem;
	background-color: ${({ index }) => (index === 0 ? colors.brandPrimary : colors.neutralForm)};
	border-radius: 0.375rem;
`;
