// COPYRIGHT DISPATCHR

/**
 * Roles
 */

exports.ROLES = {
	FOREMAN: 'foreman',
	ADMIN: 'admin',
	OWNER: 'owner'
};

/**
 * Subscription Tiers
 */

exports.SUBSCRIPTION_TIERS = {
	FREE: 'free',
	STANDARD: 'standard',
	PRO: 'pro',
	ENTERPRISE: 'enterprise'
};

/**
 * Default Subscription Limits
 */

exports.SUBSCRIPTION_LIMITS = {
	[exports.SUBSCRIPTION_TIERS.FREE]: {
		admins: 1,
		dispatches: 200
	},
	[exports.SUBSCRIPTION_TIERS.STANDARD]: {
		admins: 1,
		dispatches: 1000
	},
	[exports.SUBSCRIPTION_TIERS.PRO]: {
		admins: 3,
		dispatches: 3000
	},
};

/**
 * Task Status Options
 */

exports.TASK_STATUS_OPTIONS = {
	NOT_STARTED: 'not_started',
	IN_PROGRESS: 'in_progress',
	COMPLETE: 'complete'
};

/**
 * General
 */

exports.DEFAULT_TIMEZONE = 'America/Indiana/Indianapolis';
exports.PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
exports.META_TITLE = 'Dispatchr';

/**
 * Cookies
 */

exports.SESSION_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
exports.SESSION_TOKEN_COOKIE = 'e_s_token';

/**
 * Cookie Domain
 */

exports.COOKIE_DOMAIN = () => {
	switch (process.env.ENV) {
		case 'development':
			return null;
		case 'qa':
			return process.env.APP_URL.replace(/(^\w+:|^)\/\//, '');
		case 'staging':
			return process.env.APP_URL.replace(/(^\w+:|^)\/\//, '');
		case 'production':
			return process.env.APP_URL.replace(/(^\w+:|^)\/\//, '');
		default:
			return null;
	}
};

/**
 * File Upload Limits (Bytes)
 */

exports.FILE_UPLOAD_LIMITS = {
	PROFILE_MEDIA: 10000000, // 10 MB
	RESOURCE_MEDIA: 10000000, // 10 MB
};

/**
 * Acceptable File Types
 */

exports.ACCEPTABLE_FILE_TYPES = {
	PROFILE_MEDIA: {
		EXTENSIONS: [
			'png',
			'jpeg',
			'jpg'
		],
		MIME_TYPES: [
			'image/png',
			'image/jpeg',
			'image/jpg'
		]
	},
	RESOURCE_MEDIA: {
		EXTENSIONS: [
			'png',
			'jpeg',
			'jpg',
			'pdf'
		],
		MIME_TYPES: [
			'image/png',
			'image/jpeg',
			'image/jpg',
			'application/pdf'
		]
	},
};

/**
 * State Options
 */

exports.STATE_ABBREVIATIONS = [
	'AL',
	'AK',
	'AS',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DE',
	'DC',
	'FM',
	'FL',
	'GA',
	'GU',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MH',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'MP',
	'OH',
	'OK',
	'OR',
	'PW',
	'PA',
	'PR',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VI',
	'VA',
	'WA',
	'WV',
	'WI',
	'WY'
];

exports.STATE_OPTIONS = [
	{
		name: 'Alabama',
		abbreviation: 'AL'
	},
	{
		name: 'Alaska',
		abbreviation: 'AK'
	},
	{
		name: 'American Samoa',
		abbreviation: 'AS'
	},
	{
		name: 'Arizona',
		abbreviation: 'AZ'
	},
	{
		name: 'Arkansas',
		abbreviation: 'AR'
	},
	{
		name: 'California',
		abbreviation: 'CA'
	},
	{
		name: 'Colorado',
		abbreviation: 'CO'
	},
	{
		name: 'Connecticut',
		abbreviation: 'CT'
	},
	{
		name: 'Delaware',
		abbreviation: 'DE'
	},
	{
		name: 'District Of Columbia',
		abbreviation: 'DC'
	},
	{
		name: 'Federated States Of Micronesia',
		abbreviation: 'FM'
	},
	{
		name: 'Florida',
		abbreviation: 'FL'
	},
	{
		name: 'Georgia',
		abbreviation: 'GA'
	},
	{
		name: 'Guam',
		abbreviation: 'GU'
	},
	{
		name: 'Hawaii',
		abbreviation: 'HI'
	},
	{
		name: 'Idaho',
		abbreviation: 'ID'
	},
	{
		name: 'Illinois',
		abbreviation: 'IL'
	},
	{
		name: 'Indiana',
		abbreviation: 'IN'
	},
	{
		name: 'Iowa',
		abbreviation: 'IA'
	},
	{
		name: 'Kansas',
		abbreviation: 'KS'
	},
	{
		name: 'Kentucky',
		abbreviation: 'KY'
	},
	{
		name: 'Louisiana',
		abbreviation: 'LA'
	},
	{
		name: 'Maine',
		abbreviation: 'ME'
	},
	{
		name: 'Marshall Islands',
		abbreviation: 'MH'
	},
	{
		name: 'Maryland',
		abbreviation: 'MD'
	},
	{
		name: 'Massachusetts',
		abbreviation: 'MA'
	},
	{
		name: 'Michigan',
		abbreviation: 'MI'
	},
	{
		name: 'Minnesota',
		abbreviation: 'MN'
	},
	{
		name: 'Mississippi',
		abbreviation: 'MS'
	},
	{
		name: 'Missouri',
		abbreviation: 'MO'
	},
	{
		name: 'Montana',
		abbreviation: 'MT'
	},
	{
		name: 'Nebraska',
		abbreviation: 'NE'
	},
	{
		name: 'Nevada',
		abbreviation: 'NV'
	},
	{
		name: 'New Hampshire',
		abbreviation: 'NH'
	},
	{
		name: 'New Jersey',
		abbreviation: 'NJ'
	},
	{
		name: 'New Mexico',
		abbreviation: 'NM'
	},
	{
		name: 'New York',
		abbreviation: 'NY'
	},
	{
		name: 'North Carolina',
		abbreviation: 'NC'
	},
	{
		name: 'North Dakota',
		abbreviation: 'ND'
	},
	{
		name: 'Northern Mariana Islands',
		abbreviation: 'MP'
	},
	{
		name: 'Ohio',
		abbreviation: 'OH'
	},
	{
		name: 'Oklahoma',
		abbreviation: 'OK'
	},
	{
		name: 'Oregon',
		abbreviation: 'OR'
	},
	{
		name: 'Palau',
		abbreviation: 'PW'
	},
	{
		name: 'Pennsylvania',
		abbreviation: 'PA'
	},
	{
		name: 'Puerto Rico',
		abbreviation: 'PR'
	},
	{
		name: 'Rhode Island',
		abbreviation: 'RI'
	},
	{
		name: 'South Carolina',
		abbreviation: 'SC'
	},
	{
		name: 'South Dakota',
		abbreviation: 'SD'
	},
	{
		name: 'Tennessee',
		abbreviation: 'TN'
	},
	{
		name: 'Texas',
		abbreviation: 'TX'
	},
	{
		name: 'Utah',
		abbreviation: 'UT'
	},
	{
		name: 'Vermont',
		abbreviation: 'VT'
	},
	{
		name: 'Virgin Islands',
		abbreviation: 'VI'
	},
	{
		name: 'Virginia',
		abbreviation: 'VA'
	},
	{
		name: 'Washington',
		abbreviation: 'WA'
	},
	{
		name: 'West Virginia',
		abbreviation: 'WV'
	},
	{
		name: 'Wisconsin',
		abbreviation: 'WI'
	},
	{
		name: 'Wyoming',
		abbreviation: 'WY'
	}
];

/**
 * Days of Week
 */

exports.DAYS_OF_WEEK = [
	'sun',
	'mon',
	'tue',
	'wed',
	'thu',
	'fri',
	'sat'
];


/**
 * Sort Options
 */

exports.CONTACT_SORT_OPTIONS = [
	{ value: 'name', label: 'Name' },
	{ value: 'title', label: 'Title' },
	{ value: 'phone', label: 'Phone' },
	{ value: 'email', label: 'Email' }
];

exports.TEAM_SORT_OPTIONS = [
	{ value: 'name', label: 'Name' },
	{ value: 'permissions', label: 'Permissions' },
	{ value: 'phone', label: 'Phone' },
	{ value: 'email', label: 'Email' }
];

exports.DISPATCH_SORT_OPTIONS = [
	{ value: 'targetDate', label: 'Date' },
	{ value: 'number', label: 'Number' },
	{ value: 'taskDescription', label: 'Task Description' },
	{ value: 'assignees', label: 'Assignees' },
	{ value: 'tags', label: 'Tags' }
];


/**
 * Socket Actions
 */

exports.SOCKET_ACTIONS = {
	CREATE_CONTACT: 'create_contact',
	UPDATE_CONTACT: 'update_contact',
	DELETE_CONTACT: 'delete_contact',
	CREATE_TAG: 'create_tag',
	UPDATE_TAG: 'update_tag',
	DELETE_TAG: 'delete_tag',
	CREATE_TEAM_USER: 'create_team_user',
	UPDATE_TEAM_USER: 'update_team_user',
	DELETE_TEAM_USER: 'delete_team_user',
	CREATE_DISPATCH: 'create_dispatch',
	UPDATE_DISPATCH: 'update_dispatch',
	DELETE_DISPATCH: 'delete_dispatch',
	MOVE_DISPATCH: 'move_dispatch',
	SEND_DISPATCH: 'send_dispatch',
	DUPLICATE_DISPATCH: 'duplicate_dispatch',
	CREATE_TASK: 'create_task',
	UPDATE_TASK: 'update_task',
	DELETE_TASK: 'delete_task',
	MOVE_TASK: 'move_task',
	ADD_TASK_TO_DISPATCH: 'add_task_to_dispatch',
	REMOVE_TASK_FROM_DISPATCH: 'remove_task_from_dispatch',
	UPDATE_TASK_STATUS: 'update_task_status',
	DUPLICATE_TASK: 'duplicate_task',
};
