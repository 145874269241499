import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { IconButton as ReactIconButton } from '../IconButton';
import colors from '../../styles/colors';

export const IconButton = styled(ReactIconButton)`
	position: absolute;
	padding: inherit;
	top: 1rem;
	right: 1.25rem;
	z-index: 1;

	svg {
		stroke: white;
		stroke-width: 3rem;

		path {
			fill: ${({ bookmarked }) => (bookmarked ? colors.brandPrimary : 'transparent')};
		}
	}
`;

export const InfoBox = styled.div`
	overflow: hidden;
`;

const LIST_BASE_STYLES = css`
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 992px) {
		flex-direction: row;
		align-items: center;

		.directory-card {
			&__thumbnail {
				margin-right: 0.75rem;
				min-width: 6rem;
				max-width: 6rem;
			}
		}

		${IconButton} {
			top: 0.25rem;
			right: 0;

			svg {
				stroke: ${colors.neutralButton};
			}
		}
	}
`;

const VARIATION_STYLES = {
	grid: css`
		display: flex;
		flex-direction: column;
	`,
	list: css`
		${LIST_BASE_STYLES}

		.directory-card {
			&__type {
				@media screen and (min-width: 992px) {
					margin-top: 0;
				}
			}
		}
	`,
	'list-alt': css`
		${LIST_BASE_STYLES};
		${InfoBox} {
			align-self: flex-start;
		}
		@media screen and (min-width: 992px) {
			.directory-card {
				&__thumbnail {
					min-width: 11.125rem;
					max-width: 11.125rem;
				}
			}
		}
	`,
};

export const DirectoryCard = styled(Link)`
	position: relative;
	text-decoration: none;

	.directory-card {
		&__thumbnail {
			border: 1px solid ${colors.neutralForm};
			border-radius: 0.375rem;
		}
		&__type {
			margin-top: 0.5rem;
			color: ${colors.neutralText};
		}
		&__title {
			margin-top: 0.5rem;
			color: black;
		}
		&__location {
			margin-top: 0.25rem;
			color: black;
		}
	}

	${({ variation }) => VARIATION_STYLES[variation]}
`;
