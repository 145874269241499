import { useSelector } from 'react-redux';
import { ROLES } from '../../constants/general.constants';

// Hook for handingling user permissions conditional
export const useUserPermissions = (isBacklog) => {
	const user = useSelector((state) => state.user.value);
	const hasAdminAccess = user?.role === ROLES.ADMIN || user?.role === ROLES.OWNER;
	const hasOwnerAccess = user?.role === ROLES.OWNER;
	const canDrop = !!(hasAdminAccess || (!hasAdminAccess && isBacklog));

	return { hasAdminAccess, hasOwnerAccess, canDrop };
};
