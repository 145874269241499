import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: inline-flex;
`;

export const Radio = styled.input.attrs({ type: 'radio' })`
	display: flex;
	appearance: none;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 9999px;
	border: 1px solid ${colors.neutralForm};
	width: 1.5625rem;
	height: 1.5625rem;
	padding: 0.3125rem;

	&::after {
		display: none;
		content: '';
		background-color: ${colors.brandPrimary};
		border-radius: 9999px;
		width: 100%;
		height: 100%;
	}

	&:checked {
		border-color: ${colors.brandPrimary};

		&::after {
			display: block;
		}
	}
`;
