import React, { forwardRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import * as S from './PhoneTextInput.styles';

export const PhoneTextInput = forwardRef(({
	id, label, error, containerClassName, icon, ...rest
}, ref) => (
	<S.Wrapper error={error} className={containerClassName}>
		{label && (
			<Typography tag="label" weight="bold" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null}>
				{label}
			</Typography>
		)}
		<PhoneInput
			ref={ref}
			country="us"
			placeholder=""
			{...rest}
		/>
		{error && error.message && (
			<S.FloatingWrapper title={error.message}>
				<Typography tag="p" variation="2">
					{error.message}
				</Typography>
			</S.FloatingWrapper>
		)}
	</S.Wrapper>
));

PhoneTextInput.displayName = 'PhoneTexInput';
PhoneTextInput.propTypes = {
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	containerClassName: PropTypes.string,
	id: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.shape(),
};
