import React from 'react';
import PropTypes from 'prop-types';

import { PRICING_PLANS } from '../../../constants/pricingPlans';

import { Typography } from '../..';
import * as S from './ReviewPlan.styles';

export const ReviewPlan = ({
	user, address, selectedTier, selectedMethod,
}) => {
	const paymentMethod = user.paymentMethods.find((method) => method.id === selectedMethod);
	return (
		<S.Wrapper>
			<Typography tag="h5" weight="bold">Plan Type</Typography>
			<S.InfoWrapper>
				<S.LeftContent>
					<Typography tag="p" className="selected-tier">{selectedTier}</Typography>
					<Typography tag="p">
						{`${PRICING_PLANS[selectedTier].admins} Admin`}
					</Typography>
					<Typography tag="p">{`${PRICING_PLANS[selectedTier].dispatches} Dispatches`}</Typography>
				</S.LeftContent>
				<S.RightContent>
					<Typography tag="p" weight="semibold">{`$${PRICING_PLANS[selectedTier].price} per month`}</Typography>
				</S.RightContent>
			</S.InfoWrapper>
			<hr />
			<Typography tag="h5" weight="bold">Billing Address</Typography>
			<S.InfoWrapper>
				<S.LeftContent>
					<Typography tag="p">
						{address.street}
					</Typography>
					<Typography tag="p">{`${address.city}, ${address.state} ${address.postalCode}`}</Typography>
				</S.LeftContent>
			</S.InfoWrapper>
			<hr />
			<Typography tag="h5" weight="bold">Payment</Typography>
			<S.InfoWrapper>
				<S.LeftContent>
					<Typography tag="p" className="payment-method">
						{`${paymentMethod.card.brand} Ending **** **** **** ${paymentMethod.card.last4}`}
					</Typography>
				</S.LeftContent>
			</S.InfoWrapper>
			<hr />
			<S.TotalWrapper>
				<Typography tag="h5" weight="bold">Total</Typography>
				<Typography tag="h3" weight="semibold">
					{`$${PRICING_PLANS[selectedTier].price}`}
				</Typography>
			</S.TotalWrapper>
		</S.Wrapper>
	);
};

ReviewPlan.displayName = 'ReviewPlan';
ReviewPlan.propTypes = {
	user: PropTypes.object,
	address: PropTypes.object,
	selectedMethod: PropTypes.string,
	selectedTier: PropTypes.string,
};
