import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as S from './ScrollableContainer.styles';

export const ScrollableContainer = forwardRef(({
	children, ...rest
}, ref) => (
	<S.Container ref={ref} {...rest}>
		{children}
	</S.Container>
));

ScrollableContainer.displayName = 'ScrollableContainer';
ScrollableContainer.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
