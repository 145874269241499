import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	border: '1px solid #008f68';
	background-color: #fff;

	opacity: ${({ isOver }) => (isOver ? '.5' : 1)};
`;

export const IconLabelWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	border-top: ${({ isAccordionSelected }) => (isAccordionSelected ? `1px solid ${colors.brandSecondary}` : 'none')};
	border-bottom: 1px solid ${({ isAccordionSelected }) => (isAccordionSelected ? colors.brandSecondary : colors.neutralForm)};
	border-right: 1px solid ${colors.neutralForm};
	border-left: 1px solid ${({ isAccordionSelected }) => (isAccordionSelected ? colors.brandSecondary : colors.neutralForm)};

	h5 {
		color: ${({ isAccordionSelected }) => (isAccordionSelected ? `${colors.brandSecondary}` : 'initial')};
	}
`;

export const LabelWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding: .75rem .75rem .75rem 0;
	cursor: pointer;

	.indicator-icon {
		height: .625rem;
		color: ${colors.stateSuccess};
		margin-left: .75rem;
	}

	.dispatch-count {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 1.25rem;
		width: 1.25rem;
		background-color: ${colors.brandSecondary};
		border-radius: 500vh;
		color: white;
		margin-left: .4rem;
	}

	&:hover {
		h5 {
			opacity: .6;
		}
	}
`;

export const IconWrapper = styled.div`
	padding: .75rem 0 .75rem .75rem;
	margin-right: .5rem;

	svg {
		height: 1.5rem !important;
		color: ${colors.neutralText};
		-webkit-animation: fadeIn 1s;
       	-moz-animation: fadeIn 1s;
        -ms-animation: fadeIn 1s;
        -o-animation: fadeIn 1s;
        animation: fadeIn 1s;
	}

	@keyframes fadeIn {
		0% { opacity: 0; }
		100% { opacity: 1; }
	}
`;

export const ContentWrapper = styled.div`
	max-height: 0px;
	overflow: hidden;
	opacity: 0;
	-webkit-transition: max-height .5s ease-in-out, opacity .5s ease-in-out;
	-moz-transition: max-height .5s ease-in-out, opacity .5s ease-in-out;
	-ms-transition: max-height .5s ease-in-out, opacity .5s ease-in-out;
	-o-transition: max-height .5s ease-in-out, opacity .5s ease-in-out;
	transition: max-height .5s ease-in-out, opacity .5s ease-in-out;

	${({ isOpen }) => (
		`${isOpen && `
			max-height: 4320px;
			opacity: 1;
			overflow: visible;
			-webkit-transition: max-height 1.5s ease-in-out, opacity 1.5s ease-in-out;
			-moz-transition: max-height 1.5s ease-in-out, opacity 1.5s ease-in-out;
			-ms-transition: max-height 1.5s ease-in-out, opacity 1.5s ease-in-out;
			-o-transition: max-height 1.5s ease-in-out, opacity 1.5s ease-in-out;
			transition: max-height 1.5s ease-in-out, opacity 1.5s ease-in-out;
		`}`
	)};
`;

export const Content = styled.div`
	@media screen and (max-width: 1200px) {
		grid-template-columns: 1fr 1fr;
	}
`;
