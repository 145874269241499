import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const LinkTitle = styled.h3`
	font-size: 0.5rem;
	line-height: 0.875rem;
	font-weight: 700;
	color: white;
	margin: 1rem 1rem 0.375rem 1rem;
`;

const COLLAPSED_STYLES = css`
	min-width: 55px;
	max-width: 55px;

	h3 {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	a {
		justify-content: center;

		svg {
			margin: 0;
		}

		.link-title {
			display: none;
		}
	}
`;


export const Sidebar = styled.aside`
	display: flex;
	flex-direction: column;
	width: 0%;
	max-width: 3.25rem;
	background-color: ${colors.brandPrimary};
	box-shadow: ${colors.darkShadow};
	overflow-x: auto;
	position: absolute;
	height: inherit;
	z-index: 3;
	transition: width 0.4s cubic-bezier(0.4, 0, 0.2, 1);

	padding-top: 2.8125rem;

	${({ opened }) => opened
		&& css`
			width: 100%;
		`}

	@media screen and (min-width: 768px) {
		width: 100%;
		min-width: 3.25rem;
		position: static;
		padding-top: 0;

		${({ collapsed }) => collapsed && COLLAPSED_STYLES}
	}

	@media screen and (max-width: 768px) {
		max-width: none;
	}
`;

export const Overlay = styled.div`
	position: absolute;
	inset: 0;
	background-color: ${colors.brandPrimary};
	display: none;
	cursor: pointer;
	z-index: 2;

	${({ opened }) => opened
		&& css`
			display: block;
		`}

	@media screen and (min-width: 768px) {
		display: none;
	}
`;

export const LinksWrapper = styled.nav`
	display: flex;
	flex-direction: column;
`;
