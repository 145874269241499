import styled from 'styled-components';

import colors from '../../../styles/colors';

export const LoadWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 1rem 0;

	.load-button {
		color: ${colors.brandSecondary};
		padding: 0;
	}
`;
