export const required = (label, message) => ({
	value: true,
	message: message || `${label} is required`,
});

export const pattern = (label, value, message) => ({
	value,
	message: message || `${label} is invalid`,
});

export const numberOnly = (label, message) => ({
	value: /^\d*$/,
	message: message || `${label} needs to be a number`,
});
