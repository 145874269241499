import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import {
	CardElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';

import { useDispatch } from 'react-redux';
import {
	updateUser
} from '../../../app/slices/user/userSlice';
import {
	addUpdatePaymentMethod
} from '../../../services/user/userService';
import { errorHandler } from '../../../services/api';


import { required } from '../../../utils/form-default-errors';
import {
	Button, TextInput, Typography
} from '../..';
import * as S from './PaymentForm.styles';
import colors from '../../../styles/colors';

export const PaymentForm = ({
	user, handleShowPaymentForm, handleSelectedMethod, address
}) => {
	const [cardholderName, setCardholderName] = useState();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const {
		register,
		formState: { errors },
	} = useForm();
	const dispatch = useDispatch();
	const stripe = useStripe();
	const elements = useElements();

	const CARD_ELEMENT_OPTIONS = {
		iconStyle: 'solid',
		hidePostalCode: true,
		style: {
			base: {
				iconColor: colors.brandSecondary,
				color: colors.neutralText,
				fontSize: '16px',
				fontFamily: '"Open Sans", sans-serif',
				fontSmoothing: 'antialiased',
				width: '100%',
				'::placeholder': {
					color: colors.neutralText,
				},
				':focus': {
					color: colors.brandPrimary,
				},
			},
			invalid: {
				color: colors.stateDanger,
				':focus': {
					color: colors.stateDanger
				}
			}
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsSubmitting(true);

		if (!stripe || !elements) {
			return;
		}

		const ownerInfo = {
			owner: {
				name: cardholderName,
				address: {
					line1: address.street,
					city: address.city,
					postal_code: address.postalCode,
					country: 'US',
				},
				email: user.email
			},
		};

		const result = await stripe.createSource(
			elements.getElement(CardElement),
			ownerInfo
		);

		if (result.error) {
			setIsSubmitting(false);
			errorHandler(result.error);
		} else {
			await addUpdatePaymentMethod({ source: result.source.id }).then((res) => {
				dispatch(updateUser(res.data.result.user));
				handleShowPaymentForm();
				handleSelectedMethod(res.data.result.method.id, res.data.result.user);
				setIsSubmitting(false);
			}).catch((err) => {
				setIsSubmitting(false);
				errorHandler(err);
			});
		}
	};

	const handleNameChange = (e) => {
		const { value } = e.target;
		setCardholderName(value);
	};

	return (
		<S.Wrapper>
			<Typography tag="h6" weight="bold">New Payment Method</Typography>
			<S.Form>
				<TextInput
					id="cardholderName"
					placeholder="Cardholder Name"
					error={errors.cardholderName}
					autoComplete="cardholderName"
					{...register('cardholderName', {
						required: required('Cardholder Name'),
					})}
					onChange={handleNameChange}
				/>
				<S.FormRow>
					<CardElement
						className="card"
						options={CARD_ELEMENT_OPTIONS}
					/>
				</S.FormRow>
				<S.ActionsWrapper>
					<Button variant="text" className="cancel-button" onClick={isSubmitting ? (e) => e.preventDefault() : handleShowPaymentForm}>
						<Typography variation="button-medium">
							Cancel
						</Typography>
					</Button>
					<Button variant="text" className="submit-button" onClick={isSubmitting ? (e) => e.preventDefault() : handleSubmit}>
						<Typography variation="button-medium" weight="bold">
							{isSubmitting ? 'Saving...' : 'Save'}
						</Typography>
					</Button>
				</S.ActionsWrapper>
			</S.Form>
		</S.Wrapper>
	);
};

PaymentForm.displayName = 'PaymentForm';
PaymentForm.propTypes = {
	user: PropTypes.object,
	handleShowPaymentForm: PropTypes.func,
	handleSelectedMethod: PropTypes.func,
	address: PropTypes.object
};
