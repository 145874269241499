import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import { Button } from '../Button';
import * as S from './MessageDialog.styles';

export const MessageDialog = forwardRef(({
	title, subtitle, icon, isOpen = false, onRequestClose, ...rest
}, ref) => (
	<ReactModal ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={onRequestClose} className="c-message-dialog" {...rest}>
		<S.Wrapper>
			{icon && (
				<S.IconWrapper>
					<div className="circle-wrapper">
						<FontAwesomeIcon icon={icon} size="4x" />
					</div>
				</S.IconWrapper>
			)}
			<S.InfoWrapper>
				<Typography tag="h2" weight="bold">
					{title}
				</Typography>
				<Typography tag="p">{subtitle}</Typography>
			</S.InfoWrapper>
			<S.ButtonWrapper>
				<Button onClick={onRequestClose}>
					<Typography weight="bold" variation="button-medium">
						Close
					</Typography>
				</Button>
			</S.ButtonWrapper>
		</S.Wrapper>
	</ReactModal>
));

MessageDialog.displayName = 'MessageDialog';
MessageDialog.propTypes = {
	title: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	subtitle: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
