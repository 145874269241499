import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import {
	updateUser
} from '../../app/slices/user/userSlice';
import {
	updateSubscription
} from '../../services/user/subscription/subscriptionService';
import { errorHandler } from '../../services/api';

import { BillingForm } from './BillingForm';
import { ReviewPlan } from './ReviewPlan';
import {
	Modal, IconButton, Stepper, Typography, Button, PricingTier, PaymentSelect
} from '..';
import * as S from './BillingModal.styles';

export const BillingModal = ({
	isOpen, onRequestClose
}) => {
	const user = useSelector((state) => state.user.value);
	const [activeStep, setActiveStep] = useState(0);
	const [selectedTier, setSelectedTier] = useState(user?.subscription?.tier || '');
	const [selectedMethod, setSelectedMethod] = useState(user.paymentMethods && user.paymentMethods.length > 0 ? user.paymentMethods.find((method) => user?.subscription?.default_payment_method === method.stripeId)?.id || '' : '');
	const [isUpgradingPlan, setIsUpgradingPlan] = useState(false);
	const [address, setAddress] = useState({
		street: user?.subscription?.billingAddress?.street || '', city: user?.subscription?.billingAddress?.city || '', state: user?.subscription?.billingAddress?.state || '', postalCode: user?.subscription?.billingAddress?.postalCode || ''
	});
	const [canGoNext, setCanGoNext] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (activeStep === 0 && selectedTier) {
			setCanGoNext(true);
		} else if (activeStep === 1 && Object.values(address).every((val) => val !== '') && selectedMethod) {
			setCanGoNext(true);
		} else if (activeStep === 2 || activeStep === 3) {
			setCanGoNext(true);
		} else {
			setCanGoNext(false);
		}
	}, [activeStep, selectedTier, address, selectedMethod]);

	const headerInfo = useMemo(() => [
		{
			title: 'Upgrade Plan',
			subtitle: 'Select plan below'
		},
		{
			title: 'Add Payment',
			subtitle: 'Enter billing info below'
		},
		{
			title: 'Review',
			subtitle: 'Review plan and billing info below'
		},
		{
			title: 'Plan Upgraded',
			subtitle: `Your plan has been upgraded to the ${selectedTier} plan. View updates on your account screen.`
		}
	], []);

	const handleActiveStep = (shouldGoBack) => {
		let currentStep = activeStep;

		if (shouldGoBack) {
			currentStep -= 1;
		} else {
			currentStep += 1;
		}

		setActiveStep(currentStep);
	};

	const handleModalClose = () => {
		onRequestClose();

		// Update URL to get rid of queries so modal doesn't continue to open
		window.history.replaceState(null, null, '/dashboard/account');

		// Clean up timeout for state
		setTimeout(() => {
			setActiveStep(0);
			setSelectedTier('');
			setAddress({
				street: user?.subscription?.billingAddress?.street || '', city: user?.subscription?.billingAddress?.city || '', state: user?.subscription?.billingAddress?.state || '', postalCode: user?.subscription?.billingAddress?.postalCode || ''
			});
		}, 500);
	};

	const handleSelectedTier = (type) => {
		setSelectedTier(type);
	};

	const handleUpgradePlan = async () => {
		setIsUpgradingPlan(true);

		await updateSubscription({
			tier: selectedTier || user?.subscription?.tier,
			billingStreet: address.street,
			billingCity: address.city,
			billingState: address.state,
			billingPostalCode: address.postalCode,
			paymentMethodId: selectedMethod
		}).then((res) => {
			if (res.data.result.success) {
				dispatch(updateUser(res.data.result.user));
				handleActiveStep(false);
				setIsUpgradingPlan(false);
			}
		}).catch((err) => {
			setIsUpgradingPlan(false);
			errorHandler(err);
		});
	};

	const handleAddressChange = (key, value) => {
		setAddress({ ...address, [key]: value });
	};

	const handleSelectedMethod = (e, updatedUser) => {
		if (e?.target && !updatedUser) {
			const { value } = e.target;
			setSelectedMethod(value);
		} else {
			const userPaymentMethods = [...updatedUser.paymentMethods];
			const methodIndex = userPaymentMethods.findIndex((method) => method.id === e);
			const methodId = userPaymentMethods[methodIndex].id;
			setSelectedMethod(methodId);
		}
	};

	const completedUpgrade = activeStep === 3;
	const userTier = user?.subscription?.tier;

	return (
		<Modal isOpen={isOpen} onRequestClose={handleModalClose} maxWidth="85rem">
			<S.Wrapper>
				<S.ActionsWrapper>
					<IconButton icon={['fal', 'times']} size={1.25} className="icon close-icon" onClick={() => handleModalClose()} disabled={isUpgradingPlan} />
				</S.ActionsWrapper>
				{!completedUpgrade && (
					<S.StepperWrapper>
						<Stepper activeStep={activeStep} steps={[{ id: 1, label: 'Plan' }, { id: 2, label: 'Billing' }, { id: 3, label: 'Review' }]} width={15} maxWidth={15} />
					</S.StepperWrapper>
				)}
				<S.HeaderWrapper>
					<Typography tag="h2" weight="bold">{headerInfo[activeStep].title}</Typography>
					<Typography tag="p">{headerInfo[activeStep].subtitle}</Typography>
				</S.HeaderWrapper>
				{!completedUpgrade && (
					<S.Content>
						{activeStep === 0
					&& (
						<S.PricingTierContainer>
							{user?.subscription?.tier === 'free' && <PricingTier title="Free Trial" price={0} frequency="30 day trial" services={[1, 200]} isCurrent={userTier === 'free'} handleSelectedTier={handleSelectedTier} isSelected={selectedTier === 'free'} type="free" />}
							<PricingTier title="Standard" price={35} frequency="monthly" services={[1, 1000]} isCurrent={userTier === 'standard'} handleSelectedTier={handleSelectedTier} isSelected={selectedTier === 'standard'} type="standard" />
							<PricingTier title="Pro" price={70} frequency="monthly" services={[3, 3000]} isCurrent={userTier === 'pro'} handleSelectedTier={handleSelectedTier} isSelected={selectedTier === 'pro'} type="pro" />
							<PricingTier title="Enterprise" price={0} isCustom services={['Unlimited', 'Unlimited']} isCurrent={userTier === 'enterprise'} handleSelectedTier={handleSelectedTier} />
						</S.PricingTierContainer>
					)}
						{activeStep === 1 && (
							<S.BillingFormWrapper>
								<BillingForm address={address} handleAddressChange={handleAddressChange} />
								<PaymentSelect address={address} selectedMethod={selectedMethod} handleSelectedMethod={handleSelectedMethod} canCreatePaymentMethod={Object.values(address).every((val) => val !== '')} />
							</S.BillingFormWrapper>
						)}
						{activeStep === 2 && (
							<ReviewPlan user={user} address={address} selectedTier={selectedTier} selectedMethod={selectedMethod} />
						)}
					</S.Content>
				) }
				<S.FooterActionsWrapper>
					{activeStep > 0 && (
						<Button variant="outline" onClick={completedUpgrade ? () => handleModalClose() : () => handleActiveStep(true)} disabled={isUpgradingPlan}>
							{completedUpgrade ? 'Close' : 'Back'}
						</Button>
					)}
					<Button onClick={completedUpgrade ? () => handleModalClose() : activeStep === 2 ? () => handleUpgradePlan() : () => handleActiveStep(false)} disabled={!canGoNext || isUpgradingPlan}>{completedUpgrade ? 'Complete' : activeStep === 2 ? `${isUpgradingPlan ? 'Upgrading' : 'Upgrade Plan'}` : 'Next'}</Button>
				</S.FooterActionsWrapper>
			</S.Wrapper>
		</Modal>
	);
};

BillingModal.displayName = 'BillingModal';
BillingModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
};
