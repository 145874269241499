import React, { forwardRef } from 'react';
import PropTypes, { string } from 'prop-types';
import { DashboardCustomizeRounded, ViewListRounded, PersonAddRounded } from '@mui/icons-material/';
import { Typography } from '../../..';
import * as S from './NavLink.styles';

const renderIcon = (icon) => {
	switch (icon) {
		case 'dashboard':
			return <DashboardCustomizeRounded className="nav-icon" />;
		case 'job-archive':
			return <ViewListRounded className="nav-icon" />;
		case 'contact':
			return <PersonAddRounded className="nav-icon" />;
		default:
			return null;
	}
};

export const NavLink = forwardRef(({
	children, to, icon, onClick = () => null
}, ref) => (
	<S.LinkWrapper>
		<S.NavLink ref={ref} to={to} exact onClick={onClick}>
			{renderIcon(icon)}
			<Typography tag="p" variation="3" className="link-title">
				{children}
			</Typography>
		</S.NavLink>
	</S.LinkWrapper>
));

NavLink.displayName = 'NavLink';
NavLink.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	to: PropTypes.string.isRequired,
	icon: string,
	onClick: PropTypes.func,
};
