import React, { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import * as S from './DateInput.styles';

export const DateInput = forwardRef(({
	id, label, error, containerClassName, icon, ...rest
}, ref) => {
	const [startDate, setStartDate] = useState(null);
	return (
		<S.Wrapper error={error} className={containerClassName}>
			{label && (
				<Typography tag="label" weight="bold" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null}>
					{label}
				</Typography>
			)}
			<DatePicker ref={ref} id={id} error={error} selected={startDate} onChange={(date) => setStartDate(date)} popperClassName="date-input-popper" dateFormat="EEE MMM d" {...rest} />
			<S.IconWrapper>
				<FontAwesomeIcon icon={['fal', 'calendar']} />
			</S.IconWrapper>
			{error && error.message && (
				<S.FloatingWrapper title={error.message}>
					<Typography tag="p" variation="2">
						{error.message}
					</Typography>
				</S.FloatingWrapper>
			)}
		</S.Wrapper>
	);
});

DateInput.displayName = 'DateInput';
DateInput.propTypes = {
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	containerClassName: PropTypes.string,
	size: PropTypes.oneOf(['small', 'medium']),
	id: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.shape(),
};
