
import styled from 'styled-components';
import { Typography as Text } from '../Typography';

import colors from '../../styles/colors';

export const Typography = styled(Text)`
	color: ${colors.neutralText};
	text-decoration: underline;

	&:hover {
		cursor: pointer;
		color: ${colors.neutralFormHover};
	}
`;
