import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, useFlexLayout, useSortBy } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Card, TableComponents, Typography, Spinner
} from '..';
import * as S from './Table.styles';

export const Table = ({
	columns, data, children, onSort, loading, hasManualSort
}) => {
	const {
		getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: { sortBy }
	} = useTable(
		{
			columns,
			data,
			manualSortBy: hasManualSort
		},
		useFlexLayout,
		useSortBy,
	);

	useEffect(() => {
		if (onSort && sortBy.length && sortBy.length > 0 && hasManualSort) {
			onSort(sortBy);
		}
	}, [onSort, sortBy, hasManualSort]);

	return (
		<S.Wrapper>
			<Card className="table-card fade-in">
				<TableComponents.TableWrapper>
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map(({ key, ...headerGroup }) => (
								<tr key={key} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(({ key: key2, ...column }) => (
										<th key={key2} {...column.getHeaderProps(column.getSortByToggleProps())} className="table-header">
											<Typography tag="h6" weight="semibold">
												{column.render('Header')}
											</Typography>
											{!column.disableSortBy && (
												<S.SortingWrapper isSortedDesc={column.isSorted && column.isSortedDesc} isSortedAsc={column.isSorted && !column.isSortedDesc}>
													{column.isSorted
														? column.isSortedDesc
															? <FontAwesomeIcon icon={['fas', 'caret-down']} className="sort-down-icon" />
															: <FontAwesomeIcon icon={['fas', 'caret-up']} className="sort-up-icon" />
														: (
															<>
																<FontAwesomeIcon icon={['fas', 'caret-up']} className="sort-up-icon" />
																<FontAwesomeIcon icon={['fas', 'caret-down']} className="sort-down-icon" />
															</>
														)}
												</S.SortingWrapper>
											)}
										</th>
									))}
								</tr>
							))}
						</thead>

						<tbody {...getTableBodyProps()}>
							{loading ? (
								<tr>
									<td><Spinner /></td>
								</tr>
							)
								: children || rows?.map(({ key, ...row }) => {
									if (row) {
										prepareRow(row);
										return (
											<tr key={key} {...row.getRowProps()}>
												{row.cells.map(({ key: key2, ...cell }) => (
													<td key={key2} {...cell.getCellProps()}>
														{cell.render('Cell')}
													</td>
												))}
											</tr>
										);
									} return null;
								})}
						</tbody>
					</table>
				</TableComponents.TableWrapper>
			</Card>
		</S.Wrapper>
	);
};

Table.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	onSort: PropTypes.func,
	loading: PropTypes.bool,
	hasManualSort: PropTypes.bool,
};
