import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { beginPasswordReset } from '../../services/authService';
import { errorHandler } from '../../services/api';
import { pattern, required } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';
import logo from '../../assets/dispatcher-logo.png';
import {
	Typography, Button, TextInput
} from '../../components';
import * as S from './ForgotPasswordForm.styles';
import { META_TITLE } from '../../../constants/general.constants';

const ForgotPasswordForm = () => {
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting, isSubmitSuccessful },
	} = useForm();
	const history = useHistory();

	useEffect(() => {
		document.title = `Reset Password | ${META_TITLE}`;
	}, []);

	async function asyncCaller(data) {
		await beginPasswordReset({ email: data.email });
	}

	function onSubmit(e) {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	}

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1" />
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="Dispatchr Logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			{isSubmitSuccessful ? (
				<S.Container>
					<FontAwesomeIcon className="envelope-icon" icon={['fal', 'envelope']} size="3x" />
					<Typography tag="h2" weight="bold" center>
						Check Your Email
					</Typography>
					<Typography tag="p" className="description" center>
						We sent a link to reset your password to your email address.
					</Typography>
					<Button type="submit" className="submit-button" onClick={() => history.push('/login')}>
						<Typography variation="button-medium" weight="bold">
							Okay
						</Typography>
					</Button>
				</S.Container>
			) : (
				<S.Container>
					<Typography tag="h2" weight="bold" center>
						Reset Password
					</Typography>
					<Typography tag="p" className="description" center>
						Enter email to receive reset link.
					</Typography>
					<S.Form onSubmit={onSubmit}>
						<TextInput
							label="Email"
							id="email"
							error={errors.email}
							autoComplete="email"
							{...register('email', {
								required: required('Email'),
								pattern: pattern('Email', EMAIL),
							})}
						/>
						<S.ActionsWrapper>
							<Button variant="outline" onClick={() => history.push('/login')}>
								<Typography variation="button-medium" weight="bold">
									Cancel
								</Typography>
							</Button>
							<Button type="submit">
								<Typography variation="button-medium" weight="bold">
									{isSubmitting ? 'Loading...' : 'Send'}
								</Typography>
							</Button>
						</S.ActionsWrapper>
					</S.Form>
				</S.Container>
			)}
		</S.Wrapper>
	);
};

export default ForgotPasswordForm;
