import React from 'react';
import PropTypes from 'prop-types';

import {
	Modal, IconButton, Button, Typography
} from '..';
import * as S from './PasswordModal.styles';

export const PasswordModal = ({
	isOpen,
	onRequestClose,
	submit,
	isSubmitting,
	isEmailSent
}) => (
	<Modal isOpen={isOpen} onRequestClose={onRequestClose} maxWidth="45.875rem" noOverflow>
		<S.Wrapper>
			<S.ActionsWrapper>
				<IconButton icon={['fal', 'times']} size={1.25} className="icon close-icon" onClick={onRequestClose} />
			</S.ActionsWrapper>
			<S.InfoWrapper>
				<Typography tag="h2" weight="bold">
					Reset Password
				</Typography>
				{isEmailSent ? (
					<Typography tag="p" className="email-success">
						Email has been sent to reset password
					</Typography>
				) : (
					<Typography tag="p">
						Are you sure you want to reset this password?
					</Typography>
				)}
			</S.InfoWrapper>
			<S.ButtonRow>
				<Button variant="outline" className="submit-button" onClick={onRequestClose}>
					<Typography variation="button-medium" weight="bold">
						{isEmailSent ? 'Close' : 'Cancel'}
					</Typography>
				</Button>
				{!isEmailSent && (
					<Button className="submit-button" onClick={submit}>
						<Typography variation="button-medium" weight="bold">
							{`Send${isSubmitting ? 'ing...' : ' Email'}`}
						</Typography>
					</Button>
				) }
			</S.ButtonRow>
		</S.Wrapper>
	</Modal>
);

PasswordModal.displayName = 'PasswordModal';
PasswordModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	isEmailSent: PropTypes.bool
};
