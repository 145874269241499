import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useQuery } from '../../hooks/useQuery';
import { useUserPermissions } from '../../hooks/useUserPermissions';

import {
	UserNavigation, PageHeader, Tab
} from '../../components';
import { OverviewTab } from './OverviewTab';
import { TeamTab } from './TeamTab';
import { META_TITLE } from '../../../constants/general.constants';
import * as S from './Account.styles';


const Account = () => {
	const isTeamTab = useQuery('tab') === 'team';
	const { status: userStatus } = useSelector((state) => state.user);
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Overview',
			component: <OverviewTab />,
			active: true
		},
	]);
	const { hasAdminAccess } = useUserPermissions();

	useEffect(() => {
		document.title = `Account | ${META_TITLE}`;
	}, []);

	useEffect(() => {
		if (hasAdminAccess) {
			setTabs([{
				id: 1,
				label: 'Overview',
				component: <OverviewTab />,
				active: !isTeamTab
			},
			{
				id: 2,
				label: 'Team',
				component: <TeamTab />,
				active: !!isTeamTab
			},]);
		}
	}, [hasAdminAccess, isTeamTab]);

	return (
		<UserNavigation>
			<S.PageWrapper>
				<PageHeader title="Account" />
				<S.Wrapper>
					<Tab tabs={tabs} setTabs={setTabs} loading={userStatus === 'loading'} headClassName="tabs" contentClassName="tab-content" />
				</S.Wrapper>
			</S.PageWrapper>
		</UserNavigation>
	);
};

export default Account;
