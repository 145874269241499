import styled, { css } from 'styled-components';

export const WEIGHTS = {
	regular: 400,
	semibold: 600,
	bold: 700,
	extrablack: 800,
};

export const SIZES = {
	h1: '2.75rem', // 44px
	h2: '2.125rem', // 34px
	h3: '1.75rem', // 28px
	h4: '1.5rem', // 24px
	h5: '1.25rem', // 20px
	h6: '1rem', // 16px
	p1: '1rem', // 16px
	p2: '0.875rem', // 14px
	p3: '0.75rem', // 12px
	span1: '1rem', // 16px
	span2: '0.875rem', // 14px
	span3: '0.75rem', // 12px
	'spanbutton-medium': '0.875rem', // 14px
	'spanbutton-small': '0.75rem', // 12px
	label: '0.875rem', // 14px
};

export const LINE_HEIGHTS = {
	h1: 'normal',
	h2: 'normal',
	h3: 'normal',
	h4: 'normal',
	h5: 'normal',
	h6: 'normal',
	p1: 'normal',
	p2: 'normal',
	p3: 'normal',
	span1: 'normal',
	span2: 'normal',
	span3: 'normal',
	'spanbutton-medium': 'normal',
	'spanbutton-small': 'normal',
	label: 'normal',
};

export const LETTER_SPACING = {
	h1: '.0625rem', // 1px
	h2: '.0625rem', // 1px
	h3: '.0625rem', // 1px
	h4: '.0625rem', // 1px
	h5: '.0625rem', // 1px
	h6: '.0625rem', // 1px
	p1: '.03125rem', // .5px
	p2: '.00625rem', // .1px
	p3: '.00625rem', // 1px
	span1: 'normal',
	span2: 'normal',
	span3: 'normal',
	'spanbutton-medium': 'normal',
	'spanbutton-small': 'normal',
	label: '.0625rem', // 1px
};

export const Typography = styled.span`
	font-weight: ${({ weight }) => WEIGHTS[weight]};
	font-size: ${({ as, variation }) => (as === 'span' || as === 'p' ? SIZES[as + variation] : SIZES[as])};
	line-height: ${({ as, variation }) => (as === 'span' || as === 'p' ? LINE_HEIGHTS[as + variation] : LINE_HEIGHTS[as])};
	letter-spacing: ${({ as, variation }) => (as === 'span' || as === 'p' ? LETTER_SPACING[as + variation] : LETTER_SPACING[as])};
	margin-bottom: ${({ as }) => (as === 'label' ? '0.375em' : '0')};
	text-align: ${({ center }) => (center ? 'center' : 'inherit')};

	${({ truncate }) => truncate
		&& css`
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		`}
`;
