import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
	fetchDispatches as getDispatches,
	fetchDispatch as getDispatch,
	createUpdateDispatch,
	duplicateDispatch as copyDispatch,
	deleteDispatch as destroyDispatch,
	moveDispatch as updateDispatchTargetDate,
	addTaskToDispatch as createDispatchTask,
	removeTaskFromDispatch as removeDispatchTask,
	sendDispatch as textDispatch
} from '../../../services/dispatches/dispatchService';
import { errorHandler } from '../../../services/api';
import { getCookie } from '../../../utils/cookie-methods';

export const fetchDispatches = createAsyncThunk('dispatches/fetchDispatches', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}

		const response = await getDispatches({ dates: data.datesArr });

		if (response?.data?.result?.dispatches) {
			return response.data.result.dispatches;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const fetchMoreDispatches = createAsyncThunk('dispatches/fetchMoreDispatches', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await getDispatches({ dates: data.datesArr });
		if (response?.data?.result?.dispatches) {
			return response.data.result.dispatches;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const fetchPreviousDispatches = createAsyncThunk('dispatches/fetchPreviousDispatches', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await getDispatches({ dates: data.datesArr });
		if (response?.data?.result?.dispatches) {
			return response.data.result.dispatches;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const fetchDispatch = createAsyncThunk('dispatches/fetchDispatch', async (data) => {
	try {
		const response = await getDispatch(data);
		if (response?.data?.result?.dispatch) {
			return response.data.result.dispatch;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const addDispatch = createAsyncThunk('dispatches/addDispatch', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await createUpdateDispatch(data);
		if (response?.data?.result?.dispatch) {
			return response.data.result.dispatch;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const updateDispatch = createAsyncThunk('dispatches/updateDispatch', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await createUpdateDispatch(data);
		if (response?.data?.result?.dispatch) {
			return response.data.result.dispatch;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const addTaskToDispatch = createAsyncThunk('dispatches/addTaskToDispatch', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await createDispatchTask(data);
		if (response?.data?.result?.dispatch) {
			return {
				...response.data.result.dispatch, task: response?.data?.result?.task, tempId: data.tempId, position: data.position
			};
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const removeTaskFromDispatch = createAsyncThunk('dispatches/removeTaskFromDispatch', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await removeDispatchTask(data);
		if (response?.data?.result?.dispatch) {
			return { dispatch: response.data.result.dispatch, task: response.data.result.task, persist: data.persist };
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const duplicateDispatch = createAsyncThunk('dispatches/duplicateDispatch', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await copyDispatch(data);
		if (response?.data?.result?.dispatch) {
			return response.data.result.dispatch;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const deleteDispatch = createAsyncThunk('dispatches/deleteDispatch', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await destroyDispatch(data);
		if (response?.data?.result?.dispatch) {
			return response.data.result.dispatch;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const moveDispatch = createAsyncThunk('dispatches/moveDispatch', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await updateDispatchTargetDate(data);
		if (response?.data?.result?.dispatch) {
			return response.data.result.dispatch;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const sendDispatch = createAsyncThunk('dispatches/sendDispatch', async (data) => {
	try {
		if (!getCookie('e_s_token')) {
			return null;
		}
		const response = await textDispatch(data);
		if (response?.data?.result?.dispatch) {
			return response.data.result.dispatch;
		}
		return null;

	} catch (error) {
		errorHandler(error);
	}
});

export const dispatchesSlice = createSlice({
	name: 'dispatches',
	initialState: {
		status: 'idle',
		taskStatus: 'idle',
		dispatchStatus: 'idle',
		error: null,
		value: [],
		dispatch: null
	},
	reducers: {
		updateDispatches: (state, action) => {
			state.value = action.payload;
		},
		addDispatch: (state, action) => {
			const { payload } = action;
			payload.targetDate = { iso: action.payload?.targetDate?.iso || action.payload.targetDate };
			state.value = [...state.value, payload];
		},
		updateDispatchTask: (state, action) => {
			const dispatchesArr = [...state.value];
			const dispatchTaskIndex = dispatchesArr.findIndex((dispatch) => dispatch.id === action.payload.dispatchId);
			const tasksArr = dispatchesArr[dispatchTaskIndex].tasks;
			const index = tasksArr.findIndex((task) => task.id === action.payload.id);
			tasksArr[index] = action.payload;
			tasksArr.sort((a, b) => a.order - b.order);
			for (let i = 0; i < tasksArr.length; i += 1) {
				if (i < action.payload.order) {
					tasksArr[i].order = i;
				} else {
					tasksArr[i].order = i + 1;
				}
			}
			state.value[dispatchTaskIndex].tasks = tasksArr;
		},
		updateWorkerDispatchTask: (state, action) => {
			const dispatch = { ...state.dispatch };
			const tasksArr = [...dispatch.tasks];
			const index = tasksArr.findIndex((task) => task.id === action.payload.id);
			tasksArr[index] = action.payload;
			tasksArr.sort((a, b) => a.order - b.order);
			for (let i = 0; i < tasksArr.length; i += 1) {
				if (i < action.payload.order) {
					tasksArr[i].order = i;
				} else {
					tasksArr[i].order = i + 1;
				}
			}
			dispatch.tasks = tasksArr;
			state.dispatch = dispatch;
		},
		addDispatchTask: (state, action) => {
			const dispatchesArr = [...state.value];
			const dispatchIndex = dispatchesArr.findIndex((dispatch) => dispatch.id === action.payload.dispatchId);
			const tasksArr = [...dispatchesArr[dispatchIndex].tasks].sort((a, b) => a.order - b.order);
			const position = action.payload.position || action.payload.position === 0 ? action.payload.position : null;
			if (position || position === 0) {
				for (let i = 0; i < tasksArr.length; i += 1) {
					if (i < position) {
						tasksArr[i].order = i;
					} else {
						tasksArr[i].order = i + 1;
					}
				}
				const task = { ...action.payload };
				task.order = action.payload.position;
				task.isSent = false;
				tasksArr.splice(task.order, 0, task);
			} else {
				tasksArr.push(action.payload);
			}
			dispatchesArr[dispatchIndex].tasks = tasksArr;
			state.value = dispatchesArr;
		},
		deleteDispatchTask: (state, action) => {
			const dispatchesArr = [...state.value];
			const dispatchTaskIndex = dispatchesArr.findIndex((dispatch) => dispatch.id === action.payload.dispatchId);
			const tasksArr = dispatchesArr[dispatchTaskIndex].tasks;
			tasksArr.splice(tasksArr.findIndex((i) => i.id === action.payload.id), 1);
			tasksArr.sort((a, b) => a.order - b.order);
			for (let i = 0; i < tasksArr.length; i += 1) {
				tasksArr[i].order = i;
			}

			state.value[dispatchTaskIndex].tasks = tasksArr;
		},
		updateDispatchInState: (state, action) => {
			state.dispatchStatus = 'succeeded';
			const dispatchesArr = [...state.value];
			const index = dispatchesArr.findIndex((dispatch) => dispatch.id === action.payload.id);
			const { payload } = action;
			payload.targetDate = { iso: action.payload?.targetDate?.iso || action.payload.targetDate };
			dispatchesArr[index] = payload;
			state.value = dispatchesArr;
		},
		asyncMoveDispatch: (state, action) => {
			const dispatchesArr = [...state.value];
			dispatchesArr.splice(dispatchesArr.findIndex((i) => i.id === action.payload.id), 1);

			state.value = [...dispatchesArr, action.payload];
		},
		deleteDispatchTaskTag: (state, action) => {
			const dispatchesArr = state.value;

			dispatchesArr.forEach((dispatch) => {
				const { tasks } = dispatch;
				tasks.forEach((task) => {
					const { tags } = task;
					if (tags.length > 0) {
						tags.splice(tags.findIndex((i) => i.id === action.payload.id), 1);
					}
				});
			});

			state.value = dispatchesArr;
		},
		addDispatchSocket: (state, action) => {
			const dispatches = [...state.value];
			const { payload } = action;
			payload.targetDate = { iso: action.payload?.targetDate?.iso || action.payload.targetDate };
			state.value = [...dispatches, payload];
		},
		updateDispatchSocket: (state, action) => {
			state.dispatchStatus = 'succeeded';
			const dispatchesArr = [...state.value];
			const index = dispatchesArr.findIndex((dispatch) => dispatch.id === action.payload.id);
			const { payload } = action;
			payload.targetDate = { iso: action.payload?.targetDate?.iso || action.payload.targetDate };
			dispatchesArr[index] = payload;
			state.value = dispatchesArr;
		},
		deleteDispatchSocket: (state, action) => {
			const dispatchesArr = [...state.value];
			dispatchesArr.splice(dispatchesArr.findIndex((i) => i.id === action.payload.id), 1);
			state.value = dispatchesArr;
		},
		moveDispatchSocket: (state, action) => {
			const dispatchesArr = [...state.value];
			dispatchesArr.splice(dispatchesArr.findIndex((i) => i.id === action.payload.id), 1);
			const { payload } = action;
			payload.targetDate = { iso: action.payload?.targetDate?.iso || action.payload.targetDate };
			state.value = [...dispatchesArr, payload];
		},
		duplicateDispatchSocket: (state, action) => {
			const { payload } = action;
			payload.targetDate = { iso: action.payload?.targetDate?.iso || action.payload.targetDate };
			state.value = [...state.value, payload];
		},
		sendDispatchSocket: (state, action) => {
			const dispatchesArr = state.value;
			const index = dispatchesArr.findIndex((task) => task.id === action.payload.id);
			const { payload } = action;
			payload.targetDate = { iso: action.payload?.targetDate?.iso || action.payload.targetDate };
			dispatchesArr[index] = payload;
			state.value = dispatchesArr;
		},
		orderDispatchTasks: (state, action) => {
			const dispatchesArr = [...state.value];
			const dispatchTaskIndex = dispatchesArr.findIndex((dispatch) => dispatch.id === action.payload.dispatchId);
			const tasksArr = dispatchesArr[dispatchTaskIndex].tasks;
			const index = tasksArr.findIndex((task) => task.id === action.payload.id);
			tasksArr.splice(index, 1);
			tasksArr.splice(action.payload.order, 0, action.payload);
			state.value[dispatchTaskIndex].tasks = tasksArr;
		},
		deleteTaskFromPreviousDispatch: (state, action) => {
			const dispatchesArr = [...state.value];
			const dispatchTaskIndex = dispatchesArr.findIndex((dispatch) => dispatch.id === action.payload.previousDispatch);
			const tasksArr = dispatchesArr[dispatchTaskIndex].tasks;
			tasksArr.splice(tasksArr.findIndex((i) => i.id === action.payload.id), 1);
			tasksArr.sort((a, b) => a.order - b.order);
			for (let i = 0; i < tasksArr.length; i += 1) {
				tasksArr[i].order = i;
			}

			state.value[dispatchTaskIndex].tasks = tasksArr;
		},
		removeDispatchTaskInState: (state, action) => {
			const dispatchesArr = [...state.value];
			const dispatchIndex = dispatchesArr.findIndex((dispatch) => dispatch.id === action.payload.dispatchId);
			const tasksArr = [...dispatchesArr[dispatchIndex].tasks];
			const taskIndex = tasksArr.findIndex((task) => task.id === action.payload.task.id);
			tasksArr.splice(taskIndex, 1);
			tasksArr.sort((a, b) => a.order - b.order);
			for (let i = 0; i < tasksArr.length; i += 1) {
				tasksArr[i].order = i;
			}

			dispatchesArr[dispatchIndex].tasks = tasksArr;
			state.value = dispatchesArr;
		},
		addDispatchTaskInState: (state, action) => {
			const dispatchesArr = [...state.value];
			const dispatchIndex = dispatchesArr.findIndex((dispatch) => dispatch.id === action.payload.id);
			const tasksArr = [...dispatchesArr[dispatchIndex].tasks].sort((a, b) => a.order - b.order);
			if (action.payload.tempId) {
				const index = tasksArr.findIndex((task) => task.id === action.payload.tempId);
				tasksArr[index] = action.payload.task;
			} else if (action.payload.position) {
				for (let i = 0; i < tasksArr.length; i += 1) {
					if (i < action.payload.task.order) {
						tasksArr[i].order = i;
					} else {
						tasksArr[i].order = i + 1;
					}
				}
				tasksArr.splice(action.payload.task.order, 0, action.payload.task);
			} else {
				tasksArr.push(action.payload.task);
			}
			dispatchesArr[dispatchIndex].tasks = tasksArr;
			state.value = dispatchesArr;
		},
		updateOrderDispatchTasksByTask: (state, action) => {
			const dispatchesArr = [...state.value];
			const dispatchIndex = dispatchesArr.findIndex((dispatch) => dispatch.id === action.payload.dispatchId);
			const tasksArr = [...dispatchesArr[dispatchIndex].tasks];
			const taskIndex = tasksArr.findIndex((task) => task.id === action.payload.id);
			tasksArr.splice(taskIndex, 1);
			tasksArr.sort((a, b) => a.order - b.order);
			for (let i = 0; i < tasksArr.length; i += 1) {
				if (i < action.payload.order) {
					tasksArr[i].order = i;
				} else {
					tasksArr[i].order = i + 1;
				}
			}
			tasksArr.splice(action.payload.order, 0, action.payload);
			dispatchesArr[dispatchIndex].tasks = tasksArr;
			state.value = dispatchesArr;
		},
		clearDispatches: (state) => {
			state.status = 'idle';
			state.taskStatus = 'idle';
			state.dispatchStatus = 'idle';
			state.error = null;
			state.value = [];
			state.dispatch = null;
		}
	},
	extraReducers: {
		[fetchDispatches.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchDispatches.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.value = action.payload;
		},
		[fetchDispatches.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
		[fetchMoreDispatches.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			const dispatches = [...state.value];
			if (action.payload.length > 0) {
				state.value = [...dispatches, ...action.payload];
			} else {
				state.value = [...dispatches];
			}
		},
		[fetchMoreDispatches.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
		[fetchPreviousDispatches.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			const dispatches = [...state.value];
			if (action.payload.length > 0) {
				state.value = [...action.payload, ...dispatches];
			} else {
				state.value = [...dispatches];
			}
		},
		[fetchPreviousDispatches.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
		[fetchDispatch.pending]: (state) => {
			state.dispatchStatus = 'loading';
		},
		[fetchDispatch.fulfilled]: (state, action) => {
			state.dispatchStatus = 'succeeded';
			state.dispatch = action.payload;
		},
		[fetchDispatch.rejected]: (state, action) => {
			state.dispatchStatus = 'failed';
			state.error = action.error.message;
		},
		[addDispatch.pending]: (state) => {
			state.dispatchStatus = 'loading';
		},
		[addDispatch.fulfilled]: (state, action) => {
			state.dispatchStatus = 'succeeded';
			const dispatches = [...state.value];
			const { payload } = action;
			payload.targetDate = { iso: action.payload?.targetDate?.iso || action.payload.targetDate };
			state.value = [...dispatches, payload];
		},
		[addDispatch.rejected]: (state, action) => {
			state.dispatchStatus = 'failed';
			state.error = action.error.message;
		},
		[updateDispatch.pending]: (state) => {
			state.dispatchStatus = 'loading';
		},
		[updateDispatch.fulfilled]: (state, action) => {
			state.dispatchStatus = 'succeeded';
			const dispatchesArr = [...state.value];
			const index = dispatchesArr.findIndex((dispatch) => dispatch.id === action.payload.id);
			const { payload } = action;
			payload.targetDate = { iso: action.payload?.targetDate?.iso || action.payload.targetDate };
			dispatchesArr[index] = payload;
			state.value = dispatchesArr;
		},
		[updateDispatch.rejected]: (state, action) => {
			state.dispatchStatus = 'failed';
			state.error = action.error.message;
		},
		[addTaskToDispatch.pending]: (state) => {
			state.taskStatus = 'loading';
		},
		[addTaskToDispatch.fulfilled]: (state, action) => {
			state.dispatchStatus = 'succeeded';
			const dispatchesArr = [...state.value];
			const dispatchIndex = dispatchesArr.findIndex((dispatch) => dispatch.id === action.payload.id);
			const tasksArr = [...dispatchesArr[dispatchIndex].tasks].sort((a, b) => a.order - b.order);
			if (action.payload.tempId) {
				const index = tasksArr.findIndex((task) => task.id === action.payload.tempId);
				tasksArr[index] = action.payload.task;
			} else if (action.payload.position) {
				for (let i = 0; i < tasksArr.length; i += 1) {
					if (i < action.payload.task.order) {
						tasksArr[i].order = i;
					} else {
						tasksArr[i].order = i + 1;
					}
				}
				tasksArr.splice(action.payload.task.order, 0, action.payload.task);
			} else {
				tasksArr.push(action.payload.task);
			}
			dispatchesArr[dispatchIndex].tasks = tasksArr;
			state.value = dispatchesArr;
		},
		[addTaskToDispatch.rejected]: (state, action) => {
			state.taskstatus = 'failed';
			state.error = action.error.message;
		},
		[removeTaskFromDispatch.pending]: (state) => {
			state.taskStatus = 'loading';
		},
		[removeTaskFromDispatch.fulfilled]: (state, action) => {
			if (action.payload.persist) {
				state.dispatchStatus = 'succeeded';
				const dispatchesArr = [...state.value];
				const dispatchIndex = dispatchesArr.findIndex((dispatch) => dispatch.id === action.payload.dispatch.id);
				const tasksArr = [...dispatchesArr[dispatchIndex].tasks];
				const taskIndex = tasksArr.findIndex((task) => task.id === action.payload.task.id);
				tasksArr.splice(taskIndex, 1);
				dispatchesArr[dispatchIndex].tasks = tasksArr;
				state.value = dispatchesArr;
			}
		},
		[removeTaskFromDispatch.rejected]: (state, action) => {
			state.taskstatus = 'failed';
			state.error = action.error.message;
		},
		[duplicateDispatch.pending]: (state) => {
			state.dispatchStatus = 'loading';
		},
		[duplicateDispatch.fulfilled]: (state, action) => {
			state.dispatchStatus = 'succeeded';
			const { payload } = action;
			payload.targetDate = { iso: action.payload?.targetDate?.iso || action.payload.targetDate };
			state.value = [...state.value, payload];
		},
		[duplicateDispatch.rejected]: (state, action) => {
			state.dispatchStatus = 'failed';
			state.error = action.error.message;
		},
		[deleteDispatch.pending]: (state) => {
			state.dispatchStatus = 'loading';
		},
		[deleteDispatch.fulfilled]: (state, action) => {
			state.dispatchStatus = 'succeeded';
			const dispatchesArr = [...state.value];
			dispatchesArr.splice(dispatchesArr.findIndex((i) => i.id === action.payload.id), 1);
			state.value = dispatchesArr;
		},
		[deleteDispatch.rejected]: (state, action) => {
			state.dispatchStatus = 'failed';
			state.error = action.error.message;
		},
		[moveDispatch.pending]: (state) => {
			state.dispatchStatus = 'loading';
		},
		[moveDispatch.fulfilled]: (state, action) => {
			state.dispatchStatus = 'succeeded';
			const dispatchesArr = [...state.value];
			dispatchesArr.splice(dispatchesArr.findIndex((i) => i.id === action.payload.id), 1);
			const { payload } = action;
			payload.targetDate = { iso: action.payload?.targetDate?.iso || action.payload.targetDate };
			state.value = [...dispatchesArr, payload];
		},
		[moveDispatch.rejected]: (state, action) => {
			state.dispatchStatus = 'failed';
			state.error = action.error.message;
		},
		[sendDispatch.pending]: (state) => {
			state.dispatchStatus = 'loading';
		},
		[sendDispatch.fulfilled]: (state, action) => {
			state.dispatchStatus = 'succeeded';
			const dispatchesArr = state.value;
			const index = dispatchesArr.findIndex((task) => task.id === action.payload.id);
			const { payload } = action;
			payload.targetDate = { iso: action.payload?.targetDate?.iso || action.payload.targetDate };
			dispatchesArr[index] = payload;
			state.value = dispatchesArr;
		},
		[sendDispatch.rejected]: (state, action) => {
			state.dispatchStatus = 'failed';
			state.error = action.error.message;
		},
	},
});

export const {
	updateDispatches,
	addDispatchTask,
	updateDispatchTask,
	updateWorkerDispatchTask,
	deleteDispatchTask,
	updateDispatchInState,
	asyncMoveDispatch,
	deleteDispatchTaskTag,
	addDispatchSocket,
	updateDispatchSocket,
	deleteDispatchSocket,
	moveDispatchSocket,
	duplicateDispatchSocket,
	sendDispatchSocket,
	orderDispatchTasks,
	deleteTaskFromPreviousDispatch,
	removeDispatchTaskInState,
	addDispatchTaskInState,
	updateOrderDispatchTasksByTask,
	clearDispatches
} = dispatchesSlice.actions;

export default dispatchesSlice.reducer;
