import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import * as S from './RangeInput.styles';

export const RangeInput = forwardRef(({
	min = 0, max = 100, unit, label, ...rest
}, ref) => {
	const Thumb = (props, { valueNow }) => (
		<S.Thumb {...props}>
			<Typography tag="p" className="floating-top">
				{`${valueNow}${unit || ''}`}
			</Typography>
		</S.Thumb>
	);

	const Track = (props, { index }) => <S.Track {...props} index={index} />;

	return (
		<S.Container>
			{label && (
				<Typography tag="label" weight="semibold">
					{label}
				</Typography>
			)}
			<S.Wrapper>
				<Typography tag="p" variation="2" className="floating-bottom left">{`${min} ${unit || ''}`}</Typography>
				<S.Slider ref={ref} {...rest} min={min} max={max} renderThumb={Thumb} renderTrack={Track} />
				<Typography tag="p" variation="2" className="floating-bottom right">{`${max} ${unit || ''}`}</Typography>
			</S.Wrapper>
		</S.Container>
	);
});

RangeInput.displayName = 'RangeInput';
RangeInput.propTypes = {
	unit: PropTypes.string,
	min: PropTypes.number,
	max: PropTypes.number,
	label: PropTypes.string,
};
