import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as S from './TextAction.styles';

export const TextAction = forwardRef(({
	text, containerClassName, variation, handleClick, ...rest
}, ref) => (
	<S.Typography ref={ref} tag="p" variation={variation} {...rest} className={containerClassName} onClick={handleClick}>
		{text}
	</S.Typography>
));

TextAction.displayName = 'TextAction';
TextAction.propTypes = {
	containerClassName: PropTypes.string,
	variation: PropTypes.string,
	text: PropTypes.string,
	handleClick: PropTypes.func,
};
