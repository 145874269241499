import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { useDispatch } from 'react-redux';

import { updateUser } from '../../app/slices/user/userSlice';
import { errorHandler } from '../../services/api';
import { registerUser } from '../../services/user/onboarding/onboardingService';
import { pattern, required } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';
import { PASSWORD_REGEX, META_TITLE } from '../../../constants/general.constants';
import logo from '../../assets/dispatcher-logo.png';
import {
	Typography, Button, TextInput, Link, PasswordInput
} from '../../components';
import * as S from './SignUpForm.styles';

const SignUpForm = () => {
	const history = useHistory();
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors, isSubmitting },
		control,
	} = useForm();
	const dispatch = useDispatch();

	useEffect(() => {
		document.title = `Sign Up | ${META_TITLE}`;
	}, []);

	async function asyncCaller(data) {
		const {
			data: { user },
		} = await registerUser({
			firstName: data.firstName, lastName: data.lastName, email: data.email, password: data.password
		});

		dispatch(updateUser(user));

		history.push('/dashboard');
	}

	function onSubmit(e) {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	}

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1" />
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="Dispatchr Logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			<S.Container>
				<Typography tag="h2" weight="bold" center>
					Signup
				</Typography>
				<Typography tag="p" className="description" center>
					Enter signup info below.
				</Typography>
				<S.Form onSubmit={onSubmit}>
					<div className="two-items">
						<TextInput
							label="First Name"
							id="first-name"
							error={errors.firstName}
							autoComplete="first-name"
							hideLastPass
							{...register('firstName', {
								required: required('First Name'),
							})}
						/>
						<TextInput
							label="Last Name"
							id="last-name"
							error={errors.lastName}
							autoComplete="last-name"
							hideLastPass
							{...register('lastName', {
								required: required('Last Name'),
							})}
						/>
					</div>
					<TextInput
						label="Email"
						id="email"
						error={errors.email}
						autoComplete="email"
						hideLastPass
						{...register('email', {
							required: required('Email'),
							pattern: pattern('Email', EMAIL),
						})}
					/>
					<Controller
						name="password"
						control={control}
						rules={{ required: required('Password'), pattern: pattern('Password', PASSWORD_REGEX) }}
						render={({ field: { onChange, value } }) => <PasswordInput label="Password" id="password" type="password" error={errors.password} disallowedContent={[]} value={value} onChange={onChange} />}
					/>
					<TextInput
						label="Confirm Password"
						id="confirm-password"
						type="password"
						error={errors.confirmPassword}
						autoComplete="confirm-password"
						{...register('confirmPassword', {
							required: required('Confirm Password'),
							validate: {
								equalToPassword: (v) => (getValues('password') === v ? true : "Confirm Password doesn't match with Password"),
							},
						})}
					/>
					<Typography tag="p" variation="2" className="login-message" center>
						<span>Already have an account?</span>
						{' '}
						<Link to="/login">Login</Link>
					</Typography>
					<Button type="submit" className="submit-button">
						<Typography variation="button-medium" weight="bold">
							{isSubmitting ? 'Loading...' : 'Signup'}
						</Typography>
					</Button>
				</S.Form>
			</S.Container>
		</S.Wrapper>
	);
};

export default SignUpForm;
