import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import {
	updateUser
} from '../../app/slices/user/userSlice';
import {
	updateSubscription
} from '../../services/user/subscription/subscriptionService';
import { errorHandler } from '../../services/api';

import {
	Button, Modal, IconButton, PaymentSelect, Typography
} from '..';
import { BillingForm } from '../BillingModal/BillingForm';
import * as S from './PaymentModal.styles';

export const PaymentModal = ({
	isOpen, onRequestClose
}) => {
	const user = useSelector((state) => state.user.value);
	const [selectedMethod, setSelectedMethod] = useState(user.paymentMethods && user.paymentMethods.length > 0 ? user.paymentMethods.find((method) => user?.subscription?.default_payment_method === method.stripeId)?.id || '' : '');
	const [address, setAddress] = useState({
		street: user?.subscription?.billingAddress?.street || '', city: user?.subscription?.billingAddress?.city || '', state: user?.subscription?.billingAddress?.state || '', postalCode: user?.subscription?.billingAddress?.postalCode || ''
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const dispatch = useDispatch();

	const handleClosePaymentModal = () => {
		onRequestClose();

		setTimeout(() => {
			setAddress({
				street: user?.subscription?.billingAddress?.street || '', city: user?.subscription?.billingAddress?.city || '', state: user?.subscription?.billingAddress?.state || '', postalCode: user?.subscription?.billingAddress?.postalCode || ''
			});
		}, 500);
	};

	const handleAddressChange = (key, value) => {
		setAddress({ ...address, [key]: value });
	};

	const handleSelectedMethod = (e) => {
		const { value } = e.target;
		setSelectedMethod(value);
	};

	const handleSubmit = async () => {
		setIsSubmitting(true);

		await updateSubscription({
			tier: user?.subscription?.tier,
			billingStreet: address.street,
			billingCity: address.city,
			billingState: address.state,
			billingPostalCode: address.postalCode,
			paymentMethodId: selectedMethod
		}).then((res) => {
			if (res.data.result.success) {
				dispatch(updateUser(res.data.result.user));
				handleClosePaymentModal();
				setIsSubmitting(false);
			}
		}).catch((err) => {
			setIsSubmitting(false);
			errorHandler(err);
		});
	};

	return (
		<Modal isOpen={isOpen} onRequestClose={handleClosePaymentModal} maxWidth="55.875rem">
			<S.Wrapper>
				<S.ActionsWrapper>
					<IconButton icon={['fal', 'times']} size={1.25} className="icon close-icon" onClick={onRequestClose} disabled={isSubmitting} />
				</S.ActionsWrapper>
				<S.HeaderWrapper>
					<Typography tag="h2" weight="bold">Update Payment Method</Typography>
					<Typography tag="p">Create a new payment method or update your default payment method below</Typography>
				</S.HeaderWrapper>
				<S.Content>
					<BillingForm address={address} handleAddressChange={handleAddressChange} />
					<PaymentSelect address={address} selectedMethod={selectedMethod} handleSelectedMethod={handleSelectedMethod} canCreatePaymentMethod={Object.values(address).every((val) => val !== '')} canRemovePaymentMethod />
				</S.Content>
				<S.FooterActionsWrapper>
					<Button variant="outline" onClick={handleClosePaymentModal} disabled={isSubmitting}>
						Close
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting}>
						{isSubmitting ? 'Saving' : 'Save'}
					</Button>
				</S.FooterActionsWrapper>
			</S.Wrapper>
		</Modal>
	);

};

PaymentModal.displayName = 'PaymentModal';
PaymentModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
};
