import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import * as S from './EmptyComponent.styles';

export const EmptyComponent = ({ title, message, icon = ['fal', 'books'] }) => (
	<S.Wrapper>
		<FontAwesomeIcon className="envelope-icon" icon={icon} size="2x" />
		<Typography tag="h4" weight="bold" center>
			{title}
		</Typography>
		<Typography tag="p" className="description" center>
			{message}
		</Typography>
	</S.Wrapper>
);

EmptyComponent.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string,
	icon: PropTypes.array,
};
