import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '../../../hooks/useQuery';

import { selectAccordion, setOpenAccordions, setAccordionsLoaded } from '../../../app/slices/dashboard/dashboardSlice';

import { DispatchAccordionSection } from '../DispatchAccordionSection';
import * as S from './DispatchAccordion.styles';

const DispatchAccordion = ({ children, allowMultipleOpen }) => {
	const dispatch = useDispatch();
	const activeDate = useQuery('activeDate');
	const accordionSelected = useSelector((state) => state.dashboard.accordionSelected);
	const openAccordions = useSelector((state) => state.dashboard.openAccordions);
	const accordionsLoaded = useSelector((state) => state.dashboard.accordionsLoaded);

	const setURL = (date) => {
		const url = new URL(window.location);
		url.searchParams.set('activeDate', date);
		window.history.pushState(null, '', url.toString());
	};

	const loadAccordions = useCallback(() => {
		const openAccordionsObj = { ...openAccordions };

		children.forEach((child) => {
			if (moment(child.props.date, 'MM-DD-YYYY').format('ddd, MMM DD') === moment(child.props.currentDate, 'YYYY-MM-DD').format('ddd, MMM DD') && !accordionSelected && !activeDate) {
				openAccordionsObj[child.props.date] = true;
				setURL(child.props.date);
				dispatch(selectAccordion(child.props.date));
			} else if (accordionSelected && !activeDate) {
				openAccordionsObj[accordionSelected] = true;
				dispatch(selectAccordion(accordionSelected));
			} else if (activeDate) {
				openAccordionsObj[activeDate] = true;
				dispatch(selectAccordion(activeDate));
			}
		});

		dispatch(setOpenAccordions(openAccordionsObj));
	}, [accordionSelected, activeDate, children, dispatch, openAccordions]);

	useEffect(() => {
		if (accordionSelected) {
			setURL(accordionSelected);
		}
	}, []);

	useEffect(() => {
		if (!accordionsLoaded) {
			loadAccordions();
			dispatch(setAccordionsLoaded());
		}
	}, [accordionsLoaded]);

	const onClick = useCallback((date) => {
		let openAccordionsObj = { ...openAccordions };
		const isOpen = openAccordionsObj[date];

		if (allowMultipleOpen) {
			openAccordionsObj = { ...openAccordionsObj, [date]: !isOpen };
		} else {
			openAccordionsObj[date] = !isOpen;
		}

		dispatch(setOpenAccordions(openAccordionsObj));
	}, [allowMultipleOpen, openAccordions, dispatch]);

	return (
		<S.Wrapper className="fade-in">
			{children.map((child) => (
				<DispatchAccordionSection
					key={child.props.label}
					isOpen={!!openAccordions[child.props.date]}
					label={child.props.label}
					date={child.props.date}
					dispatchCount={child.props.dispatchCount}
					onClick={onClick}
				>
					{child.props.children}
				</DispatchAccordionSection>
			))}
		</S.Wrapper>
	);
};

export default DispatchAccordion;
DispatchAccordion.displayName = 'DispatchAccordion';
DispatchAccordion.propTypes = {
	allowMultipleOpen: PropTypes.bool,
	children: PropTypes.instanceOf(Object).isRequired,
};
