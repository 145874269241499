import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch } from 'react-redux';

import {
	acceptInvite,
	validateInviteAttempt
} from '../../services/user/invites/inviteService';
import { errorHandler } from '../../services/api';
import { clearUser, updateUser } from '../../app/slices/user/userSlice';
import { clearContacts } from '../../app/slices/contacts/contactsSlice';
import { clearDispatches } from '../../app/slices/dispatches/dispatchesSlice';
import { clearTasks } from '../../app/slices/tasks/tasksSlice';
import { clearTags } from '../../app/slices/tags/tagsSlice';
import { clearDashboard } from '../../app/slices/dashboard/dashboardSlice';
import { clearMenu } from '../../app/slices/menu/menuSlice';
import { clearTeam } from '../../app/slices/team/teamSlice';

import { required, pattern } from '../../utils/form-default-errors';
import logo from '../../assets/dispatcher-logo.png';
import {
	Typography, Button, TextInput, PasswordInput
} from '../../components';
import * as S from './AcceptInvite.styles';
import { PASSWORD_REGEX, META_TITLE } from '../../../constants/general.constants';
import { logoutUser } from '../../services/authService';

const AcceptInviteForm = () => {
	const { aToken, bToken } = useParams();
	const {
		register,
		handleSubmit,
		getValues,
		control,
		formState: { errors, isSubmitting, isSubmitSuccessful },
	} = useForm();
	const history = useHistory();
	const dispatch = useDispatch();

	const [isValidated, setIsValidated] = useState(false);
	const [validationError, setValidationError] = useState(null);

	useEffect(() => {
		document.title = `Accept Invite | ${META_TITLE}`;

		let mounted = true;
		validateInviteAttempt({ aToken, bToken }).then((response) => {
			const { success, error } = response.data.result;
			if (mounted) {
				setIsValidated(true);
				setValidationError(success !== true ? error : null);
				if (success === true) {
					logoutUser().then(() => {
						if (mounted) {
							dispatch(clearDashboard());
							dispatch(clearMenu());
							dispatch(clearTeam());
							dispatch(clearContacts());
							dispatch(clearDispatches());
							dispatch(clearTasks());
							dispatch(clearTags());
							dispatch(clearUser());
						}
					});
				}
			}
		});
		return () => { mounted = false; };
	}, []);

	async function asyncCaller(data) {
		const userData = await acceptInvite({ aToken, bToken, pass: data.password });
		if (userData.data.result.success) {
			dispatch(updateUser(userData.data.result.user));
			history.push('/dashboard');
		}
	}

	function onSubmit(e) {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	}

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1" />
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="Dispatchr Logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			{ (() => {
				if (isValidated !== true) {
					return <div />;
				} if (isSubmitSuccessful === true) {
					return (
						<S.Container>
							<FontAwesomeIcon className="envelope-icon" icon={['fal', 'check-circle']} size="3x" />
							<Typography tag="h2" weight="bold" center>
								Invitation Accepted
							</Typography>
							<Typography tag="p" className="description" center>
								Your Password has been set. Continue to login to access your account.
							</Typography>

							<Button type="submit" className="submit-button" onClick={() => history.push('/login')}>
								<Typography variation="button-medium" weight="bold">
									Complete
								</Typography>
							</Button>
						</S.Container>
					);
				} if (validationError != null) {
					return (
						<S.Container>
							<FontAwesomeIcon className="envelope-icon" icon={['fal', 'question-circle']} size="3x" />
							{ (() => {
								if (validationError.code === 102) {
									return (
										<>
											<Typography tag="h2" weight="bold" center>
												Your invite link has expired.
											</Typography>
											<Typography tag="p" className="description" center>
												To set your password, reach out to your account administrator.
											</Typography>
										</>
									);
								}
								return (
									<>
										<Typography tag="h2" weight="bold" center>
											Your invite link has already been used or is no longer valid.
										</Typography>
										<Typography tag="p" className="description" center>
											To get a new invite, reach out to your account administrator.
										</Typography>
									</>
								);

							})() }
							<Button type="submit" className="submit-button" onClick={() => history.push('/login')}>
								<Typography variation="button-medium" weight="bold">
									Return to login
								</Typography>
							</Button>
						</S.Container>
					);
				}
				return (
					<S.Container>
						<Typography tag="h2" weight="bold" center>
							Enter New Password
						</Typography>
						<Typography tag="p" className="description" center>
							Enter in a new password.
						</Typography>
						<S.Form onSubmit={onSubmit}>
							<Controller
								name="password"
								control={control}
								rules={{ required: required('Password'), pattern: pattern('Password', PASSWORD_REGEX) }}
								render={({ field: { onChange, value } }) => <PasswordInput label="New Password" id="password" type="password" error={errors.password} disallowedContent={[]} value={value} onChange={onChange} />}
							/>
							<TextInput
								label="Confirm New Password"
								type="password"
								id="confirm-password"
								error={errors.confirmPassword}
								autoComplete="password"
								{...register('confirmPassword', {
									required: required('Confirm Password'),
									validate: {
										equalToPassword: (v) => (getValues('password') === v ? true : "Confirm Password doesn't match with Password"),
									},
								})}
							/>
							<S.ActionsWrapper>
								<Button variant="outline" onClick={() => history.push('/login')}>
									<Typography variation="button-medium" weight="bold">
										Cancel
									</Typography>
								</Button>
								<Button type="submit">
									<Typography variation="button-medium" weight="bold">
										{isSubmitting ? 'Saving...' : 'Save'}
									</Typography>
								</Button>
							</S.ActionsWrapper>
						</S.Form>
					</S.Container>
				);

			})()}
		</S.Wrapper>
	);
};

export default AcceptInviteForm;
