import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
	height: 4.5rem;
	min-height: 4.5rem;
	flex-direction: column;
	padding: 0.5rem 1rem;
	background-color: white;
	border-bottom: 1px solid ${colors.neutralForm};
	z-index: 2;

	@media screen and (min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
		height: auto;
	}
`;

export const LeftWrapper = styled.div`
	display: flex;
	align-items: center;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const SearchWrapper = styled.div`
	margin-left: 1.5rem;
	min-width: 100%;

	svg {
		color: ${colors.neutralText};
		height: 1.25rem;
	}

	input {
		padding-left: 2.5rem;
	}

	@media screen and (max-width: 992px) {
		min-width: 65%;
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		margin: 0;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 0.5rem;

	svg {
		height: 1.125rem;
	}

	.icon {
		width: 1rem;
	}

	& > * + * {
		margin-top: 0.5rem;
	}

	@media screen and (min-width: 768px) {
		flex-direction: row;
		margin-top: 0;

		& > * + * {
			margin-top: 0;
			margin-left: 0.5rem;
		}
	}
`;
