import React, {
	useState, useRef, useEffect, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';
import moment from 'moment';

import { useForm, Controller } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import {
	updateTask, duplicateTask, deleteTask, deleteTaskTag, orderTasksByTask, updateTaskOrder
} from '../../../app/slices/tasks/tasksSlice';
import {
	addTag, removeTag
} from '../../../app/slices/tags/tagsSlice';
import {
	updateDispatchTask, deleteDispatchTask, addTaskToDispatch, addDispatch, updateDispatchInState, deleteDispatchTaskTag
} from '../../../app/slices/dispatches/dispatchesSlice';
import {
	updateTaskStatus
} from '../../../services/tasks/taskService';
import { errorHandler } from '../../../services/api';

import {
	Modal, IconButton, Typography, Button, TextInput, Select, TextArea, TextAction, Popover
} from '../../../components';
import { MoveTaskModal } from './MoveTaskModal';
import * as S from './TaskModal.styles';

import { useUserPermissions } from '../../../hooks/useUserPermissions';

const TaskModal = ({
	task, isOpen = false, onRequestClose
}) => {
	const formatTag = (tag) => ({
		label: tag.title,
		value: tag.id
	});
	const { value: tags } = useSelector((state) => state.tags);
	const { value: dispatches } = useSelector((state) => state.dispatches);
	const [moveTaskModalIsOpen, setMoveTaskModalIsOpen] = useState(false);
	const [originalDispatch, setOriginalDispatch] = useState(null);
	const [selectedDispatch, setSelectedDispatch] = useState(null);
	const [loadingDispatch, setLoadingDispatch] = useState(false);
	const [dispatchOptions, setDispatchOptions] = useState([]);
	const [hasUpdated, setHasUpdated] = useState(false);
	const [targetDate, setTargetDate] = useState('');
	const [tagOptions, setTagOptions] = useState(task.tags && task.tags.length > 0 ? [...task.tags].sort((a, b) => new Date(a.createdAt.iso).getTime() - new Date(b.createdAt.iso)).map((tag) => formatTag(tag)) : []);
	const {
		register,
		handleSubmit,
		control,
		watch,
		formState: { errors, isSubmitting, isDirty },
		reset
	} = useForm();
	const reduxDispatch = useDispatch();
	const tagsRef = useRef();
	const { hasAdminAccess } = useUserPermissions();
	const formLocation = watch('location', '');
	const formNotes = watch('notes', '');
	const formContent = watch('content', '');

	useEffect(() => {
		const location = task.location || '';
		const notes = task.notes || '';
		const content = task.content || '';

		if (formLocation !== location || formNotes !== notes || formContent !== content) {
			if (isDirty) {
				setHasUpdated(true);
			} else {
				setHasUpdated(false);
			}
		} else {
			setHasUpdated(false);
		}
	}, [task, isDirty, formLocation, formNotes, formContent]);

	const formatDispatchAssigneeLabel = (dispatch) => {
		if (selectedDispatch) {
			return `${dispatch.assignees.length === 0 ? `dispatch-${dispatch.dispatchNumber}` : dispatch.assignees.length > 1 ? `${dispatch.assignees.map((assignee) => `${assignee.firstName} ${assignee.lastName ? assignee.lastName : ''}`).join(', ')} (${dispatch.dispatchNumber})` : `${dispatch.assignees[0].firstName} ${dispatch.assignees[0].lastName ? dispatch.assignees[0].lastName : ''} (${dispatch.dispatchNumber})`}`;
		}
		return '';
	};

	const formatDispatch = (dispatch) => ({
		label: formatDispatchAssigneeLabel(dispatch),
		value: dispatch.id,
		dispatch
	});

	const formatTargetDate = (dispatch) => {
		if (selectedDispatch) {
			const isToday = moment(dispatch.targetDate.iso, 'YYYY-MM-DD').isSame(moment(), 'day');
			if (isToday) {
				return `Today, ${moment(dispatch.targetDate.iso, 'YYYY-MM-DD').format('ddd MMM DD')}`;
			}
			return moment(dispatch.targetDate.iso, 'YYYY-MM-DD').format('ddd, MMM DD');
		} return '';
	};

	const updateDispatchOptions = useCallback((updatedTargetDate) => {
		const dispatchTaskIndex = dispatches.findIndex((dispatch) => dispatch.id === task.dispatchId);
		const taskDispatch = dispatchTaskIndex === -1 ? dispatches[0] : dispatches[dispatchTaskIndex];
		const targetDispatches = dispatches.filter((dispatch) => {
			if (updatedTargetDate) {
				return dispatch.targetDate && moment(dispatch.targetDate.iso, 'YYYY-MM-DD').format('MM-DD-YYYY') === updatedTargetDate;
			} return dispatch.targetDate && dispatch.targetDate.iso === taskDispatch.targetDate.iso;
		});

		setSelectedDispatch(taskDispatch);
		setTargetDate(moment(taskDispatch.targetDate.iso, 'YYYY-MM-DD').format('MM-DD-YYYY'));
		setOriginalDispatch(taskDispatch);
		setDispatchOptions(targetDispatches.map((dispatch) => formatDispatch(dispatch)));
	}, [dispatches, task, targetDate]);

	useEffect(() => {
		if (dispatches && dispatches.length && isOpen) {
			updateDispatchOptions();
		}
	}, [dispatches, isOpen, task, updateDispatchOptions]);

	useEffect(() => {
		setTagOptions(task.tags && task.tags.length > 0 ? [...task.tags].sort((a, b) => new Date(a.createdAt.iso).getTime() - new Date(b.createdAt.iso)).map((tag) => formatTag(tag)) : []);
	}, [task]);

	const updateAsyncCaller = async (data) => {
		const formattedTags = tagsRef?.current?.select?.props?.value.map((tag) => tag.value) || [];
		const updatedTask = {
			taskId: task.id, content: data.content, location: data.location, tagIds: formattedTags, notes: data.notes
		};
		await reduxDispatch(updateTask({ ...updatedTask, isSent: task.isSent })).then((res) => {
			if (res?.payload?.placement === 'dispatch') {
				reduxDispatch(updateDispatchInState(res.payload.dispatch));
				reduxDispatch(updateDispatchTask(res.payload));
			}
		});
	};

	function onSubmit(e) {
		e.preventDefault();
		handleSubmit(updateAsyncCaller)();
		onRequestClose(e);
	}

	const handleCloseModal = (e) => {
		onRequestClose(e);
		reset();
	};

	const handleSaveCloseModal = (e) => {
		onSubmit(e);
		onRequestClose(e);
	};

	const handleDuplicateTask = (e) => {
		reduxDispatch(duplicateTask({ taskId: task.id, position: task.order + 1 })).then((res) => {
			const payload = res?.payload;
			if (selectedDispatch && payload && task.placement === 'dispatch') {
				reduxDispatch(addTaskToDispatch({
					taskId: payload.id, dispatchId: selectedDispatch.id, position: task.order + 1
				}));
			} else if (payload && task.placement === 'backlog') {
				reduxDispatch(orderTasksByTask(payload));
			}
		});
		onRequestClose(e);
	};

	const handleDeleteTask = (e) => {
		reduxDispatch(deleteTask({ taskId: task.id })).then((res) => {
			const payload = res?.payload;
			if (payload && payload.placement === 'dispatch') {
				reduxDispatch(deleteDispatchTask(res.payload));
			} else if (payload && payload.placement === 'backlog') {
				reduxDispatch(updateTaskOrder());
			}
		});
		onRequestClose(e);
	};

	const handleTaskStatus = (e) => {
		let status = 'not_started';

		if (task.status === 'not_started' || task.status === 'in_progress') {
			status = 'complete';
		} else if (task.status === 'complete') {
			status = 'not_started';
		}
		updateTaskStatus({
			taskId: task.id,
			assigneeId: selectedDispatch.assignees && selectedDispatch.assignees.length > 0 && selectedDispatch.assignees[0].id,
			status,
			dispatchToken: task.dispatch.token
		}).then((res) => {
			reduxDispatch(updateDispatchTask(res.data.result.task));
		}).catch((err) => errorHandler(err));

		onRequestClose(e);
	};

	const handleMoveTaskModal = () => {
		setMoveTaskModalIsOpen(!moveTaskModalIsOpen);
	};

	const handleTagChange = (value) => {
		tagsRef?.current?.select?.props?.value.push(value);
		const tagsArr = [...tagOptions, value];
		setTagOptions(tagsArr);
	};

	const handleAddTag = (value) => {
		reduxDispatch(addTag({ title: value })).then((res) => {
			handleTagChange({ label: res?.payload?.title, value: res?.payload?.id });
		});
	};

	const handleRemoveTag = (e, value) => {
		e.stopPropagation();

		reduxDispatch(removeTag({ tagId: value })).then((res) => {
			const payload = res?.payload;
			reduxDispatch(deleteTaskTag(payload));
			reduxDispatch(deleteDispatchTaskTag(payload));
		});
	};


	const handleTargetDateChange = (updatedTargetDate) => {
		setTargetDate(updatedTargetDate);
		updateDispatchOptions(updatedTargetDate);
	};

	const handleAddDispatch = (date) => {
		setLoadingDispatch(true);
		reduxDispatch(addDispatch({ targetDate: moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY') })).then((res) => {
			setSelectedDispatch(res?.payload);
			setLoadingDispatch(false);
		}).catch(() => {
			setLoadingDispatch(false);
		});
	};


	const actions = [
		{
			text: 'Duplicate Job',
			onClick: (e) => handleDuplicateTask(e)
		},
		{
			text: 'Delete Job',
			onClick: (e) => handleDeleteTask(e)
		}
	];

	if (task.placement === 'dispatch' && selectedDispatch && selectedDispatch.assignees && selectedDispatch.assignees.length > 0) {
		actions.unshift({
			text: `Mark as ${task.status === 'not_started' || task.status === 'in_progress' ? 'Complete' : 'Incomplete'}`,
			onClick: (e) => handleTaskStatus(e)
		});
	}

	return (
		<Modal isOpen={isOpen} onRequestClose={hasUpdated ? handleSaveCloseModal : onRequestClose} maxWidth="33.875rem" disableOverflow>
			<S.Wrapper>
				<S.ActionsWrapper>
					{hasAdminAccess && (
						<Popover id="task-modal-popover" positions={['bottom']} actions={actions}>
							<IconButton icon={['fal', 'ellipsis-h']} className="icon" />
						</Popover>
					)}
					<IconButton icon={['fal', 'times']} size={1.25} className="icon close-icon" onClick={hasUpdated ? handleSaveCloseModal : onRequestClose} />
				</S.ActionsWrapper>
				<S.Form onSubmit={onSubmit}>
					<S.FormRow addMargin={!dispatches || dispatches?.length === 0 || !hasAdminAccess}>
						<FontAwesomeIcon icon={['fal', 'equals']} className="form-icon" />
						<TextInput
							id="content"
							error={errors.content}
							autoComplete="content"
							placeholder="Add Content"
							containerClassName="text-input task-content-input"
							defaultValue={task.content}
							hideLastPass
							{...register('content')}
						/>
					</S.FormRow>
					{hasAdminAccess && (
						<div>
							{dispatches && dispatches.length ? task.placement === 'dispatch' ? (
								<S.DetailWrapper>
									<S.DetailRow>
										<span>for</span>
										<TextAction handleClick={handleMoveTaskModal} text={formatDispatchAssigneeLabel(selectedDispatch)} variation="2" />
									</S.DetailRow>
									<S.DetailRow>
										<span>on</span>
										<TextAction handleClick={handleMoveTaskModal} text={formatTargetDate(selectedDispatch)} variation="2" />
									</S.DetailRow>
								</S.DetailWrapper>
							) : (
								<S.DetailWrapper>
									<S.DetailRow>
										<span>in</span>
										<TextAction handleClick={handleMoveTaskModal} text="Backlog" variation="2" />
									</S.DetailRow>
								</S.DetailWrapper>
							) : null}
						</div>
					)}

					<S.FormRow>
						<FontAwesomeIcon icon={['fal', 'map-marker-alt']} className="form-icon" />
						<TextInput
							id="location"
							error={errors.location}
							autoComplete="location"
							placeholder="Add Location"
							containerClassName="text-input"
							defaultValue={task.location}
							hideLastPass
							{...register('location')}
						/>
					</S.FormRow>
					<S.FormRow>
						<FontAwesomeIcon icon={['fal', 'tag']} className="form-icon" />
						<Controller
							control={control}
							defaultValue={tagOptions ? tagOptions.map((c) => (c ? c.value : '')) : []}
							name="tags"
							render={({ field: { onChange, ref } }) => (
								<Select
									id="tags"
									error={errors.tags}
									inputRef={ref}
									ref={tagsRef}
									options={[...tags].sort((a, b) => new Date(a.createdAt.iso).getTime() - new Date(b.createdAt.iso)).map((tag) => formatTag(tag))}
									defaultValue={tagOptions}
									onChange={(val) => {
										const formTagsLength = val.length || 0;
										const tagsLength = task?.tags?.length || 0;
										if (tagsLength !== formTagsLength) {
											setHasUpdated(true);
										} else {
											setHasUpdated(false);
										}
										onChange(val.map((c) => c.value));
									}}
									noOptionsMessage={({ inputValue }) => (!inputValue ? 'Type to create a tag' : 'No tags found')}
									onCreateOption={handleAddTag}
									handleRemoveOption={handleRemoveTag}
									canRemoveOptions
									alternate
									isCreatable
								/>
							)}
						/>
					</S.FormRow>
					<S.FormRow>
						<NotesRoundedIcon className="notes-icon form-icon" />
						<TextArea
							id="notes"
							error={errors.notes}
							autoComplete="notes"
							placeholder="Add Notes"
							containerClassName="text-input"
							defaultValue={task.notes}
							{...register('notes')}
						/>
					</S.FormRow>
					<S.ButtonRow>
						<Button type="button" variant="text" className="cancel-button" onClick={handleCloseModal}>
							<Typography variation="button-medium" weight="bold">
								Cancel
							</Typography>
						</Button>
						<Button type="submit" variation="secondary" className="submit-button" disabled={!hasUpdated}>
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading...' : 'Save'}
							</Typography>
						</Button>
					</S.ButtonRow>
				</S.Form>
			</S.Wrapper>
			<MoveTaskModal
				task={task}
				isOpen={moveTaskModalIsOpen}
				onRequestClose={(e) => {
					setMoveTaskModalIsOpen(false);
					onRequestClose(e);
				}}
				selectedDispatch={selectedDispatch}
				setSelectedDispatch={setSelectedDispatch}
				targetDate={targetDate}
				setTargetDate={handleTargetDateChange}
				dispatchOptions={dispatchOptions}
				originalDispatch={originalDispatch}
				handleAddDispatch={handleAddDispatch}
				loadingDispatch={loadingDispatch}
				disableOverflow
			/>
		</Modal>
	);
};
export default TaskModal;

TaskModal.displayName = 'TaskModal';
TaskModal.propTypes = {
	task: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
};
