import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
`;

export const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;

	.icon {
		color: ${colors.neutralText}
	}
	.close-icon {
		margin-left: .75rem;
	}
`;

export const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 1rem;

	h2 {
		margin-bottom: .75rem;
		text-transform: capitalize;
	}

	.email-success {
		color: ${colors.stateSuccess}
	}
`;

export const ButtonRow = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: .75rem;

	button {
		margin: .5rem;
	}
`;
