/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import uuid from 'react-uuid';
import { usePagination, DOTS } from '../../hooks/usePagination';
import * as S from './Pagination.styles';

export const Pagination = ({
	onPageChange,
	totalCount,
	siblingCount = 1,
	currentPage,
	pageSize,
	className
}) => {

	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize
	});

	if (totalCount < pageSize || (paginationRange && paginationRange.length < 2)) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	const isLastPage = currentPage + 1 === (Math.ceil(totalCount / pageSize));

	return (
		<S.Wrapper className={classnames('pagination-container', { [className]: className })}>
			{/* Left navigation arrow */}
			<li
				className={classnames('pagination-item', {
					disabled: currentPage === 0
				})}
				onClick={onPrevious}
			>
				<div className="arrow left" />
			</li>
			{paginationRange && paginationRange.map((pageNumber) => {
				if (pageNumber === DOTS) {
					return <li key={uuid()} className="pagination-item dots">&#8230;</li>;
				}
				return (
					<li
						key={pageNumber}
						className={classnames('pagination-item', {
							selected: pageNumber === currentPage + 1
						})}
						onClick={() => onPageChange(pageNumber - 1)}
					>
						{pageNumber}
					</li>
				);
			})}
			{/*  Right Navigation arrow */}
			<li
				className={classnames('pagination-item', {
					disabled: isLastPage
				})}
				onClick={onNext}
			>
				<div className="arrow right" />
			</li>
		</S.Wrapper>
	);
};

Pagination.propTypes = {
	onPageChange: PropTypes.func.isRequired,
	totalCount: PropTypes.number,
	siblingCount: PropTypes.number,
	currentPage: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	className: PropTypes.string,
};
