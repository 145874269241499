import styled from 'styled-components';

export const Wrapper = styled.div`
	tbody {
		tr {
			padding: .5rem 1rem;

			&:last-child {
				border-bottom: none;
			}
		}
	}

	.title-table-cell, .phone-table-cell, .email-table-cell {
		display: flex;
		align-items: center;
		padding: 0;
	}

	.name-table-cell {
		display: flex;
		align-items: center;
		padding: 0;

		a {
			display: flex;
			align-items: center;
			padding: 0;

			.name {
				font-weight: initial;
				color: #000;
			}
		}

		.avatar {
			height: 1.75rem;
			width: 1.75rem;
			margin-right: .5rem;
		}
	}

	.title-table-cell {
		padding-left: .5rem;
	}

	.phone-table-cell {
		padding-left: .825rem;
	}

	.email-table-cell {
		padding-left: 1.5rem;
	}
`;
