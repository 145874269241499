import styled from 'styled-components';

// import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;

	h5 {
		margin-bottom: 1rem;
	}
`;

export const Form = styled.div`
	width: 100%;
`;

export const FormRow = styled.div`
	display: grid;
	grid-template-columns: auto auto auto;
	gap: 1.25rem;
	margin-top: 1.25rem;

	input {
		flex: 1;

		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: auto;
	}
`;
