import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: inline-flex;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
	display: block;
	appearance: none;
	cursor: pointer;
	border-radius: 0.375rem;
	border: 1px solid ${colors.neutralForm};
	width: 1.5625rem;
	height: 1.5625rem;

	&:checked {
		border-color: ${colors.brandPrimary};
		background-color: ${colors.brandPrimary};
	}
`;

export const CheckboxWrapper = styled.div`
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	.checkmark {
		position: absolute;
		left: .325rem;
		width: 1rem;
		height: 1rem;
		color: white;
		pointer-events: none;
		opacity: 0;
	}

	${Checkbox}:checked + .checkmark {
		opacity: 1;
	}
`;
