import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Popover as TinyPopover } from 'react-tiny-popover';
import { Button } from '../Button';
import * as S from './Popover.styles';

export const Popover = forwardRef(({
	id, contentClassName, positions = ['bottom', 'top', 'left', 'right'], position, children, content, actions, ...rest
}, ref) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const actionClick = (e, action) => {
		setIsPopoverOpen(false);

		setTimeout(() => {
			action(e);
		}, 100);

	};

	// This component accepts an array to use for a quick dropdown of buttons or you can render custom content
	const actionsContent = () => actions.map((action, index) => (
		<S.ActionContent key={action.text} index={index}>
			<Button variant="text" className="action-button" onClick={(e) => actionClick(e, action.onClick)}>{action.text}</Button>
		</S.ActionContent>
	));

	return (
		<TinyPopover
			id={id}
			ref={ref}
			isOpen={isPopoverOpen}
			positions={positions}
			padding={10}
			containerStyle={{ zIndex: 3, ...position }}
			onClickOutside={() => setIsPopoverOpen(false)}
			content={() => (
				<S.ContentWrapper className={contentClassName}>
					{actions ? actionsContent() : content}
				</S.ContentWrapper>
			)}
			{...rest}
		>
			<S.ChildrenWrapper onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
				{children}
			</S.ChildrenWrapper>
		</TinyPopover>
	);
});

Popover.displayName = 'Popover';
Popover.propTypes = {
	id: PropTypes.string,
	contentClassName: PropTypes.string,
	isPopoverOpen: PropTypes.bool,
	positions: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	actions: PropTypes.arrayOf(PropTypes.object),
	position: PropTypes.object,
};
