import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logoutUser } from '../../services/authService';
import { clearUser } from '../../app/slices/user/userSlice';
import { Spinner } from '../../components';
import * as S from './Logout.styles';
import { META_TITLE } from '../../../constants/general.constants';
import { clearContacts } from '../../app/slices/contacts/contactsSlice';
import { clearDispatches } from '../../app/slices/dispatches/dispatchesSlice';
import { clearTasks } from '../../app/slices/tasks/tasksSlice';
import { clearTags } from '../../app/slices/tags/tagsSlice';
import { clearDashboard } from '../../app/slices/dashboard/dashboardSlice';
import { clearMenu } from '../../app/slices/menu/menuSlice';
import { clearTeam } from '../../app/slices/team/teamSlice';

const Logout = () => {
	const dispatch = useDispatch();

	const [shouldRedirect, setShouldRedirect] = useState(false);

	useEffect(() => {
		document.title = `Logout | ${META_TITLE}`;

		let mounted = true;
		logoutUser().then(() => {
			if (mounted) {
				dispatch(clearDashboard());
				dispatch(clearMenu());
				dispatch(clearTeam());
				dispatch(clearContacts());
				dispatch(clearDispatches());
				dispatch(clearTasks());
				dispatch(clearTags());
				dispatch(clearUser());
				setShouldRedirect(true);
			}
		});
		return () => { mounted = false; };
	}, []);

	return !shouldRedirect ? (
		<S.Wrapper>
			<Spinner />
		</S.Wrapper>
	) : (
		<Redirect
			to={{
				pathname: '/login',
			}}
		/>
	);
};

export default Logout;
