import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: fit-content;
	background-color: #fff;
	border: 1px solid ${colors.neutralForm};
	box-shadow: ${colors.lightShadow};
`;

export const ContactWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	margin-left: .25rem;
	margin-right: .25rem;

	p {
		margin-right: .25rem;
	}
`;

export const SendWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: .875rem;
	background-color:  ${({ allTasksSent, allTasksComplete }) => (allTasksComplete ? colors.stateSuccess : allTasksSent ? colors.brandSecondary : 'white')};

	p {
		color: ${({ allTasksSent }) => (allTasksSent ? 'white' : colors.neutralText)};
	}
`;

export const IconWrapper = styled.div`
	display: flex;
	align-items: center;

	svg {
		height: 1.25rem !important;
	}

	.ellipsis {
		width: 1rem;
		color: ${({ allTasksSent }) => (allTasksSent ? 'white' : colors.neutralText)};

		.more-vert-icon {
			width: .25rem;
			transform: scale(1.1);
		}
	}

	.send-button-wrapper, .replay-button-wrapper, .loader-icon {
		margin-right: .625rem;
	}

	.send-button-wrapper, .replay-button-wrapper {
		color: ${({ allTasksSent }) => (allTasksSent ? 'white' : colors.brandSecondary)};
	}

	.replay-button-wrapper {
		.replay-icon {
			transform: scale(1.3);
		}
	}

	.send-button-wrapper {
		width: 1.25rem;
		.send-icon {
			transform: scale(1.3);
		}
	}
`;

export const DispatchInfoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ allTasksComplete }) => (allTasksComplete ? colors.stateComplete : colors.lightBrandTertiary)};
	padding: .625rem;

	.dispatch-id-text, .dispatch-tasks-text {
		color: ${colors.neutralText};
	}

	.dispatch-id-text {
		text-transform: lowercase;
		font-style: italic;
	}

	.dispatch-tasks-text {
		text-transform: uppercase;
	}
`;

export const TaskListWrapper = styled.div`
	.task-card {
		box-shadow: none;
		margin: 0;
		border-top: 1px solid ${colors.neutralForm};
	}
`;

export const AddTaskWrapper = styled.div`
	background-color: ${colors.neutralForm};
	border-top: 1px solid ${colors.neutralForm};

	.add-task-button {
		padding: .25rem .625rem;

		.add-task-icon {
			margin-right: .5rem;
		}
	}
`;
