import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const currentDate = moment(new Date(), 'MM-DD-YYYY');
const initialDate = moment(currentDate, 'YYYY-MM-DD').subtract(7, 'd');

export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState: {
		accordionSelected: '',
		isBacklogOpen: true,
		openAccordions: {},
		accordionsLoaded: false,
		accordionDates: [initialDate.format('MM-DD-YYYY')],
	},
	reducers: {
		selectAccordion: (state, action) => {
			state.accordionSelected = action.payload;
		},
		setIsBacklogOpen: (state, action) => {
			state.isBacklogOpen = action.payload;
		},
		setOpenAccordions: (state, action) => {
			state.openAccordions = action.payload;
		},
		setAccordionsLoaded: (state) => {
			state.accordionsLoaded = true;
		},
		setAccordionDates: (state, action) => {
			state.accordionDates = action.payload;
		},
		clearDashboard: (state) => {
			state.accordionSelected = '';
			state.isBacklogOpen = true;
			state.openAccordions = {};
			state.accordionsLoaded = false;
			state.accordionDates = [initialDate.format('MM-DD-YYYY')];
		}
	},
});

export const {
	selectAccordion, setIsBacklogOpen, setOpenAccordions, setAccordionsLoaded, setAccordionDates, clearDashboard
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
